import React from "react";

import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";

import FilterBySearch from "../../../components/filters/FilterBySearch";
import FilterByDate from "../../../components/filters/FilterByDate";
import StyledButton from "../../../components/styledComponents/Button";
import Spinner from "../../../components/customComponents/Spinner";
import Dropdown from "../../../components/customComponents/Dropdown";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";

import YesNoModal from "../../../components/modals/YesNoModal";
import PreviewImageModal from "../../../components/modals/PreviewImageModal";
import PreviewVideoModal from "../../../components/modals/PreviewVideoModal";

const UserCreatives_ST = () => {
    const [spinner, setSpinner] = React.useState(false);
    const [data, setData] = React.useState();
    const [allCreativeNames, setAllCreativeNames] = React.useState();
    const [search, setSearch] = React.useState("");
    const [dateFilter, setDateFilter] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [selectedCreative, setSelectedCreative] = React.useState(null);

    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const timestampRef = React.useRef(Date.now());

    const curDefer = useDefer();
    const curOnScreen = useOnScreen();
    const curDispatch = useDispatch();

    const prepareFilters = () => {
        let out = [];
        if (!search) return out;

        for (let item of search.split(" ")) {
            out.push({or: [
                {name: "CreativeName", op: "like", value: item},
                {name: "FileType", op: "like", value: item},
                {name: "CustomID", op: "like", value: item}
            ]})
        };

        return out.length === 0 ? null : {and: out};
    };

    const getAllCreativeNames = ts => {
        if (timestampRef.current !== ts) return;
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreativeNames`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setAllCreativeNames(res.data.status === "ok" ? res.data.data : []);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setAllCreativeNames([]);
        });
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);
        getAllCreativeNames(ts);

        let finalDateFilters = [];
        if (dateFilter?.start && dateFilter?.end) {
            finalDateFilters.push({and: [
                {name: "createdAt", op: "pdgeq", value: dateFilter.start.toDate().getTime()},
                {name: "createdAt", op: "pdleq", value: dateFilter.end.toDate().getTime()}
            ]});
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreatives`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    prepareFilters(),
                    ...finalDateFilters,
                    (selectedCreative ? {name: "CreativeName", op: "eq", value: selectedCreative} : null)
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
            console.log(res.data)
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        }).finally(() => {
            setSpinner(false);
        });
    };

    const continueData = (ts) => {
        if (!canPaginate) return;
        if (!data) return;
        if (data.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        let finalDateFilters = [];
        if (dateFilter?.start && dateFilter?.end) {
            finalDateFilters.push({and: [
                {name: "createdAt", op: "pdgeq", value: dateFilter.start.toDate().getTime()},
                {name: "createdAt", op: "pdleq", value: dateFilter.end.toDate().getTime()}
            ]});
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreatives`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    prepareFilters(),
                    ...finalDateFilters,
                    {not: [
                        {name: "ID", op: "in", value: data.data.map(d => d.ID)},
                    ]},
                    (selectedCreative ? {name: "CreativeName", op: "eq", value: selectedCreative} : null)
                ].filter(t => t)
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
                
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };

    const removeCreative = (e, item) => {
        let target = {currentTarget: e?.target};

        animateBox(target, <YesNoModal
            heading="Are you sure?"
            text={["You are about to remove a creative, are You sure?", <br/>, "This action is irreversible"]}
            buttonLeftText={"No"}
            buttonRightText={"Yes"}
            isRightButtonNormal={true}
            buttonRightCallback={async args => {
                args.disabledAll(true);
                args.spinner(true);

                let hadError = false;
                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/creatives/removeCreative`,
                    data: {
                        ID: item.ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "error") hadError = true;
                }).catch(() => {
                    hadError = true;
                });

                args.spinner(false);
                args.disabledAll(false);
                if (hadError) {
                    args.errorMessage("There was an error while removing the creative!");
                } else {
                    let ts = Date.now();
                    timestampRef.current = ts;
                    curDefer(() => getData(ts), 0);

                    args.close();
                };
            }}
        />);
    };

    const checkAdditionalData = (ad, creative) => {
        if (!ad) return null;
        if (!ad?.["integration"]) return <span style={{
            color: "gray",
            marginRight: "auto",
            fontSize: "14px"
        }}>{creative?.CustomID}</span>;
        let nameSplit = String(ad["integration"]).split(":");

        let intName = nameSplit.shift();
        let intValue = nameSplit.join(":");

        return <span style={{
            color: "gray",
            marginRight: "auto",
            fontSize: "14px"
        }}>{intName.charAt(0)}{`-${intValue}`}</span>;
    };

    React.useEffect(() => {
        const handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => getData(ts), 500);
        };

        handler();
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => {
            try {
                curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
            } catch {};
        };
    }, [search, selectedCreative, dateFilter]);

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        curDefer(() => {
            let ts = Date.now();
            timestampRef.current = ts;
            continueData(ts);
        }, 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    return <div className="route__user__creatives__st">
        <div className="route__user__creatives__st__filters" style={{
            gridTemplateColumns: (userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.canAddCreatives) ? null : "1fr 350px"
        }}>
            <FilterBySearch onChange={e => setSearch(e)} />
            <AdvancedDropdown
                headline="Creative"
                showSearch={true}
                data={Array.isArray(allCreativeNames) ? [{key: "all", name: "All creatives", value: null},...allCreativeNames.map(c => {
                    return {
                        key: c.CreativeName,
                        name: `${c.CreativeName} (${c.Count})`,
                        value: c.CreativeName
                    };
                })] : null}
                onChange={e => {
                    if (selectedCreative !== e?.value) setSelectedCreative(e?.value);
                }}
                selected={(()=>{
                    if (selectedCreative === undefined) return null;
                    if (!Array.isArray(allCreativeNames)) return null;
                    if (selectedCreative === null) return 0;

                    return allCreativeNames.indexOf(allCreativeNames.find(ac => ac.CreativeName === selectedCreative)) + 1;
                })()}
            />
            <FilterByDate defaultValue="all" onChange={e => setDateFilter(e)} />

            {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.canAddCreatives) && <StyledButton onClick={e => animateBox(e, <AddCreative onChange={() => {
                let ts = Date.now();
                timestampRef.current = ts;
                curDefer(() => getData(ts), 0);
            }} />)}>
                <img src="/images/icon_close.svg" />
                <span>Add</span>
            </StyledButton>}
        </div>

        <div className="route__user__creatives__st__content">
            <div className="route__user__creatives__st__content__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }}>
                <Spinner color="white" align="center" />
            </div>
            {data ? <>
                {data.status === "ok" ? <>
                    {data.data.map(item => {
                        return <div className="route__user__creatives__st__content__item" key={`creative-${item.ID}`}>
                            <div className="route__user__creatives__st__content__item__name">[{`${item.FileType.charAt(0).toUpperCase()}${item.FileType.substring(1, item.FileType.length)}`}] {item.CreativeName}</div>
                            <div className="route__user__creatives__st__content__item__date">{moment(item.createdAt).toDate().toLocaleString()}</div>
                            
                            <img src={(()=>{
                                if (item.FileType === "image") return item.File;
                                if (item.FileType === "video") return "/images/fileTypes/video.svg";
                                return "/images/fileTypes/file.svg";
                            })()} onClick={e => {
                                if (item.FileType === "image") return animateBox({currentTarget: e.target.parentNode}, <PreviewImageModal image={item.File} />);
                                if (item.FileType === "video") return animateBox({currentTarget: e.target.parentNode}, <PreviewVideoModal video={item.File} />)
                            }} />

                            <div className="route__user__creatives__st__content__item__buttons">
                                {checkAdditionalData(item?.AdditionalData ?? {}, item)}
                                {item.FileType === "file" && <StyledButton isSecondary={false} style={{padding: "0 10px"}} onClick={e => window.open(item.File, "_blank")}>Download</StyledButton>}
                                {(item.FileType === "video" || item.FileType === "image") && <StyledButton isSecondary={false} style={{padding: "0 10px"}} onClick={e => window.open((()=>{
                                    let tmpURL = item.File;
                                    tmpURL += tmpURL.includes("?") ? "&" : "?";
                                    tmpURL += "download=1";
                                    return tmpURL;
                                })(), "_blank", "download")}>Download</StyledButton>}
                                {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.ID === item.CreatedBy) && <StyledButton isSecondary={true} style={{padding: "0 10px"}} onClick={e => removeCreative(e, item)}>Remove</StyledButton>}
                            </div>
                        </div>
                    })}

                    {canPaginate && <div style={{opacity: 0, paddingBottom: "10px", marginBottom: "10px"}} ref={curOnScreen.measureRef}></div>}
                    {data.data.length === 0 && <p>Nothing to show...</p>}
                </> : <p className="route__user__creatives__st__content__infoP">Error while fetching data!</p>}
            </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
        </div>
    </div>
};

const AddCreative = (props) => {
    const [files, setFiles] = React.useState([]);
    const [allCreativeNames, setAllCreativeNames] = React.useState();
    const [infoP, setInfoP] = React.useState({
        error: "",
        hadErrors: false,
        inputs: []
    });
    const [spinner, setSpinner] = React.useState(false);
    const [creativeName, setCreativeName] = React.useState();

    const selectFiles = f => {
        if (!(f instanceof FileList)) return;
        if (f.length === 0) return;

        let toAdd = [];
        for (let item of f) {
            if (!files.find(t => t.name === item.name && t.size === item.size)) toAdd.push(item);
        };
        setFiles(f => [...f, ...toAdd]);
    };

    const addCreative = async (e) => {
        if (spinner) return;
        setInfoP(ip => {return {...ip, hadErrors: false, inputs: []}});

        let initialData = {
            CreativeName: creativeName,
        };

        if (!initialData.CreativeName) return setInfoP(ip => {return {...ip, hadErrors: true, error: "Creative name can't be empty!", inputs: ["name"]}})
        if (files.length === 0) return setInfoP(ip => {return {...ip, hadErrors: true, error: "At least 1 file has to be selected!", inputs: []}});
        for (let f of files) {
            if (!f.type.startsWith("image/") && !f.type.startsWith("video/")) return setInfoP(ip => {return {...ip, hadErrors: true, error: `File ${f.name} is invalid!`, inputs: []}});
        };

        let i = 0;
        const failedFiles = [];
        setSpinner(true);
        for (let file of files) {
            let fileType = null;
            if (file.type.startsWith("image/")) fileType = "image";
            if (file.type.startsWith("video/")) fileType = "video";

            let fd = new FormData();
            if (fileType === "image") {
                fd.append("ImageName", `creative-${initialData.CreativeName}-${i++}`);
                fd.append("tag", "creative");
                fd.append("image", file);
            } else {
                fd.append("FileName", `creative-${initialData.CreativeName}-${i++}`);
                fd.append("tag", "creative");
                fd.append("file", file);
                fd.append("FileType", fileType);
            };

            let upload = await axios({
                method: "POST",
                url: `${backendModule.backendURL}${fileType === "image" ? "/images/uploadImage" : "/files/uploadFile"}`,
                data: fd,
                ...backendModule.axiosConfig,
                headers: {
                    ...backendModule.axiosConfig.headers,
                    "Content-Type": "multipart/form-data"
                }
            }).then(res => res.data.status === "ok"? res.data.data?.url : null).catch(() => null);
            if (!upload) {
                failedFiles.push(file);
                continue;
            };

            let final = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/creatives/addCreative`,
                data: {
                    ...initialData,
                    File: upload,
                    FileType: fileType,
                    FileName: file.name
                },
                ...backendModule.axiosConfig
            }).then(res => res.data).catch(() => backendModule.genericError);

            if (final.status !== "ok") {
                failedFiles.push(file);
                let imgName = upload.split("/").pop();
                await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}${fileType === "image" ? "/images/deleteImageByFilename" : "/files/deleteFileByFilename"}`,
                    data: {
                        FileName: imgName
                    },
                    ...backendModule.axiosConfig
                }).catch(() => null);
            };
        };

        if (failedFiles.length > 0) {
            let errs = [`There was an error while uploading ${failedFiles.length} files!`, <br/>, "The following files could not be uploaded:",<br/>];
            for (let file of failedFiles) {
                errs.push(<span style={{color: "rgb(201, 194, 255)"}}>{file?.name ?? "unknown"}</span>, <br/>);
            };

            animateBox({currentTarget: e?.target}, <YesNoModal
                heading="Error while uploading"
                text={errs}
                buttonLeftHidden={true}
                isRightButtonNormal={true}
                buttonRightText="Ok"
            />);
        };
        setSpinner(false);
        props.onClose();
        props.onChange();
    };

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/getAllCreativeNames`,
            ...backendModule.axiosConfig
        }).then(res => {
            setAllCreativeNames(res.data);
        }).catch(() => {
            setAllCreativeNames(backendModule.genericError);
        });
    }, []);

    return <div className="route__user__creatives__st__addCreative genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add creative</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className={`genericModal__wrap__input ${infoP.inputs.includes("name") ? "genericModal__wrap__input--error" : ""}`}>
                <p>Illness</p>
                {allCreativeNames ? <>
                    <Dropdown
                        theme="dark"
                        accent="#6C5DD3"
                        inlinePlaceholder="Illness"
                        data={[
                            {value: null, name: "New illness", alwaysVisible: true, saveValue: true, showValue: true, style: {color: "rgb(146, 240, 157)"}},
                            ...(allCreativeNames?.status ===  "ok" ? allCreativeNames.data : []).map(name => {
                                return {name: name.CreativeName, value: name.CreativeName};
                            })
                        ]}
                        onChange={e => {
                            setCreativeName(e?.value);
                        }}
                        selected={(()=>{
                            if (!allCreativeNames) return null;
                            if (allCreativeNames.status !== "ok") return null;

                            let tmpIndex = allCreativeNames.data.indexOf(allCreativeNames.data.find(a => a.CreativeName === creativeName));
                            if (tmpIndex === -1) return null;
                            return tmpIndex + 1;
                        })()}
                    />
                </> : <Spinner style={{width: "24px", height: "24px"}} color="white" />}
            </div>

            <input type="file" accept="image/*, video/*" style={{display: "none"}} multiple={true} onChange={e => selectFiles(e?.target?.files)} />
            <StyledButton className="route__user__creatives__st__addCreative__uploadBtn" onClick={e => e?.currentTarget?.parentNode?.querySelector?.("input[type=file]")?.click?.()}>
                <img src="/images/fileTypes/file.svg" />
                <span>Click to upload a file</span>
            </StyledButton>

            <div className="route__user__creatives__st__addCreative__list">
                {files.map((f, fIdx) => <div className="route__user__creatives__st__addCreative__list__item">
                    <p>{f?.name}</p>
                    <StyledButton isSecondary={true} onClick={() => {
                        setFiles(f => f.filter((_, f2Idx) => f2Idx !== fIdx));
                    }}>Remove</StyledButton>
                </div>)}
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={(e) => !spinner && addCreative(e)}>
                    {spinner ? <Spinner style={{width: "24px", height: "24px"}} color="white" /> : "Add"}
                </div>
            </div>

            <p className="genericModal__wrap__infoP" style={{
                opacity: infoP.hadErrors ? 1 : 0
            }}>{infoP.error}</p>

        </div>
    </div>
};

export default UserCreatives_ST;