import React from "react";
import "./index.scss";


import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import * as basicStylesModule from "../../../modules/basicStylesModule";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import ButtonWithDropdown from "../../../components/customComponents/ButtonWithDropdown";
import Checkbox from "../../../components/customComponents/Checkbox";
import Spinner from "../../../components/customComponents/Spinner";
import FilterBySearch from "../../../components/filters/FilterBySearch";

import YesNoModal from "../../../components/modals/YesNoModal";

const AdminUsers = (props) => {
    const [data, setData] = React.useState();
    const [selectedUsers, setSelectedUsers] = React.useState([]);

    const checkboxFunctionsRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const getUsers = () => {
        if (!curUserSelector?.Flags?.isAdmin || !curUserSelector?.Flags?.isTeamLeader) return setData(backendModule.genericError);

        checkboxFunctionsRef.current?.reset();
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/${(curUserSelector?.Flags?.isAdmin && !props.teamLead) ? "getAllUsers" : "getAllUsersTeamLead"}`,
            data: {
                ...(!props.teamLead ? {} : {
                    excludeSelf: true
                })
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const removeUser = (e, ids) => {
        if (ids.length === 0) return;

        if (ids.includes(curUserSelector?.ID)) return animateBox(e, <YesNoModal
            heading="Error"
            text="You can't remove your own account!"
            buttonLeftHidden={true}
            buttonRightText="Ok"
            isRightButtonNormal={true}
        />);

        animateBox(e, <YesNoModal
            heading="Are you sure?"
            text={["You are about to remove ", <span style={{ color: "#6C5DD3" }}>{ids.length} users</span>, ", this action is irreversible!"]}
            isRightButtonNormal={true}
            buttonRightCallback={async (args) => {
                args.disabledAll(true);
                args.spinner(true);

                let hadErrors = false;
                for (let item of ids) {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/users/removeUser`,
                        data: {
                            ID: item
                        },
                        ...backendModule.axiosConfig
                    }).then(res => {
                        if (res.data.status !== "ok") {
                            hadErrors = true;
                        };
                    }).catch(() => {
                        hadErrors = true;
                    });
                };

                args.disabledAll(true);
                args.spinner(false);
                args.close();
                getUsers();

                if (hadErrors) {
                    let tmp = document.querySelector(".route__admin__users__btns__right__btn");
                    if (!tmp) return;

                    animateBox({ currentTarget: tmp }, <YesNoModal
                        heading="Error"
                        text="There was an error while removing one or more users."
                        buttonLeftHidden={true}
                        buttonRightText="Ok"
                    />);
                };
            }}
        />);
    };

    const editUser = (e, id) => {
        if (!data) return;
        if (data.status === "error") return;
        if (!curUserSelector) return;

        let curUser = data.data.find(d => d.ID === id);
        if (!curUser) return;

        // if (curUserSelector?.ID === id) return animateBox(e, <YesNoModal
        //     heading="Error"
        //     text="You can't edit your own account!"
        //     buttonLeftHidden={true}
        //     buttonRightText="Ok"
        //     isRightButtonNormal={true}
        // />);

        animateBox(e, <AddUser onChange={getUsers} edit={curUser} />);
    };

    const viewAccount = userID => {
        if (!userID) return;

        window.open(`${window.location.origin}${window.location.origin.endsWith("/") ? "" : "/"}?_admin-viewbyid=${userID}`);
    };

    React.useEffect(() => {
        getUsers();
    }, []);

    return <div className="route__admin__users">
        <div className="route__admin__users__btns">
            <div className="route__admin__users__btns__left">
                <div className={`route__admin__users__btns__left__selected ${selectedUsers.length > 0 ? "route__admin__users__btns__left__selected--active" : ""}`}>
                    {`${selectedUsers.length} users selected`}
                    <div
                        className="route__admin__users__btns__left__selected__btn"
                        style={{ backgroundImage: `url("/images/icon_close.svg")` }}
                        onClick={() => checkboxFunctionsRef.current?.reset()}
                    ></div>
                </div>
            </div>

            <div className="route__admin__users__btns__right">
                {selectedUsers.length > 0 && <ButtonWithDropdown
                    image="/images/icon_edit.svg"
                    value="More actions"
                    data={[
                        (selectedUsers.length === 1 && { name: "Edit", onClick: e => editUser(e, selectedUsers[0]) }),
                        { name: `Remove ${selectedUsers.length} users`, onClick: (e) => removeUser(e, selectedUsers) },
                        ((selectedUsers.length === 1 && curUserSelector.Flags.isAdmin) && { name: "View IP Logs", onClick: e => animateBox(e, <ViewIPLogs ID={selectedUsers[0]} />) }),
                        ((selectedUsers.length === 1 && curUserSelector.Flags.isAdmin) && { name: "View account in new tab", onClick: () => viewAccount(selectedUsers[0]) })
                    ]}
                />}

                {selectedUsers.length === 0 && <div className="route__admin__users__btns__right__btn" onClick={e => {
                    animateBox(e, <AddUser onChange={() => getUsers()} />);
                }}>
                    <img src="/images/icon_close.svg" style={{ transform: "rotate(45deg)" }} />
                    <span>Add user</span>
                </div>}
            </div>
        </div>
        <div className="route__admin__users__tableWrap">
            <FilteredCustomTable
                key="admin-users-table"
                checkboxCB={(data?.status === "ok" && data?.data?.length > 0) ? setSelectedUsers : false}
                checkboxFunctions={cf => checkboxFunctionsRef.current = cf()}
                accent="#6C5DD3"
                theme={themeSelector}
                headers={curUserSelector.Flags.isAdmin ? ["ID", "Username", "Email", "Team", "Admin"] : ["Username", "Email"]}
                data={(() => {
                    let out = [];

                    if (!data) {
                        out.push([{ keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black" }]);
                    } else {
                        if (data.status === "ok") {
                            for (let item of data.data) {
                                if (curUserSelector.Flags.isAdmin) {
                                    out.push([
                                        { keyID: String(item.ID), type: "text", text: item.ID },
                                        { keyID: String(item.ID), type: "text", text: item.Username },
                                        { keyID: String(item.ID), type: "text", text: item.Email },
                                        { keyID: String(item.ID), type: "text", text: item.Team ?? "-" },
                                        { keyID: String(item.ID), type: "custom", data: item.Flags?.isAdmin ? <span style={{ color: themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight }}>Yes</span> : <span style={{ color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight }}>No</span> }
                                    ]);
                                } else {
                                    out.push([
                                        { keyID: String(item.ID), type: "text", text: item.Username },
                                        { keyID: String(item.ID), type: "text", text: item.Email }
                                    ]);
                                };
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "An error occured!" }]);
                        };
                    };

                    if (out.length === 0) {
                        out.push([{ keyID: "noData-noData", type: "text", text: "Nothing to show..." }]);
                    };
                    return out;
                })()}
            />
        </div>


    </div>
};

const AddUser = props => {
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        error: "",
        inputs: []
    });
    const [flags, setFlags] = React.useState({});
    const [spinner, setSpinner] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState(1);

    const curUserSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const usernameRef = React.useRef();
    const passwordRef = React.useRef();
    const emailRef = React.useRef();
    const teamRef = React.useRef();

    const JPBalanceRef = React.useRef();
    const MWQuotaRef = React.useRef();
    const balanceTresholdReminderRef = React.useRef();

    const onClose = (force = false) => {
        if (spinner && !force) return;

        props.onClose();
    };

    const addUser = () => {
        setInfoP(i => { return { ...i, hadError: false, inputs: [] } });

        let data = {
            username: usernameRef.current.value,
            password: passwordRef.current.value,
            email: emailRef.current.value,
            team: teamRef.current.value,
            flags
        };

        if (!data.username) return setInfoP(i => { return { ...i, hadError: true, inputs: ["username"], error: "Username can't be empty" } });
        if (!data.email) return setInfoP(i => { return { ...i, hadError: true, inputs: ["email"], error: "Email can't be empty" } });

        if (!props.edit && !data.password) return setInfoP(i => { return { ...i, hadError: true, inputs: ["password"], error: "Password can't be empty" } });

        if (data.email.split("@").length < 2) return setInfoP(i => { return { ...i, hadError: true, inputs: ["email"], error: "Email invalid" } });
        if (data.password && (data.password.length < 8 || data.password.length > 64)) return setInfoP(i => { return { ...i, hadError: true, inputs: ["password"], error: "Password must be between 8 and 64 characters" } });

        if (props.edit) data["id"] = props.edit.ID;

        if (!curUserSelector?.Flags?.isAdmin) data["flags"] = {
            ...flags,
            isAdmin: false,
            isTeamLeader: false
        };
        
        if (curUserSelector?.Flags?.isAdmin) {
            let JPMaxBalance = JPBalanceRef.current.value;
            JPMaxBalance = Number(JPMaxBalance);
            if (!isNaN(JPMaxBalance) && JPMaxBalance > 0) {
                data["JPMaxBalance"] = JPMaxBalance;
            };

            let MWQuota = MWQuotaRef.current.value;
            MWQuota = Number(MWQuota);
            if (!isNaN(MWQuota) && MWQuota > 0) {
                data["MWMaxQuota"] = MWQuota;
            };

            let BalanceTresholdReminder = balanceTresholdReminderRef.current.value;
            BalanceTresholdReminder = Number(BalanceTresholdReminder);
            if (!isNaN(BalanceTresholdReminder)) {
                data["BalanceTresholdReminder"] = BalanceTresholdReminder;
            };
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/${props.edit ? "editUser" : "addUser"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                return onClose(true);
            } else {
                return setInfoP(i => { return { ...i, hadError: true, inputs: [], error: `Error while ${props.edit ? "editing" : "creating"} user` } });
            };
        }).catch(() => {
            return setInfoP(i => { return { ...i, hadError: true, inputs: [], error: "Server timed out" } });
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__admin__users__add">
        <div className="route__admin__users__add__wrap">
            <div className="route__admin__users__add__wrap__head">
                <div className="route__admin__users__add__wrap__head__left">{props.edit ? "Edit" : "Add"} user</div>
                <div className="route__admin__users__add__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
            </div>

            <div className="genericTabs">
                <div className={`genericTabs__tab ${activeTab === 1 ? "genericTabs__tab--active" : ""}`} onClick={() => setActiveTab(1)}>General</div>
                <div className={`genericTabs__tab ${activeTab === 2 ? "genericTabs__tab--active" : ""}`} onClick={() => setActiveTab(2)}>Permissions</div>
            </div>

            <div style={{display: activeTab === 1 ? null : "none"}} className={`route__admin__users__add__wrap__input ${infoP.inputs.includes("username") ? "route__admin__users__add__wrap__input--error" : ""}`}>
                <p>Username</p>
                <input ref={usernameRef} type="text" placeholder="Username" defaultValue={props.edit?.Username} />
            </div>
            <div style={{display: activeTab === 1 ? null : "none"}} className={`route__admin__users__add__wrap__input ${infoP.inputs.includes("email") ? "route__admin__users__add__wrap__input--error" : ""}`}>
                <p>Email</p>
                <input ref={emailRef} type="text" placeholder="Email" defaultValue={props.edit?.Email} />
            </div>
            <div style={{display: activeTab === 1 ? null : "none"}} className={`route__admin__users__add__wrap__input ${infoP.inputs.includes("password") ? "route__admin__users__add__wrap__input--error" : ""}`}>
                <p>{props.edit ? `Password (optional)` : "Password"}</p>
                <input ref={passwordRef} type="password" placeholder="Password" />
            </div>

            <div style={{display: activeTab === 1 ? null : "none"}} className={`route__admin__users__add__wrap__input ${infoP.inputs.includes("team") ? "route__admin__users__add__wrap__input--error" : ""}`}>
                <p>Team</p>
                <input ref={teamRef} type="text" placeholder="Team" defaultValue={props.edit?.Team} />
            </div>

            {curUserSelector?.Flags?.isAdmin && <div style={{display: activeTab === 1 ? null : "none"}} className={`route__admin__users__add__wrap__input ${infoP.inputs.includes("password") ? "route__admin__users__add__wrap__input--error" : ""}`}>
                <p>[Integration] JP daily balance €</p>
                <input ref={JPBalanceRef} type="text" placeholder="JP daily Balance €" defaultValue={props.edit?.Integrations_JPMaxBlance} />
            </div>}

            {curUserSelector?.Flags?.isAdmin && <div style={{display: activeTab === 1 ? null : "none"}} className={`route__admin__users__add__wrap__input ${infoP.inputs.includes("password") ? "route__admin__users__add__wrap__input--error" : ""}`}>
                <p>[Integration] MW daily SMS quota</p>
                <input ref={MWQuotaRef} type="text" placeholder="MW daily SMS quota" defaultValue={props.edit?.Integrations_MWQuota} />
            </div>}
            {curUserSelector?.Flags?.isAdmin && <div style={{display: activeTab === 1 ? null : "none"}} className={`route__admin__users__add__wrap__input ${infoP.inputs.includes("password") ? "route__admin__users__add__wrap__input--error" : ""}`}>
                <p>[Integration] Account balance reminder if below</p>
                <input ref={balanceTresholdReminderRef} type="text" placeholder="Account balance remind if below" defaultValue={props.edit?.BalanceTresholdReminder} />
            </div>}

            {curUserSelector?.Flags?.isAdmin && <>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.isAdmin ?? false} onChange={e => {
                        if (flags["isAdmin"] !== e) setFlags(flg => {
                            return { ...flg, isAdmin: e };
                        });
                    }} />
                    <span>Administrator</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.isTeamLeader ?? false} onChange={e => {
                        if (flags["isTeamLeader"] !== e) setFlags(flg => {
                            return { ...flg, isTeamLeader: e };
                        });
                    }} />
                    <span>Team leader</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.isManager ?? false} onChange={e => {
                        if (flags["isManager"] !== e) setFlags(flg => {
                            return { ...flg, isManager: e };
                        });
                    }} />
                    <span>Manager</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.isAccountManager ?? false} onChange={e => {
                        if (flags["isAccountManager"] !== e) setFlags(flg => {
                            return { ...flg, isAccountManager: e };
                        });
                    }} />
                    <span>Account manager</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.isSocialMediaManager ?? false} onChange={e => {
                        if (flags["isSocialMediaManager"] !== e) setFlags(flg => {
                            return { ...flg, isSocialMediaManager: e };
                        });
                    }} />
                    <span>Social media manager (restricted UI)</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.socialMediaManager_socialOnly ?? false} onChange={e => {
                        if (flags["socialMediaManager_socialOnly"] !== e) setFlags(flg => {
                            return { ...flg, socialMediaManager_socialOnly: e };
                        });
                    }} />
                    <span>[Social media manager] View social campaigns instead of ads</span>
                </div>

                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.canViewCreatives ?? false} onChange={e => {
                        if (flags["canViewCreatives"] !== e) setFlags(flg => {
                            return { ...flg, canViewCreatives: e };
                        });
                    }} />
                    <span>User can view creatives</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.canAddCreatives ?? false} onChange={e => {
                        if (flags["canAddCreatives"] !== e) setFlags(flg => {
                            return { ...flg, canAddCreatives: e };
                        });
                    }} />
                    <span>User can create creatives</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.canAddBalances ?? false} onChange={e => {
                        if (flags["canAddBalances"] !== e) setFlags(flg => {
                            return { ...flg, canAddBalances: e };
                        });
                    }} />
                    <span>User can add account balances</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.canViewOfferCopies ?? false} onChange={e => {
                        if (flags["canViewOfferCopies"] !== e) setFlags(flg => {
                            return { ...flg, canViewOfferCopies: e };
                        });
                    }} />
                    <span>User can view offer copies</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.canManageOfferCopies ?? false} onChange={e => {
                        if (flags["canManageOfferCopies"] !== e) setFlags(flg => {
                            return { ...flg, canManageOfferCopies: e };
                        });
                    }} />
                    <span>User can manage offer copies</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.canShareCampaign ?? false} onChange={e => {
                        if (flags["canShareCampaign"] !== e) setFlags(flg => {
                            return { ...flg, canShareCampaign: e };
                        });
                    }} />
                    <span>User can share their campaigns via URL</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.canTrackUserBehaviour ?? false} onChange={e => {
                        if (flags["canTrackUserBehaviour"] !== e) setFlags(flg => {
                            return { ...flg, canTrackUserBehaviour: e };
                        });
                    }} />
                    <span>[Campaigns] Allow user behaviour tracking</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.canSearchExternalAds ?? false} onChange={e => {
                        if (flags["canSearchExternalAds"] !== e) setFlags(flg => {
                            return { ...flg, canSearchExternalAds: e };
                        });
                    }} />
                    <span>Can the user search for external ads</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.userVisibleInReports ?? false} onChange={e => {
                        if (flags["userVisibleInReports"] !== e) setFlags(flg => {
                            return { ...flg, userVisibleInReports: e };
                        });
                    }} />
                    <span>Is the user visible in report filters</span>
                </div>
                <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                    <Checkbox defaultValue={props.edit?.Flags?.canRequestBatch ?? false} onChange={e => {
                        if (flags["canRequestBatch"] !== e) setFlags(flg => {
                            return { ...flg, canRequestBatch: e };
                        });
                    }} />
                    <span>[Experimental] Use request batching</span>
                </div>
            </>}
            <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                <Checkbox defaultValue={props.edit?.Flags?.allowPrivateCampaigns ?? true} onChange={e => {
                    if (flags["allowPrivateCampaigns"] !== e) setFlags(flg => {
                        return { ...flg, allowPrivateCampaigns: e };
                    });
                }} />
                <span>Allow private organic campaigns</span>
            </div>
            <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                <Checkbox defaultValue={props.edit?.Flags?.allowSocialCampaigns ?? false} onChange={e => {
                    if (flags["allowSocialCampaigns"] !== e) setFlags(flg => {
                        return { ...flg, allowSocialCampaigns: e };
                    });
                }} />
                <span>Allow Social campaigns</span>
            </div>
            <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                <Checkbox defaultValue={props.edit?.Flags?.allowPrivateIntegrations ?? true} onChange={e => {
                    if (flags["allowPrivateIntegrations"] !== e) setFlags(flg => {
                        return { ...flg, allowPrivateIntegrations: e };
                    });
                }} />
                <span>Allow private integrations</span>
            </div>
            <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                <Checkbox defaultValue={props.edit?.Flags?.allowSMSMarketing ?? false} onChange={e => {
                    if (flags["allowSMSMarketing"] !== e) setFlags(flg => {
                        return { ...flg, allowSMSMarketing: e };
                    });
                }} />
                <span>Allow SMS Marketing</span>
            </div>
            <div style={{display: activeTab === 2 ? null : "none"}} className="route__admin__users__add__wrap__checkbox">
                <Checkbox defaultValue={props.edit?.Flags?.onlyAssignedOffers ?? false} onChange={e => {
                    if (flags["onlyAssignedOffers"] !== e) setFlags(flg => {
                        return { ...flg, onlyAssignedOffers: e };
                    });
                }} />
                <span>Use only assigned offers and their sites</span>
            </div>


            <div className="route__admin__users__add__wrap__buttons">
                <div className="route__admin__users__add__wrap__buttons__btn route__admin__users__add__wrap__buttons__btn--secondary" onClick={onClose}>Cancel</div>
                <div className="route__admin__users__add__wrap__buttons__btn" onClick={addUser}>
                    {spinner ? <Spinner style={{ width: "24px", height: "24px" }} color="white" /> : "Save"}
                </div>
            </div>

            <p className="route__admin__users__add__wrap__infoP" style={{
                opacity: infoP.hadError ? 1 : 0
            }}>{infoP.error}</p>
        </div>
    </div>
};

const ViewIPLogs = (props) => {
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState();
    const [search, setSearch] = React.useState("");

    const mainRef = React.useRef();
    const timestampRef = React.useRef();

    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getIPLogs`,
            data: {
                UserID: props.ID,
                filters: search ? [{name: "IP", op: "like", value: search}] : [],
                limit: 20,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (!data) return;
        if (!canPaginate) return;
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getIPLogs`,
            data: {
                UserID: props.ID,
                filters: [
                    ...(search ? [{name: "IP", op: "like", value: search}] : []),
                    {and: [{name: "ID", op: "notIn", value: data.data.map(d => d.IP)}]}
                ],
                limit: 20,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const removeIPPadding = ip => {
        if (!ip) return ip;

        ip = String(ip);
        if (ip.startsWith("::ffff:")) ip = ip.replace("::ffff:", "");

        return ip;
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => continueData(ts), 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            curDefer(() => getData(ts), 500);
        };

        handler();
    }, [search]);

    return <div className="route__admin__userIPLogs" onClick={onClose}>
        <div className="route__admin__userIPLogs__wrap" ref={mainRef} onClick={e => e.stopPropagation()}>
            <div className="route__admin__userIPLogs__wrap__header">
                <div className="route__admin__userIPLogs__wrap__header__left">IP history</div>
                <div className="route__admin__userIPLogs__wrap__header__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={onClose}></div>
            </div>

            <div className="route__admin__userIPLogs__wrap__content">
                <FilterBySearch onChange={e => setSearch(e)} />
                <br />

                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme="dark"
                    headers={["IP", "Last seen"]}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white"}]];
                        if (data.status !== "ok") return [[{keyID: "noData-error", type: "text", text: "Error occured while fetching data!", style: {color: basicStylesModule.errorColor}}]]

                        let out = [];

                        for (let item of data.data) {
                            out.push([
                                {keyID: item.ID, type: "text", text: removeIPPadding(item.IP)},
                                {keyID: item.ID, type: "text", text: moment(item.lastSeen).toDate().toLocaleString()}
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                        return out;
                    })()}
                />
                {canPaginate && <div style={{width: 0, height: 0, opacity: 0}} ref={curOnScreen.measureRef}></div>}
            </div>
        </div>
    </div>
};

export default AdminUsers