import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";

import { FilteredCustomTable } from "../../customComponents/Table";
import Spinner from "../../customComponents/Spinner";

const CampaignStatsModal = props => {
    const [data, setData] = React.useState();
    const [allOffers, setAllOffers] = React.useState();
    const [allSites, setAllSites] = React.useState();
    const [globalSpinner, setGlobalSpinner] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const timestampRef = React.useRef();
    const curDefer = useDefer();

    const wrapTableItem = item => <span style={{padding: "0 50px 0 0px"}}>{item}</span>;

    const getCampaignIDs = () => {
        if (!props?.item?.ID) return null;

        return props.item.ID;
    };

    const generateKey = item => {
        if (item?.ID) return item.ID;

        let final = "";
        if (!item) return String(item);
        for (let key of Object.keys(item)) {
            if (typeof (item[key]) === "object") {
                final += generateKey(item[key]);
                continue;
            };
            final += String(item[key]);
        };
        return final;
    };

    const getData = (ts) => {
        let tmpFilters = [];
        let tmpTrackFilters = [
            ...(props.filters ?? [])
        ];
        tmpFilters.push({name: "ID", op: "eq", value: props.item.ID});

        let data = {
            IntegrationID: props.integration ? String(props.integration) : "-1",
            limit: 1,
            offset: 0,
            filters: tmpFilters,
            trackFilters: tmpTrackFilters,
            smsFilters: tmpTrackFilters,
            trackGroupByDate: true,
            TableHeaders: [
                "Visits",
                "Conversions",
                "Unique_conversions",

                "Date_SiteID"
            ]
        };

        if (props.integration) {
            if (props.integration.includes("-")) {
                data.IntegrationType = "campaigns";
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        }).finally(() => {
            setGlobalSpinner(false);
        });
    };

    const getTableIntegrationValues = (value, key) => {
        return Array.isArray(value) ? value : [];
    };

    const prepareTableData = () => {
        if (!allOffers) return [];
        if (allOffers.status !== "ok") return null;

        let out = {};

        for (let item of data.data) {
            let Visits = getTableIntegrationValues(item.TableData["Visits"], "ST");
            let Conversions = getTableIntegrationValues(item.TableData["Conversions"], "ST");
            let UniqueConversions = getTableIntegrationValues(item.TableData["Unique_conversions"], "ST");

            for (let v of Visits) {
                if (!out[v.Date_SiteID]) out[v.Date_SiteID] = {
                    Visits: 0,
                    Conversions: 0,
                    UniqueConversions: 0
                };
                let vv = Number(v.Value);
                if (!isNaN(vv)) out[v.Date_SiteID].Visits += vv;
            };
            for (let c of Conversions) {
                if (!out[c.Date_SiteID]) out[c.Date_SiteID] = {
                    Visits: 0,
                    Conversions: 0,
                    UniqueConversions: 0
                };
                let cc = Number(c.Value);
                if (!isNaN(cc)) out[c.Date_SiteID].Conversions += cc;
            };
            for (let c of UniqueConversions) {
                if (!out[c.Date_SiteID]) out[c.Date_SiteID] = {
                    Visits: 0,
                    Conversions: 0,
                    UniqueConversions: 0
                };
                let cc = Number(c.Value);
                if (!isNaN(cc)) out[c.Date_SiteID].UniqueConversions += cc;
            };
        };

        let final = {};
        for (let key of Object.keys(out)) {
            for (let offer of allOffers.data) {
                if (offer.Sites.includes(key)) {
                    if (!final[offer.ID]) final[offer.ID] = {
                        Name: `${offer.Name} (${offer.OfferType ?? "-"}, ${offer.Country ?? "?"})`,
                        Visits: 0,
                        Conversions: 0,
                        UniqueConversions: 0
                    };
                    final[offer.ID].Visits += out[key].Visits;
                    final[offer.ID].Conversions += out[key].Conversions;
                    final[offer.ID].UniqueConversions += out[key].UniqueConversions;
                };
            };
        };

        return Object.keys(final).map(key => {
            return {
                ID: key,
                ...final[key]
            };
        });
    };

    const prepareTableSiteData = () => {
        if (!allSites) return [];
        if (allSites.status !== "ok") return null;

        let out = {};

        for (let item of data.data) {
            let Visits = getTableIntegrationValues(item.TableData["Visits"], "ST");
            let Conversions = getTableIntegrationValues(item.TableData["Conversions"], "ST");
            let UniqueConversions = getTableIntegrationValues(item.TableData["Unique_conversions"], "ST");

            for (let v of Visits) {
                if (!out[v.Date_SiteID]) out[v.Date_SiteID] = {
                    Visits: 0,
                    Conversions: 0,
                    UniqueConversions: 0
                };
                let vv = Number(v.Value);
                if (!isNaN(vv)) out[v.Date_SiteID].Visits += vv;
            };
            for (let c of Conversions) {
                if (!out[c.Date_SiteID]) out[c.Date_SiteID] = {
                    Visits: 0,
                    Conversions: 0,
                    UniqueConversions: 0
                };
                let cc = Number(c.Value);
                if (!isNaN(cc)) out[c.Date_SiteID].Conversions += cc;
            };
            for (let c of UniqueConversions) {
                if (!out[c.Date_SiteID]) out[c.Date_SiteID] = {
                    Visits: 0,
                    Conversions: 0,
                    UniqueConversions: 0
                };
                let cc = Number(c.Value);
                if (!isNaN(cc)) out[c.Date_SiteID].UniqueConversions += cc;
            };
        };

        let final = [];
        for (let key of Object.keys(out)) {
            for (let item of allSites.data) {
                if (item.ID === key) {
                    final.push({
                        ID: key,
                        Name: item.SiteName,
                        URL: item.SiteURL,
                        ...out[key]
                    });
                    break;
                };
            };
        };

        return final;
    };

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                limit: null,
                offset: 0,
                getOfferInfo: true,
                extended: false
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllSites(res.data);
            if (res.data.status === "ok") {
                let out = {};

                for (let item of res.data.data) {
                    if (!item?._Offer) continue;
                    if (!item._Offer?.ID) continue;

                    if (!out[item._Offer.ID]) {
                        out[item._Offer.ID] = {
                            Name: item._Offer.OfferName,
                            Price: item._Offer.OfferPrice,
                            OfferType: item._Offer.OfferType,
                            Country: item._Offer.Country,
                            ResponsiblePerson: item._Offer.ResponsiblePerson,
                            Sites: [item.ID]
                        };
                    } else {
                        out[item._Offer.ID].Sites.push(item.ID);
                    };
                };

                setAllOffers({status: "ok", data: Object.keys(out).map(id => {
                    return {
                        ID: id,
                        ...out[id]
                    };
                })});
            } else {
                setAllOffers(backendModule.genericError);
            };
        }).catch(() => {
            setAllOffers(backendModule.genericError);
        });
    }, []);

    React.useEffect(() => {
        if (data) setGlobalSpinner(true);

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            getData(ts);
        }, 500);
    }, [props.search, props.filters]);

    return <div className="modals__campaignStatsModal__wrap__content" style={{
        gridTemplateColumns: "1fr",
        gridTemplateRows: "100%",
        padding: 0,
        maxHeight: "100%",
        minHeight: "100%",
        height: "100%"
    }} >
        <div className="modals__campaignStatsModal__wrap__content__left">
            <div className="modals__campaignStatsModal__wrap__content__left__tableWrap">
                <h3>Offers</h3>
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Offer", "Visits", "Conversions", "Unique conversions", "CR"]}
                    customColumns={["auto", "auto", "auto", "auto"]}
                    showSpinner={globalSpinner}
                    spinnerColor={"#fff"}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];

                        let out = [];

                        if (data.status === "ok") {
                            let d = prepareTableData();
                            if (!d) {
                                out.push([{ keyID: "noData-error", type: "text", text: "Error while displaying order data!", color: "#f96666" }]);
                            } else {
                                for (let item of d) {
                                    out.push([
                                        {keyID: item.ID, type: "text", text: item.Name},
                                        {keyID: item.ID, type: "text", text: item.Visits},
                                        {keyID: item.ID, type: "text", text: item.Conversions},
                                        {keyID: item.ID, type: "text", text: item.UniqueConversions},
                                        {keyID: item.ID, type: "text", text: Number(100 / item.Visits * item.Conversions).toFixed(0) + "%"}
                                    ]);
                                };

                                if (d.length > 0) out.push([
                                    {keyID: "finalData-totals", type: "text", text: "Total"},
                                    {keyID: "finalData-totals", type: "text", text: d.reduce((acc, val) => acc + val.Visits, 0)},
                                    {keyID: "finalData-totals", type: "text", text: d.reduce((acc, val) => acc + val.Conversions, 0)},
                                    {keyID: "finalData-totals", type: "text", text: d.reduce((acc, val) => acc + val.UniqueConversions, 0)},
                                    {keyID: "finalData-totals", type: "text", text: Number(100 / d.reduce((acc, val) => acc + val.Visits, 0) * d.reduce((acc, val) => acc + val.Conversions, 0)).toFixed(0) + "%"}
                                ]);
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching orders!", color: "#f96666" }]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                        return out;
                    })()}
                />

                <div style={{
                    margin: "30px 0",
                    width: "100%",
                    height: "2px",
                    backgroundColor: themeSelector === "dark" ? "#373A43" : "#E2E2E2"
                }}></div>

                <h3>Sites</h3>
                <FilteredCustomTable
                    theme={themeSelector}
                    accent="#6C5DD3"
                    headers={["Site", "Visits", "Conversions", "Unique conversions", "CR"]}
                    customColumns={["auto", "auto", "auto", "auto"]}
                    showSpinner={globalSpinner}
                    spinnerColor={"#fff"}
                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];

                        let out = [];

                        if (data.status === "ok") {
                            let d = prepareTableSiteData();
                            if (!d) {
                                out.push([{ keyID: "noData-error", type: "text", text: "Error while displaying site data!", color: "#f96666" }]);
                            } else {
                                for (let item of d) {
                                    out.push([
                                        {keyID: item.ID, type: "text", text: <>
                                            <span>{item.Name}</span>
                                            <br />
                                            <span style={{color: "#808191"}}>{item.URL}</span>
                                        </>},
                                        {keyID: item.ID, type: "text", text: item.Visits},
                                        {keyID: item.ID, type: "text", text: item.Conversions},
                                        {keyID: item.ID, type: "text", text: item.UniqueConversions},
                                        {keyID: item.ID, type: "text", text: Number(100 / item.Visits * item.Conversions).toFixed(0) + "%"}
                                    ]);
                                };

                                if (d.length > 0) out.push([
                                    {keyID: "finalData-totals", type: "text", text: "Total"},
                                    {keyID: "finalData-totals", type: "text", text: d.reduce((acc, val) => acc + val.Visits, 0)},
                                    {keyID: "finalData-totals", type: "text", text: d.reduce((acc, val) => acc + val.Conversions, 0)},
                                    {keyID: "finalData-totals", type: "text", text: d.reduce((acc, val) => acc + val.UniqueConversions, 0)},
                                    {keyID: "finalData-totals", type: "text", text: Number(100 / d.reduce((acc, val) => acc + val.Visits, 0) * d.reduce((acc, val) => acc + val.Conversions, 0)).toFixed(0) + "%"}
                                ]);
                            };
                        } else {
                            out.push([{ keyID: "noData-error", type: "text", text: "Error while fetching sites!", color: "#f96666" }]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                        return out;
                    })()}
                />
            </div>
        </div>
    </div>;
};

export default CampaignStatsModal;