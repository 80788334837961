export const getTrackingProfileImage = (profileName) => {
    switch (profileName) {
        case "scale-track": return "/images/logo.svg";
        case "scale-track-sms": return "/images/integrations/integration_smsMarketing.svg";
        case "scale-track-social": return "/images/integrations/integration_socialCampaigns.svg";
        case "facebook": return "/images/integrations/integration_header_facebook.svg";
        case "mgid": return "/images/integrations/integration_header_mgid.svg";
        case "midas": return "/images/integrations/integration_midas.svg";
        case "adnow": return "/images/integrations/integration_adnow.svg";
        default: return "#";
    };
};

export const getFileBase64 =(file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
};

/**
 * Gets the relative x, y, top, left, right and bottom
 * Based on parent-child relations.
 * @param {HTMLDivElement} element The element that will be used for getBoundingClientRect child.
 * @function
 * @returns {{bottom: number, top: number, left: number, right: number, width: number, height: number, x: number, y: number}} The same return as getBoundingClientRect.
 * @example getBoundingClientRectRelative(element);
 */
export const getBoundingClientRectRelative = (element) => {
    if (!element.parentElement) return element.getBoundingClientRect();
    let childPos = element.getBoundingClientRect();
    let parentPos = element.parentElement.getBoundingClientRect();
    return {
        bottom: childPos.bottom - parentPos.bottom,
        top: childPos.top - parentPos.top,
        left: childPos.left - parentPos.left,
        right: childPos.right - parentPos.right,
        width: childPos.width,
        height: childPos.height,
        x: childPos.x - parentPos.x,
        y: childPos.y - parentPos.y
    };
};

export const URLJoin = (...args) => {
    if (!Array.isArray(args)) return null;

    return args
        .join('/')
        .replace(/[\/]+/g, '/')
        .replace(/^(.+):\//, '$1://')
        .replace(/^file:/, 'file:/')
        .replace(/\/(\?|&|#[^!])/g, '$1')
        .replace(/\?/g, '&')
        .replace('&', '?');
};

export const siteRiskColors = (risk) => {
    risk = Number(risk);

    switch (risk) {
        case 1: return "#54ee54";
        case 2: return "#eed654";
        case 3: return "#f88";
        default: return "gray";
    };
};

export const siteRiskColorsLight = (risk) => {
    risk = Number(risk);

    switch (risk) {
        case 1: return "green";
        case 2: return "yellow";
        case 3: return "red";
        default: return "gray";
    };
};

export const genericStatusColorPills = (code) => {
    if (code < 90) return "rgb(96, 86, 34)";
    if (code >= 90 && code <= 99) return "rgb(106, 47, 47)";
    return "rgb(13, 100, 13)";
};

export const getIntegrationImage = integrationID => {
    integrationID = String(integrationID);

    switch (integrationID) {
        case "-1": return "/images/logo.svg";
        case "-2": return "/images/integrations/integration_smsMarketing.svg";
        case "-3": return "/images/integrations/integration_socialCampaigns.svg";
        default:
            if (integrationID.startsWith("fb-")) return "/images/integrations/integration_header_facebook.svg";
            if (integrationID.startsWith("mg-")) return "/images/integrations/integration_header_mgid.svg";
            if (integrationID === "md") return "/images/integrations/integration_midas.svg";

            return "/images/image-missing.png";
    };
};

export const chartColorSets = [
    '#6D71F9', '#4BBEA0', '#E861D8', '#00AFFF', '#4A57DA',
    '#F7944E', '#8A98E8', '#66D9EF', '#FFD86E', '#61C0BF',
    '#FF6F61', '#AEDD8E', '#B993D6', '#4ECCA3', '#FFB74D',
    '#6886C5', '#FF7E63', '#69D2E7', '#B6C867', '#FFC72C',
    '#7E7F9B', '#68D8D6', '#FF616D', '#556EE6', '#FFA07A',
    '#78D0D3', '#C17BDF', '#96E2CE', '#FFCA3A', '#66C3C4',
    '#5A7896', '#FF6F69', '#72C7E7', '#FFA35E', '#778CA3',
    '#FF4E50', '#4CB8C4', '#F4C542', '#434A6E', '#F46060',
    '#6D86A4', '#FFD662', '#8493CA', '#F88584', '#6A737B'
];
export const chartBackgroundColorSets = [
    'rgba(109, 114, 249, 0.5)',
    'rgba(75, 190, 160, 0.5)',
    'rgba(232, 97, 216, 0.5)',
    'rgba(0, 175, 255, 0.5)',
    'rgba(74, 87, 218, 0.5)',
    'rgba(247, 148, 78, 0.5)',
    'rgba(138, 152, 232, 0.5)',
    'rgba(102, 217, 239, 0.5)',
    'rgba(255, 216, 110, 0.5)',
    'rgba(97, 192, 191, 0.5)',
    'rgba(255, 111, 97, 0.5)',
    'rgba(174, 221, 142, 0.5)',
    'rgba(185, 147, 214, 0.5)',
    'rgba(78, 202, 163, 0.5)',
    'rgba(255, 183, 77, 0.5)',
    'rgba(104, 134, 197, 0.5)',
    'rgba(255, 126, 99, 0.5)',
    'rgba(105, 210, 231, 0.5)',
    'rgba(182, 200, 103, 0.5)',
    'rgba(255, 199, 44, 0.5)',
    'rgba(126, 127, 155, 0.5)',
    'rgba(104, 216, 214, 0.5)',
    'rgba(255, 97, 109, 0.5)',
    'rgba(85, 110, 230, 0.5)',
    'rgba(255, 160, 122, 0.5)',
    'rgba(120, 208, 211, 0.5)',
    'rgba(193, 123, 214, 0.5)',
    'rgba(150, 226, 206, 0.5)',
    'rgba(255, 202, 58, 0.5)',
    'rgba(102, 195, 196, 0.5)',
    'rgba(90, 120, 150, 0.5)',
    'rgba(255, 111, 105, 0.5)',
    'rgba(114, 199, 231, 0.5)',
    'rgba(255, 163, 94, 0.5)',
    'rgba(132, 208, 211, 0.5)',
    'rgba(255, 206, 58, 0.5)',
    'rgba(120, 132, 155, 0.5)',
    'rgba(255, 111, 105, 0.5)',
    'rgba(111, 134, 164, 0.5)',
    'rgba(255, 111, 105, 0.5)',
    'rgba(114, 199, 231, 0.5)',
    'rgba(255, 163, 94, 0.5)',
    'rgba(132, 208, 211, 0.5)',
    'rgba(255, 206, 58, 0.5)',
    'rgba(120, 132, 155, 0.5)'
];
  