import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import axios from "axios";

import * as backendModule from "../../../modules/backendModule";

import Spinner from "../../customComponents/Spinner";

const CheckDuplicateAdsModal = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const mainRef = React.useRef();

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                limit: null,
                filters: [
                    {name: "IntegrationID", op: "eq", value: props.integrationID}
                ],
                includeAdditionalData: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const getDuplicateData = () => {
        if (!data) return <p>No data found</p>;
        if (data.status !== "ok") return <p>There was an error while fetching data</p>;

        let tmpData = {};
        for (let item of data.data) {
            if (!item.AdditionalData) continue;
            if (!item.AdditionalData[props.column]) continue;
            if (!Array.isArray(item.AdditionalData[props.column])) continue;

            for (let ad of item.AdditionalData[props.column]) {
                if (!tmpData[ad]) tmpData[ad] = [];
                tmpData[ad].push(item.ID);
            };
        };

        let out = [];
        for (let key of Object.keys(tmpData)) {
            if (tmpData[key].length > 1) {
                let foundCampaigns = data.data.filter(d => tmpData[key].includes(d.ID));

                out.push(<div className="modals__checkDuplicateAdsModal__wrap__content__ad">
                    <p className="modals__checkDuplicateAdsModal__wrap__content__ad__adName">{key}</p>
                    {foundCampaigns.map(c => <p className="modals__checkDuplicateAdsModal__wrap__content__ad__adCampaign">{c.CampaignName}</p>)}
                </div>)
            };
        };

        if (out.length === 0) out.push(<p>No duplicates found!</p>)
        return out;
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="modals__checkDuplicateAdsModal" onClick={onClose}>
        <div className="modals__checkDuplicateAdsModal__wrap" onClick={e => e.stopPropagation()} ref={mainRef}>
            <div className="modals__checkDuplicateAdsModal__wrap__header">
                <div className="modals__checkDuplicateAdsModal__wrap__header__left">Check duplicate ads</div>

                <div className="modals__campaignsDashboard__wrap__header__right" onClick={onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            <div className="modals__checkDuplicateAdsModal__wrap__content">
                {data ? <>
                    {data?.status === "ok" ? <>
                        <p>The following ads have been found in multiple campaigns:</p>
                        {getDuplicateData()}
                    </> : <p>There was an error while fetching data</p>}
                </> : <Spinner color={themeSelector === "dark" ? "white" : "black"} />}
            </div>
        </div>
    </div>
};

export default CheckDuplicateAdsModal;