import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { animateBox } from "../../../modules/componentAnimation";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as backendModule from "../../../modules/backendModule";

import Spinner from "../../customComponents/Spinner";
import { FilteredCustomTable } from "../../customComponents/Table";
import StyledButton from "../../styledComponents/Button";
import FilterColumns from "../../filters/FilterColumns";

import YesNoModal from "../../modals/YesNoModal";

const CampaignShareModal = props => {
    const [data, setData] = React.useState();
    const mainRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/share/getAll`,
            data: {
                CampaignID: props.ID
            },
            ...backendModule.axiosConfig
        }).then(res => setData(res.data)).catch(() => setData(backendModule.genericError));
    };

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    const removeShare = (e, item) => {
        if (!e || !item) return;

        animateBox(e, <YesNoModal
            heading="Remove share"
            text="Removal of this share is irreversible, and the people who use the share URL won't be able to access it anymore. Are you really sure?"
            isRightButtonNormal={true}
            buttonRightCallback={args => {
                args.disabledAll(true);
                args.spinner(true);
                args.errorMessage();

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/campaigns/share/remove`,
                    data: {
                        ID: item.ID
                    },
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        args.close();
                        getData();
                    } else {
                        args.errorMessage("There was an error while removing the share")
                    };
                }).catch(() => {
                    args.errorMessage("Server timed out");
                }).finally(() => {
                    args.disabledAll(false);
                    args.spinner(false);
                });
            }}
        />);
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    React.useEffect(() => {
        getData();
    }, []);

    return <div className="modals__shareCampaign" onClick={onClose}>
        <div className="modals__shareCampaign__wrap" ref={mainRef} onClick={e => e?.stopPropagation()}>
            <div className="modals__shareCampaign__wrap__header">
                <div className="modals__shareCampaign__wrap__header__left">Share Campaign</div>

                <StyledButton style={{marginLeft: "auto", marginRight: "20px", height: "30px"}} onClick={e => animateBox(e, <ShareCampaign onChange={getData} ID={props.ID} integrationID={props.integrationID} columns={props.columns} />)}>Share campaign</StyledButton>

                <div className="modals__shareCampaign__wrap__header__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={onClose}></div>
            </div>

            <div className="modals__shareCampaign__wrap__content">
                {data ? <>
                    {data?.status === "ok" ? <>
                        <FilteredCustomTable
                            theme={themeSelector}
                            headers={["Date", "Details", "Shared columns", "Shared tables"]}
                            style={{width: "100%", columnGap: "50px"}}
                            accent="#6C5DD3"
                            data={(()=>{
                                let out = data.data.map(d => {
                                    return [
                                        {keyID: d.ID, type: "text", text: moment(d.createdAt).toDate().toLocaleString()},
                                        {keyID: d.ID, type: "text", text: d.CampaignDetails, style: {wordBreak: "break-all"}},
                                        {keyID: d.ID, type: "text", text: d.SharedColumns.filter(c => !c.startsWith("#")).join(", "), style: {wordBreak: "break-all"}},
                                        {keyID: d.ID, type: "text", text: d.SharedColumns.filter(c => c.startsWith("#")).join(", "), style: {wordBreak: "break-all"}},
                                        {keyID: d.ID, type: "groupNewline", group: [
                                            {keyID: d.ID, type: "button", text: "Edit", style: {marginRight: "10px"}, onClick: e => animateBox(e, <ShareCampaign onChange={getData} item={d} ID={props.ID} integrationID={props.integrationID} columns={props.columns} />)},
                                            {keyID: d.ID, type: "button", text: "Remove", style: {marginRight: "10px"}, onClick: e => removeShare(e, d)},
                                            {keyID: d.ID, type: "button", text: "Get URL", onClick: e => animateBox(e, <CampaignShareURL URL={`${window.location.origin}/#/share-campaign/${d.ShareKey}`} />)}
                                        ]}
                                    ];
                                });
                                if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

                                return out;
                            })()}
                        />
                    </> : <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>There was an error while fetching data</p>}
                </> : <p>
                    <span>Fetching data, this wont take long...</span>
                    <br />
                    <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />
                </p>}
            </div>
        </div>
    </div>;
};

const ShareCampaign = props => {
    const [columns, setColumns] = React.useState(props.item?.SharedColumns ?? []);
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        text: ""
    });
    const [spinner, setSpinner] = React.useState(false);

    const descriptionRef = React.useRef();

    const shareCamapign = () => {
        let data = {
            CampaignID: props.ID,
            CampaignDetails: descriptionRef.current.value,
            ShareColumns: columns
        };

        if (props.item) data["ID"] = props.item["ID"];

        setInfoP(ip => {return {...ip, hadError: false}});
        if (data.ShareColumns.length === 0) return setInfoP(ip => {return {...ip, hadError: true, text: "At least one column has to be shared"}});

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/share/${props.item ? "edit" : "add"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props.onChange) === "function") props.onChange();
                if (!props.item) {
                    let tmp = document.querySelector(".root__content");
                    if (!tmp) return;
                    animateBox({currentTarget: tmp}, <CampaignShareURL URL={`${window.location.origin}/#/share-campaign/${res.data.data.ShareKey}`} />)
                };
                props.onClose();
            } else {
                setInfoP(ip => {return {...ip, hadError: true, text: `An error occured while ${props.item ? "editing" : "sharing"} the campaign.`}});
            };
        }).catch(() => {
            return setInfoP(ip => {return {...ip, hadError: true, text: "Server timed out."}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Share</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <div className="genericModal__wrap__input genericModal__wrap__input--text">
                <p>Description</p>
                <textarea defaultValue={props.item?.CampaignDetails ?? ""} ref={descriptionRef}></textarea>
            </div>

            <div className="genericModal__wrap__input">
                <p>Select columns to share</p>
                <FilterColumns defaultValue={columns} onChange={e => setColumns(e)} integrationID={`share-${props.integrationID}`} columns={[...props.columns, "#scalecrm-leadid", "#scalelead-id", "#conversion-ip"]} />
            </div>

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && shareCamapign()}>
                    {spinner ? <Spinner color="white" style={{width: "17px", height: "17px"}} /> : props.item ? "Edit" : "Share"}
                </div>
            </div>

            {infoP.text && <p className="genericModal__wrap__infoP" style={{opacity: infoP.hadError ? 1 : 0}}>{infoP.text}</p>}
        </div>
    </div>
};

export const CampaignShareURL = (props) => {
    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">URL</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            <p style={{maxWidth: "100%", wordBreak: "break-all"}}>{props.URL}</p>
        </div>
    </div>
};

export default CampaignShareModal;