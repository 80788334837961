import React from "react";
import "./index.scss";

import useDefer from "../../../modules/hooks/useDefer";

const FilterBySearch = props => {
    const curDefer = useDefer()

    const searchRef = React.useRef();

    React.useEffect(() => {
        if (!searchRef.current) return;

        const keyDownHandler = e => {
            if (!props.onChange) return;
            curDefer(() => props.onChange(e.target.value), 500);
        };

        searchRef.current.addEventListener("keydown", keyDownHandler);
        
        return () => {
            try {
                searchRef.current.removeEventListener("keydown", keyDownHandler);
            } catch {};
        };
    }, [searchRef.current]);

    return <div onKeyDown={props.onKeyDown} className={`components__filters__filterBySearch ${props.className ?? ""}`} style={{...(props.style ?? {})}} onClick={props.onClick}>
        <img src="/images/icon_search.svg" className="components__filters__filterBySearch__img" />
        <input ref={searchRef} type="text" placeholder="Search" className={`${props.inputClassName ?? ""}`} />
    </div>
};

export default FilterBySearch;