import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";

import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as backendModule from "../../../modules/backendModule";
import * as miscModule from "../../../modules/miscModule";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";
import Spinner from "../../../components/customComponents/Spinner";
import StyledInput from "../../../components/styledComponents/Input";
import Dropdown from "../../../components/customComponents/Dropdown";
import { parseCSV } from "../../../modules/csvParserModule";

const UserAccountManager = props => {
    const [data, setData] = React.useState();
    const [selectedAccount, setSelectedAccount] = React.useState();
    const [selectedDate, setSelectedDate] = React.useState(moment().add(-1, "day"));

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const curDefer = useDefer();

    React.useEffect(() => {
        if (!selectedDate) return;
        if (!selectedAccount) return;

        setData();
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/approvedSpendsPerAccount/getAccounts`,
            data: {
                Date: moment(selectedDate).add(12, "hours").toDate().getTime(),
                trackFilters: [
                    { name: "createdAt", op: "pdgeq", value: moment(selectedDate).startOf("day").toDate().getTime() },
                    { name: "createdAt", op: "pdleq", value: moment(selectedDate).endOf("day").toDate().getTime() }
                ],
                Type: selectedAccount,
                utcOffset: (new Date()).getTimezoneOffset()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, [selectedDate, selectedAccount]);

    return <div className="route__user__accountManager">
        <div className="route__user__accountManager__split">
            <AdvancedDropdown
                headline="Select integration"
                data={[
                    { key: "facebook", name: "Facebook", value: "facebook", image: miscModule.getTrackingProfileImage("facebook") },
                    { key: "mgid", name: "Mgid", value: "mgid", image: miscModule.getTrackingProfileImage("mgid") },
                    { key: "midas", name: "Midas", value: "midas", image: miscModule.getTrackingProfileImage("midas") },
                    { key: "adnow", name: "AdNow", value: "adnow", image: miscModule.getTrackingProfileImage("adnow") },
                ]}
                onChange={e => e?.value && setSelectedAccount(e?.value)}
            />

            <StyledInput
                type="date"
                defaultValue={selectedDate.format("YYYY-MM-DD")}
                alternateStyle={true}
                onChange={e => {
                    let tmp = moment(e?.target?.value, "YYYY-MM-DD");
                    if (tmp.isValid()) curDefer(() => setSelectedDate(tmp), 1000);
                }}
            />
        </div>

        {selectedAccount && <>
            {data ? <>
                <FilteredCustomTable
                    accent="#6C5DD3"
                    theme={themeSelector}
                    headers={["Account", "Spent", "Approved", ""]}
                    customColumns={["max-content", "max-content", "max-content", "max-content"]}
                    style={{ columnGap: "40px" }}
                    data={(() => {
                        if (data.status !== "ok") return [[{ keyID: "noData-error", type: "custom", data: "There was an error while fetching data", style: { color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight } }]];

                        const yesColor = themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight;
                        const noColor = themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight;

                        let out = [];
                        let total = 0;
                        for (let usr of data.data) {
                            for (let profile of usr.Profiles) {
                                total += profile.Spent;
                                out.push([
                                    { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: `${usr.Username} - ${profile.Name}` },
                                    { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: `${Number(profile.Spent).toFixed(2)} ${currencySignSelector}` },
                                    { keyID: `${usr.ID}-${profile.IntegrationID}`, type: "text", text: profile.isApproved === null ? "-" : <span style={{ color: profile.isApproved ? yesColor : noColor }}>{profile.isApproved ? "Yes" : "No"} - {Number(profile.ApprovedSpent).toFixed(2)} {currencySignSelector}</span> },
                                    {
                                        keyID: `${usr.ID}-${profile.IntegrationID}`, type: "button", text: "Approve / Reject", style: { border: "none" }, onClick: () => animateBox(<UserAccountManager_approveReject
                                            UserID={usr.ID}
                                            IntegrationID={profile.IntegrationID}
                                            Name={profile.Name}
                                            Spent={`${Number(profile.Spent).toFixed(2)} ${currencySignSelector}`}
                                            Date={moment(selectedDate).add(12, "hours").toDate().getTime()}
                                            onChange={e => {
                                                setData(d => {
                                                    return {
                                                        ...d,
                                                        data: d.data.map(dd => {
                                                            let tmp = { ...dd };
                                                            if (tmp.ID === usr.ID) {
                                                                tmp.Profiles = tmp.Profiles.map(p => {
                                                                    let tmp2 = { ...p };
                                                                    if (p.IntegrationID === profile.IntegrationID) {
                                                                        for (let key of Object.keys(e)) tmp2[key] = e[key];
                                                                    }
                                                                    return tmp2;
                                                                });
                                                            };

                                                            return tmp;
                                                        })
                                                    };
                                                });
                                            }}
                                        />)
                                    }
                                ])
                            };
                        };

                        out.push([
                            { keyID: "total-1", type: "text", text: "Total", isFooter: true },
                            { keyID: "total-1", type: "text", text: `${Number(total).toFixed(2)} ${currencySignSelector}`, isFooter: true },
                            { keyID: "total-1", type: "text", text: "", isFooter: true }
                        ])
                        if (out.length === 0) out.push([{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }]);
                        return out;
                    })()}
                />
            </> : <Spinner style={{ width: "32px", height: "32px" }} color={themeSelector === "dark" ? "white" : "black"} />}
        </>}
    </div>
};

const UserAccountManager_approveReject = props => {
    const [isApproved, setIsApproved] = React.useState();
    const [actualSpent, setActualSpent] = React.useState("");
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        text: "",
        inputs: [],
        hadError: false
    });

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const approveSpent = () => {
        if (spinner) return;

        setInfoP(ip => { return { ...ip, inputs: [], hadError: false } });

        const data = {
            UserID: props.UserID,
            IntegrationID: props.IntegrationID,
            Amount: actualSpent,
            Date: props.Date,
            isApproved: isApproved
        };

        if (!["true", "false"].includes(String(data.isApproved))) setInfoP(ip => { return { ...ip, hadError: true, inputs: ["approved"], text: "Please approve or reject the spent first" } });

        data.Amount = Number(data.Amount);
        if (!data.Amount || isNaN(data.Amount)) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["spent"], text: "Amount is invalid" } });
        if (data.Amount < 0) return setInfoP(ip => { return { ...ip, hadError: true, inputs: ["spent"], text: "Amount must be greater or equals to 0" } });

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/approvedSpendsPerAccount/setSpendStatus`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof (props.onChange) === "function") props.onChange({
                    isApproved: data.isApproved,
                    ApprovedSpent: data.Amount
                });
                props.onClose();
            } else {
                setInfoP(ip => { return { ...ip, hadError: true, inputs: [], text: "There was an error while saving the spent approval!" } });
            };
        }).catch(() => {
            setInfoP(ip => { return { ...ip, hadError: true, inputs: [], text: "Server timed out!" } });
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (isApproved) {
            setActualSpent(String(props.Spent).split(" ")[0].replace(",", ""));
        };
    }, [isApproved]);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Approve / Reject spent</div>
                <div className="genericModal__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
            </div>

            <p>Profile: {props.Name}</p>
            <p>Spent: {props.Spent}</p>
            <br />

            <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("approved") ? "genericModal__wrap__input--error" : ""}`}>
                <p>Is the current spent approved?</p>
                <Dropdown
                    theme={themeSelector}
                    accent="#6C5DD3"
                    data={[
                        { name: "Yes", value: true },
                        { name: "No", value: false }
                    ]}
                    onChange={e => setIsApproved(e?.value)}
                />
            </div>

            {isApproved === false && <div className={`genericModal__wrap__input ${infoP.inputs.includes("spent") ? "genericModal__wrap__input--error" : ""}`}>
                <p>How much spent should there actually be</p>
                <input value={actualSpent} onChange={e => setActualSpent(e?.target?.value)} type="text" placeholder="Actual spent" />
            </div>}

            <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Cancel</div>
                <div className="genericModal__wrap__buttons__btn" onClick={approveSpent}>
                    {spinner ? <Spinner style={{ width: "19px", height: "19px" }} color={themeSelector === "dark" ? "white" : "black"} /> : "Update"}
                </div>
            </div>

            {infoP.text && <p className="genericModal__wrap__infoP" style={{
                opacity: infoP.hadError ? 1 : 0
            }}>{infoP.text}</p>}
        </div>
    </div>
};

export default UserAccountManager;