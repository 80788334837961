import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import useDefer from "../../../modules/hooks/useDefer";
import { chartColorSets, chartBackgroundColorSets } from "../../../modules/miscModule";

import StyledButton from "../../styledComponents/Button";
import Spinner from "../../customComponents/Spinner";

import FilterBySearch from "../../filters/FilterBySearch";
import FilterByDate from "../../filters/FilterByDate";

import OrdersModal_inline from "../OrdersModal/modalInline";
import SessionsModal_inline from "../SessionsModal/modalInline";
import SMSMessagesModal_inline from "../SMSMessagesModal/modalInline";
import CreativeDetailsModal from "../CreativeDetailsModal/modalInline";
import AutoSMSDetailsModal from "../AutoSMSDetailsModal/modalInline";
import IntegrationDetailsModal from "../IntegrationsModal/modalInline";
import CampaignStatsModal from "../CampaignStatsModal/modalInline";
import CampaignLandingRotationsModal from "../CampaignLandingRotationsModal/modalInline";
import CampaignAdsModal from "../CampaignAdsModal/modalinline";
import CampaignTrackEventsModal from "../CampaignTrackEventsModal/modalinline";
import CampaignTrafficSourcesModal from "../CampaignTrafficSourcesModal/modalinline";
import YesNoModal from "../YesNoModal";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const CampaignDashboard = props => {
    const [searchFilter, setSearchFilter] = React.useState("");
    const [dateFilter, setDateFilter] = React.useState();

    const [activeTab, setActiveTab] = React.useState(0);
    const [spinner, setSpinner] = React.useState({
        "1": false,
        "2": false,
        "3": false,
        "4": false,
        "5": false
    });
    const [spinnerButton, setSpinnerButton] = React.useState(false);
    const [orderCount, setOrderCount] = React.useState();
    const [sessionCount, setSessionCount] = React.useState();
    const [smsCount, setSMSCount] = React.useState();
    const [autoSMSCount, setAutoSMSCount] = React.useState();
    const [integrationCount, setIntegrationCount] = React.useState();
    const [eventsCount, setEventsCount] = React.useState();

    const curDefer = useDefer();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const mainRef = React.useRef();
    const tabContentRef = React.useRef();

    const prepareDateFilters = () => {
        let out = [];

        if (dateFilter) {
            if (dateFilter?.start && dateFilter?.end) {
                out.push({ name: "createdAt", op: "pdgeq", value: dateFilter.start.toDate().getTime() });
                out.push({ name: "createdAt", op: "pdleq", value: dateFilter.end.toDate().getTime() });
            };
        };

        return out;
    };

    const prepareSearchFiltersOrders = () => {
        if (!searchFilter) return [];

        return [{or: [
            { name: "ClientData.FirstName", op: "like", value: searchFilter },
            { name: "ClientData.LastName", op: "like", value: searchFilter },
            { name: "ClientData.FirstLastName", op: "like", value: searchFilter },
            { name: "ClientData.PhoneNumber", op: "like", value: searchFilter },

            { name: "LocationData.country", op: "like", value: searchFilter },
            { name: "LocationData.city", op: "like", value: searchFilter },

            { name: "DeviceData.Device.device.type", op: "like", value: searchFilter },

            { name: "NetworkData.IP", op: "like", value: searchFilter },

            { name: "v_integration_scalelead", op: "like", value: searchFilter },
            { name: "v_integration_scalecrmLead", op: "like", value: searchFilter },

            { name: "IntegrationParams.st_source", op: "like", value: searchFilter }
        ]}];
    };

    const prepareSearchFiltersSessions = () => {
        if (!searchFilter) return [];

        return [{or: [
            { name: "LocationData.country", op: "like", value: searchFilter },
            { name: "LocationData.city", op: "like", value: searchFilter },
            { name: "NetworkData.IP", op: "like", value: searchFilter },
            { name: "NetworkData.Referrer", op: "like", value: searchFilter },
            { name: "DeviceData.UserAgent", op: "like", value: searchFilter },
            { name: "DeviceData.Device.os.name", op: "like", value: searchFilter },
            { name: "DeviceData.Device.client.name", op: "like", value: searchFilter },
            { name: "DeviceData.Device.device.type", op: "like", value: searchFilter },

            { name: "IntegrationParams.st_source", op: "like", value: searchFilter }
        ]}];
    };

    const changeTab = tab => {
        if (activeTab === tab) return;
        if (!tabContentRef.current) return setActiveTab(tab);

        tabContentRef.current.animate([
            {opacity: getComputedStyle(tabContentRef.current).opacity},
            {opacity: 0}
        ], {duration: 150, iterations: 1, fill: "both", easing: "linear"}).onfinish = () => {
            setActiveTab(tab);

            tabContentRef.current.animate([
                {opacity: getComputedStyle(tabContentRef.current).opacity},
                {opacity: 1}
            ], {duration: 150, iterations: 1, fill: "both", easing: "linear"})
        };
    };

    const getCampaignIDs = () => {
        if (props.item === null || props.item === undefined) return [];
        return Array.isArray(props.item) ? props.item : [props.item.ID];
    };

    const getOrdersCount = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/countOrders`,
            data: {
                IntegrationType: props.integration,
                CampaignIDs: getCampaignIDs(),
                filters: [
                    ...(props.filters ?? []),
                    ...prepareDateFilters(),
                    ...prepareSearchFiltersOrders()
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setOrderCount(res.data);
        }).catch(() => {
            setOrderCount(backendModule.genericError);
        }).finally(() => {
            setSpinner(s => {
                return {...s, "1": false}
            });
        });
    };

    const getSessionsCount = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/countSessions`,
            data: {
                IntegrationType: props.integration,
                CampaignIDs: getCampaignIDs(),
                filters: [
                    ...(props.filters ?? []),
                    ...prepareDateFilters(),
                    ...prepareSearchFiltersSessions()
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setSessionCount(res.data);
        }).catch(() => {
            setSessionCount(backendModule.genericError);
        }).finally(() => {
            setSpinner(s => {
                return {...s, "2": false}
            });
        });
    };

    const getSMSCount = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/countSMS`,
            data: {
                IntegrationType: props.integration,
                CampaignIDs: getCampaignIDs(),
                filters: [
                    ...(props.filters ?? []),
                    ...prepareDateFilters()
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setSMSCount(res.data);
        }).catch(() => {
            setSMSCount(backendModule.genericError);
        }).finally(() => {
            setSpinner(s => {
                return {...s, "3": false}
            });
        });
    };

    const getAutoSMSCount = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaignAutoSMSHistory/getAllHistoriesCount`,
            data: {
                IntegrationType: props.integration,
                CampaignID: Array.isArray(getCampaignIDs()) ? getCampaignIDs()[0] : getCampaignIDs(),
                filters: [
                    ...(props.filters ?? []),
                    ...prepareDateFilters()
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAutoSMSCount(res.data);
        }).catch(() => {
            setAutoSMSCount(backendModule.genericError);
        }).finally(() => {
            setSpinner(s => {
                return {...s, "4": false}
            });
        });
    };

    const getIntegrationsCount = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/globalIntegrations/jp/getAllIntegrationOrdersCountForAdIDs`,
            data: {
                CampaignID: Array.isArray(getCampaignIDs()) ? getCampaignIDs() : [getCampaignIDs()],
                filters: [
                    ...(props.filters ?? []),
                    ...prepareDateFilters()
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setIntegrationCount(res.data);
        }).catch(() => {
            setIntegrationCount(backendModule.genericError);
        }).finally(() => {
            setSpinner(s => {
                return {...s, "5": false}
            });
        });
    };

    const getEventsCount = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/events/countEvents`,
            data: {
                CampaignID: Array.isArray(getCampaignIDs()) ? getCampaignIDs() : [getCampaignIDs()],
                filters: [
                    ...(props.filters ?? []),
                    ...prepareDateFilters()
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setEventsCount(res.data);
        }).catch(() => {
            setEventsCount(backendModule.genericError);
        }).finally(() => {
            setSpinner(s => {
                return {...s, "8": false}
            });
        });
    };

    const openInCRM = async () => {
        if (spinnerButton) return;

        let wnd = window.open("", "_blank");
        if (!wnd) return;

        wnd.document.head.innerHTML = `
            <style>
                * {margin: 0; padding: 0; box-sizing: border-box;}
                html, body {width: 100%; height: 100%;}
                #inline-container-integration {
                    width: 100vw;
                    height: 100vh;
                    background-color: #232630;
                
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .customComponents__spinner {
                    all: initial;
                    width: 64px;
                    height: 64px;
                    font-family: "Montserrat";
                }
                .customComponents__spinner__path {
                    stroke: #00A3FF;
                    stroke-linecap: round;
                    transform-origin: center;
                    animation: spinner__dash 1.5s ease-in-out infinite, spinner__rotate 2s linear infinite;
                    transition: stroke 0.3s ease;
                }
                
                @keyframes spinner__rotate {
                    100% {
                        transform: rotate(360deg);
                    }
                }
                
                @keyframes spinner__dash {
                    0% {
                        stroke-dasharray: 1, 150;
                        stroke-dashoffset: 0;
                    }
                
                    50% {
                        stroke-dasharray: 90, 150;
                        stroke-dashoffset: -35;
                    }
                
                    100% {
                        stroke-dasharray: 90, 150;
                        stroke-dashoffset: -124;
                    }
                }
            </style>
        `;

        let root = createRoot(wnd.window.document.body);
        root.render(<div id="inline-container-integration">
            <Spinner color="white" />
        </div>);

        setSpinnerButton(true);
        await new Promise(r => setTimeout(() => r(), 500));

        await axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllOrderCRMIDs`,
            data: {
                IntegrationType: props.integration,
                CampaignIDs: getCampaignIDs(),
                filters: [
                    ...(props.filters ?? []),
                    ...prepareDateFilters(),
                    ...prepareSearchFiltersOrders()
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status !== "ok") {
                if (!wnd?.closed) wnd.close();
                return animateBox({currentTarget: document.querySelector(".modals__campaignsDashboard__wrap__header__left")}, <YesNoModal
                    heading="Error"
                    text="There was an error while fetching CRM IDs!"
                    buttonLeftHidden={true}
                    isRightButtonNormal={true}
                    buttonRightText="Ok"
                />);
            };
            if (res.data.data.length === 0) {
                if (!wnd?.closed) wnd.close();
                return animateBox({currentTarget: document.querySelector(".modals__campaignsDashboard__wrap__header__left")}, <YesNoModal
                    heading="Info"
                    text="There are no IDs to show."
                    buttonLeftHidden={true}
                    isRightButtonNormal={true}
                    buttonRightText="Ok"
                />);
            };

            wnd.window.location = `https://scale-crm.com/#/modals/viewLeads?ids=${res.data.data.join(",")}`;
        }).catch(() => {
            if (!wnd?.closed) wnd.close();
            animateBox({currentTarget: document.querySelector(".modals__campaignsDashboard__wrap__header__left")}, <YesNoModal
                heading="Error"
                text="Server timed out while fetching CRM IDs!"
                buttonLeftHidden={true}
                isRightButtonNormal={true}
                buttonRightText="Ok"
            />);
        }).finally(() => {
            setSpinnerButton(false);
        });
    };

    const onClose = (e) => {
        if (e) e?.stopPropagation();
        if (!mainRef.current) return props.onClose();

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: "-100%" }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
        return props.onClose();
    };

    const checkIntegrationHasAds = () => {
        switch (props.integration) {
            case 0:
            case 1:
                return true;
            default:
                return false;
        };
    };

    const getIntegrationAdsCount = () => {
        if (!checkIntegrationHasAds()) return "-";

        let totalCount = 0;
        for (let key of Object.keys(props.item?.AdditionalData ?? {})) {
            if (key.endsWith("_ads")) {
                if (Array.isArray(props.item.AdditionalData[key])) {
                    totalCount += props.item.AdditionalData[key].length;
                };
            };
        };

        return totalCount;
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            { right: getComputedStyle(mainRef.current).right },
            { right: 0 }
        ], { duration: 300, iterations: 1, fill: "both", easing: "ease" });
    }, [mainRef.current]);

    React.useEffect(() => {
        if (!props.item) return props?.onClose();
    }, [props.item]);

    React.useEffect(() => {
        setSpinner({
            "1": true,
            "2": true,
            "3": true,
            "4": true,
            "5": true,
            "8": true
        });
        curDefer(() => {
            getOrdersCount();
            getSessionsCount();
            getIntegrationsCount();
            getEventsCount();
            if (props.integration === -2) getSMSCount();
            if (props.integration === -2) getAutoSMSCount();
        }, 500);
    }, [dateFilter, searchFilter]);

    if (!props.item) return null;
    return <div className="modals__campaignsDashboard" onClick={onClose}>
        <div className="modals__campaignsDashboard__wrap" onClick={e => e.stopPropagation()} ref={mainRef}>
            <div className="modals__campaignsDashboard__wrap__header">
                <div className="modals__campaignsDashboard__wrap__header__left">
                    {props.item.CampaignName}
                    <p style={{fontSize: "14px", color: "gray"}}>{props.item.ID}</p>
                </div>


                <StyledButton isSpinner={spinnerButton} style={{marginLeft: "auto", marginRight: "20px"}} onClick={e => openInCRM(e)}>Open in CRM</StyledButton>

                <div className="modals__campaignsDashboard__wrap__header__right" onClick={onClose} style={{backgroundImage: `url("/images/icon_close.svg")`}}></div>
            </div>

            <div className="modals__campaignsDashboard__wrap__content">
                <div className="modals__campaignsDashboard__wrap__content__filters">
                    <FilterBySearch onChange={e => setSearchFilter(e)} />
                    <FilterByDate onChange={e => setDateFilter(e)} defaultValue={props.datePreset ?? "all"} />
                </div>

                <div className="modals__campaignsDashboard__wrap__content__tabs">
                    <div className={`modals__campaignsDashboard__wrap__content__tabs__tab ${activeTab === -2 ? "modals__campaignsDashboard__wrap__content__tabs__tab--active" : ""}`} onClick={() => changeTab(-2)}>
                        <span>Dashboard</span>
                    </div>
                    <div className={`modals__campaignsDashboard__wrap__content__tabs__tab ${activeTab === -3 ? "modals__campaignsDashboard__wrap__content__tabs__tab--active" : ""}`} onClick={() => changeTab(-3)}>
                        <span>Traffic sources</span>
                    </div>
                    <div className={`modals__campaignsDashboard__wrap__content__tabs__tab ${activeTab === -1 ? "modals__campaignsDashboard__wrap__content__tabs__tab--active" : ""}`} onClick={() => changeTab(-1)}>
                        <span>Offer / Site stats</span>
                    </div>
                    <div className={`modals__campaignsDashboard__wrap__content__tabs__tab ${activeTab === 0 ? "modals__campaignsDashboard__wrap__content__tabs__tab--active" : ""}`} onClick={() => changeTab(0)}>
                        <span>Conversions</span>
                        <span>{(orderCount && !spinner["1"]) ? `(${orderCount.status === "ok" ? orderCount.data : "-"})` : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</span>
                    </div>
                    <div className={`modals__campaignsDashboard__wrap__content__tabs__tab ${activeTab === 1 ? "modals__campaignsDashboard__wrap__content__tabs__tab--active" : ""}`} onClick={() => changeTab(1)}>
                        <span>Sessions</span>
                        <span>{(sessionCount && !spinner["2"]) ? `(${sessionCount.status === "ok" ? sessionCount.data : "-"})` : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</span>
                    </div>
                    {props.integration === -2 && <div className={`modals__campaignsDashboard__wrap__content__tabs__tab ${activeTab === 2 ? "modals__campaignsDashboard__wrap__content__tabs__tab--active" : ""}`} onClick={() => changeTab(2)}>
                        <span>SMS details</span>
                        <span>{(smsCount && !spinner["3"]) ? `(${smsCount.status === "ok" ? smsCount.data : "-"})` : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</span>
                    </div>}
                    {props.integration === -2 && <div className={`modals__campaignsDashboard__wrap__content__tabs__tab ${activeTab === 3 ? "modals__campaignsDashboard__wrap__content__tabs__tab--active" : ""}`} onClick={() => changeTab(3)}>
                        <span>Creative details</span>
                    </div>}
                    {props.integration === -2 && <div className={`modals__campaignsDashboard__wrap__content__tabs__tab ${activeTab === 4 ? "modals__campaignsDashboard__wrap__content__tabs__tab--active" : ""}`} onClick={() => changeTab(4)}>
                        <span>Auto SMS</span>
                        <span>{(autoSMSCount && !spinner["4"]) ? `(${autoSMSCount.status === "ok" ? autoSMSCount.data : "-"})` : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</span>
                    </div>}
                    <div className={`modals__campaignsDashboard__wrap__content__tabs__tab ${activeTab === 5 ? "modals__campaignsDashboard__wrap__content__tabs__tab--active" : ""}`} onClick={() => changeTab(5)}>
                        <span>Integrations</span>
                        <span>{(integrationCount && !spinner["5"]) ? `(${integrationCount.status === "ok" ? integrationCount.data : "-"})` : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</span>
                    </div>
                    {props.item?.AdditionalData?.scalecms_rotation && <div className={`modals__campaignsDashboard__wrap__content__tabs__tab ${activeTab === 6 ? "modals__campaignsDashboard__wrap__content__tabs__tab--active" : ""}`} onClick={() => changeTab(6)}>
                        <span>Landing rotations</span>
                    </div>}
                    {checkIntegrationHasAds() && <div className={`modals__campaignsDashboard__wrap__content__tabs__tab ${activeTab === 7 ? "modals__campaignsDashboard__wrap__content__tabs__tab--active" : ""}`} onClick={() => changeTab(7)}>
                        <span>Ads</span>
                        <span>({getIntegrationAdsCount()})</span>
                    </div>}
                    <div className={`modals__campaignsDashboard__wrap__content__tabs__tab ${activeTab === 8 ? "modals__campaignsDashboard__wrap__content__tabs__tab--active" : ""}`} onClick={() => changeTab(8)}>
                        <span>Track events</span>
                        <span>{(eventsCount && !spinner["8"]) ? `(${eventsCount.status === "ok" ? eventsCount.data : "-"})` : <Spinner style={{width: "14px", height: "14px"}} color={themeSelector === "dark" ? "white" : "black"} />}</span>
                    </div>
                </div>

                <div className="modals__campaignsDashboard__wrap__content__activeContent" ref={tabContentRef}>
                    {(activeTab === -3 && (!props.datePreset || (props.datePreset && (dateFilter || dateFilter === null)))) && <CampaignTrafficSourcesModal integration={props.integrationID} item={props.item} search={searchFilter} filters={[...(props.filters ?? []), ...prepareDateFilters()]} dateFilters={dateFilter} />}
                    {(activeTab === -2 && (!props.datePreset || (props.datePreset && (dateFilter || dateFilter === null)))) && <Dashboard integration={props.integrationID} item={props.item} filters={[...(props.filters ?? []), ...prepareDateFilters()]} dateFilters={dateFilter} />}
                    {activeTab === -1 && <CampaignStatsModal integration={props.integrationID} item={props.item} filters={[...(props.filters ?? []), ...prepareDateFilters()]} dateFilters={dateFilter} />}
                    {(activeTab === 0 && (!props.datePreset || (props.datePreset && (dateFilter || dateFilter === null)))) && <OrdersModal_inline integration={props.integration} item={props.item} search={searchFilter} filters={[...(props.filters ?? []), ...prepareDateFilters()]} />}
                    {activeTab === 1 && <SessionsModal_inline integration={props.integration} item={props.item} search={searchFilter} filters={[...(props.filters ?? []), ...prepareDateFilters()]} />}
                    {(activeTab === 2 && props.integration === -2) && <SMSMessagesModal_inline integration={props.integration} item={props.item} search={searchFilter} filters={[...(props.filters ?? []), ...prepareDateFilters()]} />}
                    {(activeTab === 3 && props.integration === -2) && <CreativeDetailsModal integration={props.integration} item={props.item} search={searchFilter} filters={[...(props.filters ?? []), ...prepareDateFilters()]} />}
                    {(activeTab === 4 && props.integration === -2) && <AutoSMSDetailsModal integration={props.integration} item={props.item} search={searchFilter} filters={[...(props.filters ?? []), ...prepareDateFilters()]} />}
                    {activeTab === 5 && <IntegrationDetailsModal integration={props.integration} item={props.item} search={searchFilter} filters={[...(props.filters ?? []), ...prepareDateFilters()]} />}
                    {activeTab === 6 && <CampaignLandingRotationsModal ID={props.item.ID} filters={[...prepareDateFilters()]} />}
                    {(activeTab === 7 && checkIntegrationHasAds()) && <CampaignAdsModal ID={props.item.ID} integration={props.integrationID} search={searchFilter} filters={[...(props.filters ?? []), ...prepareDateFilters()]} />}
                    {(activeTab === 8) && <CampaignTrackEventsModal ID={props.item.ID} search={searchFilter} filters={[...(props.filters ?? []), ...prepareDateFilters()]} />}
                </div>
            </div>
        </div>
    </div>
};

const Dashboard = props => {
    const [data, setData] = React.useState();

    const dataDefer = useDefer();

    const getData = () => {
        let tmpFilters = [];
        let tmpTrackFilters = [
            ...(props.filters ?? [])
        ];
        tmpFilters.push({name: "ID", op: "eq", value: props.item.ID});

        let data = {
            IntegrationID: props.integration ? String(props.integration) : "-1",
            limit: 1,
            offset: 0,
            filters: tmpFilters,
            trackFilters: tmpTrackFilters,
            smsFilters: tmpTrackFilters,
            trackGroupByDate: true,
            TableHeaders: [
                "Visits",
                "Conversions",
                "AR",
                "CUR",
                "Revenue",
                "Profit",

                "Date_year",
                "Date_month",
                "Date_day"
            ]
        };

        let curDates = getDates();
        if (curDates.length === 1) {
            data.TableHeaders.push("Date_hour");
        };

        if (props.integration) {
            if (props.integration.includes("-")) {
                data.IntegrationType = "campaigns";
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const getTableIntegrationValues = (value, key) => {
        return Array.isArray(value) ? value : [];
    };

    const getDates = (includeHours = false) => {
        if (!props.dateFilters) return;

        let tmp = [];

        let ds = moment(props.dateFilters.start);
        let de = moment(props.dateFilters.end);

        tmp = [];
        while (ds.isBefore(de)) {
            tmp.push(ds.format("DD-MM-YYYY"));
            ds = ds.add(1, "days");
        };

        if (tmp.length === 1 && includeHours) {
            tmp = [];

            ds = moment(props.dateFilters.start);
            de = moment(props.dateFilters.end);

            while (ds.isBefore(de)) {
                tmp.push(ds.format("DD-MM-YYYY HH"));
                ds = ds.add(1, "hours");
            };
        };

        return tmp;
    };

    const getChartData = () => {
        if (!data) return {datasets: [], labels: []};
        if (data.status !== "ok") return {datasets: [], labels: []};

        let labels = getDates(true);
        if (!Array.isArray(labels)) return {datasets: [], labels: []};

        let datasets = [];
        let count = 0;
        let hourFound = false;

        let tcc = [
            "Visits",
            "Conversions",
            "AR",
            "CUR",
            "Revenue",
            "Profit"
        ];
        if (String(props.integration).includes("-")) {
            tcc = tcc.map(tccm => `${tccm} ST`);
        };

        for (let item of tcc) {
            datasets.push({
                fill: false,
                label: item,
                data: [],
                borderColor: chartColorSets[count] ?? "gray",
                backgroundColor: chartBackgroundColorSets[count] ?? "gray"
            });
            count += 1;
        };

        for (let dates of labels) {
            let [dd, h] = dates.split(" ");
            if (h) {
                h = Number(h);
                hourFound = true;
            };
            let [d, m, y] = dd.split("-");
            [d, m, y] = [+d, +m, +y];


            let totals = {};

            for (let item of datasets) totals[item.label] = 0;

            for (let item of data.data) {
                for (let ds of datasets) {
                    let finalColumn = ds.label.replace(/ /g, "_");
                    let int = "ST";
                    if (finalColumn.endsWith("_FB")) int = "INT";
                    if (finalColumn.endsWith("_ST") || finalColumn.endsWith("_FB")) finalColumn = finalColumn.substring(0, finalColumn.length - 3);
                    if (item.TableData[finalColumn]) {
                        for (let obj of getTableIntegrationValues(item.TableData[finalColumn], int)) {
                            if (obj.Date_year === y && obj.Date_month === m && obj.Date_day === d) {
                                if (h !== null && (obj.Date_hour !== h)) continue;
                                totals[ds.label] += +obj.Value;
                            };
                        };
                    };
                };
            };
            for (let ds of datasets) {
                ds.data.push(totals[ds.label]);
            };
        };

        if (!hourFound) {
            labels = labels.map(l => moment(l, "DD-MM-YYYY").toDate().toLocaleDateString());
        } else {
            labels = labels.map(l => moment(l, "DD-MM-YYYY HH").format("HH:00 A"));
        };

        return {datasets, labels};
    };

    React.useEffect(() => {
        setData();
        dataDefer(() => getData(), 1000);
    }, [props.dateFilters, props.filters]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: "index",
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'bottom'
            },
            tooltips: {
                mode: "index",
                intersect: false,
            },
            hover: {
                mode: "nearest",
                intersect: true,
            },
            title: {
                display: false
            }
        },
        scales: {
            yAxes: {
                grid: {
                    color: "#373A43"
                }
            },
            xAxes: {
                grid: {
                    color: "#373A43"
                }
            }
        }
    };

    return <div className="modals__campaignsDashboard__wrap__content__activeContent__chart">
        <div className={`modals__campaignsDashboard__wrap__content__activeContent__chart__spinner ${!data ? "modals__campaignsDashboard__wrap__content__activeContent__chart__spinner--active" : ""}`}>
            <Spinner color="white" />
        </div>

        <Line
            style={{width: "100%", height: "100%", maxHeight: "100%"}}
            options={options}
            data={getChartData()}
        />
    </div>;
};

export default CampaignDashboard;