import React, { createRef } from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import exceljs from "exceljs";
import { useSelector, useDispatch } from "react-redux";

import useDefer from "../../../../modules/hooks/useDefer";
import { chartColorSets, chartBackgroundColorSets } from "../../../../modules/miscModule";
import { animateBox } from "../../../../modules/componentAnimation";
import * as backendModule from "../../../../modules/backendModule";
import * as siteFunctionsActions from "../../../../actions/siteFunctionsActions";
import * as basicStylesModule from "../../../../modules/basicStylesModule";
import * as miscModule from "../../../../modules/miscModule";
import { countries } from "../../../../modules/countryModule";

import AdvancedDropdown from "../../../../components/customComponents/AdvancedDropdown";
import Spinner from "../../../../components/customComponents/Spinner";
import RadioButton from "../../../../components/customComponents/RadioButton";
import StyledButton from "../../../../components/styledComponents/Button";
import StyledInput from "../../../../components/styledComponents/Input";

import FilterByDate from "../../../../components/filters/FilterByDate";
import FilterColumns from "../../../../components/filters/FilterColumns";
import FilterByUserAccount from "../../../../components/filters/FilterByUserAccount";
import FilterBySearch from "../../../../components/filters/FilterBySearch";
import { FilteredCustomTable } from "../../../../components/customComponents/Table";

import OfferSelectModal from "../../../../components/modals/OfferSelectModal";
import YesNoModal from "../../../../components/modals/YesNoModal";
import CampaignAdsModal from "../../../../components/modals/CampaignAdsModal/modalinline";

import { availableCampaignColumns as FacebookColumns } from "../../Campaigns/Facebook";
import { availableCampaignColumns as OrganicColumns } from "../../Campaigns/Organic";
import { availableCampaignColumns as SMSColumns } from "../../Campaigns/SMSMarketing";
import { availableCampaignColumns as GenericColumns, mgidCampaignColumns, midasCampaignColumns, adnowCampaignColumns } from "../../Campaigns/GenericController";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import chartJszoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    chartJszoomPlugin
);

const genericAllColumns = [
    "Conversions", "Approved", "Profit", "Spent IN"
];

const MarketingOffersReport = () => {
    // totalLeads, goodLeads, profit, spent

    const [chartColumns, setChartColumns] = React.useState();
    const [chartColumnsTimestamp, setChartColumnsTimestamp] = React.useState(Date.now());

    const [allUsers, setAllUsers] = React.useState();
    const [allUsersWithMe, setAllUsersWithMe] = React.useState();
    const [allOffers, setAllOffers] = React.useState();
    const [allSites, setAllSites] = React.useState();
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [data, setData] = React.useState();
    const [compareData, setCompareData] = React.useState();
    const [approvedSpentData, setApprovedSpentData] = React.useState();
    const [dateFilters, setDateFilters] = React.useState();
    const [selectedOffer, setSelectedOffer] = React.useState([]);
    const [groupBy, setGroupBy] = React.useState("offer");
    const [localOrder, setLocalOrder] = React.useState();
    const [selectedCountry, setSelectedCountry] = React.useState();
    const [excludedAccounts, setExcludedAccounts] = React.useState([]);
    const [userTrafficSources, setUserTrafficSources] = React.useState("selected");
    const [fetchedSites, setFetchedSites] = React.useState(true);
    const [progress, setProgress] = React.useState(); // {current: 0, maximum: 0}
    const [isCompare, setIsCompare] = React.useState(false);
    const [whatif_edit, setWhatif_edit] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);

    const timestampRef = React.useRef();

    const selectedTrackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? null);
    const allTrackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const socialCostSelector = useSelector(state => state?.types?.socialCost ?? 0);
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const curDefer = useDefer();
    const curDispatch = useDispatch();

    const handleOrderCallback = React.useCallback(o => setLocalOrder(o), []);

    const getCampaignSiteIDs = () => {
        if (allSites?.status !== "ok") return [];
        let tmpData = filterCampaigns();
        if (!tmpData) return [];
        if (tmpData.status !== "ok") return [];

        let out = [];
        for (let item of tmpData.data) {
            if (item.TableData["--SiteID"]) {
                let iv = getTableIntegrationValues(item.TableData["--SiteID"], "ST");
                for (let item of iv) {
                    if (item?.Value) out.push(item.Value);
                };
            };
        };

        return [...new Set(out.flat(3))];
    };

    const returnPossibleChartColumns = () => {
        let out = [];

        let curSelector = selectedTrackingProfileSelector;
        if (selectedUser) {
            if (selectedUser?.IntegrationID) {
                curSelector = selectedUser.IntegrationID;
            };
        };

        if (!curSelector) return out;
        if (curSelector === "-1") out = [...OrganicColumns];
        if (curSelector === "-2") out = [...SMSColumns];
        if (curSelector === "md" || curSelector.startsWith("md-")) out = [...midasCampaignColumns];
        if (curSelector === "an" || curSelector.startsWith("an-")) out = [...adnowCampaignColumns];
        if (["-3"].includes(curSelector)) out = [...GenericColumns];
        if (curSelector.startsWith("fb-")) out = [...FacebookColumns];
        if (curSelector.startsWith("mg-")) out = [...mgidCampaignColumns];
        if (curSelector === "all-all") out = [...genericAllColumns]

        if (out.length === 0) out = [...genericAllColumns];
        return out;
    };

    const filterCampaigns = (additionalFilters = {}, dataSource = data) => {
        if (!additionalFilters) additionalFilters = {};
        if (typeof(additionalFilters) !== "object" || Array.isArray(additionalFilters)) additionalFilters = {};

        let isOr = false;
        if (additionalFilters["_or"]) {
            isOr = !!additionalFilters["_or"];
            delete additionalFilters["_or"];
        };

        let socialFound = false;
        for (let item of dataSource.data) {
            if (item.IntegrationType === -3) {
                socialFound = item;
                break;
            };
        };

        let final = {
            ...data,
            data: [...dataSource.data.map(dd => {
                return { ...dd };
            }), ...(socialFound ? [(() => {
                return null;
                let tmp = {
                    CampaignActive: true,
                    CampaignName: "Social spent flow",
                    CreatedBy: -1,
                    ID: -1,
                    IntegrationID: "social-spent-flow",
                    TableData: []
                };
                let socialSpent = socialFound.TableData["Visits"];
                if (!socialSpent || socialSpent.length === 0) {
                    let curDate = moment();
                    tmp.TableData["Spent"] = [{
                        Date_day: curDate.get("day"),
                        Date_month: curDate.get("month"),
                        Date_year: curDate.get("year"),
                        Value: socialCostSelector
                    }];
                    return tmp;
                } else {
                    let lastDate = { ...socialSpent[socialSpent.length - 1] };
                    lastDate.Value = socialCostSelector;
                    tmp.TableData["Spent"] = [lastDate];
                    return tmp;
                };
            })()].filter(t => t) : [])]
        };
        if (final.status === "ok") {
            if (excludedAccounts) {
                final.data = final.data.filter((d) => {
                    let excluded = false;
                    for (let exclusion of excludedAccounts) {
                        if (exclusion?.UserID && exclusion?.IntegrationID) {
                            if (d.CreatedBy === exclusion.UserID && d.IntegrationID === exclusion.IntegrationID) {
                                excluded = true;
                                break;
                            };
                        } else if (exclusion?.UserID) {
                            if (d.CreatedBy === exclusion.UserID) {
                                excluded = true;
                                break;
                            };
                        } else if (exclusion.IntegrationID) {
                            if (d.IntegrationID === exclusion.IntegrationID) {
                                excluded = true;
                                break;
                            };
                        };
                    };
                    if (excluded) return false;
                    return true;
                });
            };
        };


        if (Object.keys(additionalFilters).length !== 0) {
            if (final.status === "ok") {
                final.data = final.data.filter(f => {
                    let hits = 0;
                    let keys = 0;
                    for (let key of Object.keys(additionalFilters)) {
                        keys += 1;

                        if (Array.isArray(additionalFilters[key])) {
                            if (!additionalFilters[key].includes(f[key])) {
                                if (isOr) {
                                    continue;
                                } else {
                                    return false;
                                };
                            }
                        } else {
                            if (f[key] !== additionalFilters[key]) if (isOr) {
                                continue;
                            } else {
                                return false;
                            };
                        };
                        hits += 1;
                    };
                    if (isOr) {
                        if (hits > 0) return true;
                        if (keys > 0) return false;
                    };
                    return true;
                });
            };
        };

        return final;
    };

    const checkIfExternalIntegration = (campaign, column) => {
        if (column !== "Spent") return "ST";

        let int = String(campaign.IntegrationID);
        if (int.startsWith("fb-") || int.startsWith("mg-")) return "INT";
        return "ST";
    };

    const getTableIntegrationValues = (value, key, integration) => {
        let curSelector = selectedTrackingProfileSelector;
        if (selectedUser) {
            if (selectedUser?.IntegrationID) {
                curSelector = selectedUser.IntegrationID;
            };
        };

        if (String(curSelector).startsWith("-")) {
            let tmp = value[key];
            if (!tmp) return [];
            return Array.isArray(tmp) ? tmp : [];
        } else {
            if (integration === "ST") {
                let tmp = value[key];
                if (!tmp) tmp = [];
                if (!Array.isArray(tmp)) tmp = [];
                return tmp;
            } else {
                let tmp = value[`${key}_IN`];

                if (!tmp) tmp = [];
                if (!Array.isArray(tmp)) tmp = [];
                return Array.isArray(tmp) ? tmp : [];
            };
        };
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: "index",
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'bottom'
            },
            tooltips: {
                mode: "index",
                intersect: false,
            },
            hover: {
                mode: "nearest",
                intersect: true,
            },
            title: {
                display: false
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x'
                },
                zoom: {
                    pinch: {
                        enabled: true
                    },
                    wheel: {
                        enabled: true
                    },
                    mode: 'x'
                }
            }
        },
        scales: {
            yAxes: {
                grid: {
                    color: themeSelector === "dark" ? "#373A43" : "#E2E2E2"
                }
            },
            xAxes: {
                grid: {
                    color: themeSelector === "dark" ? "#373A43" : "#E2E2E2"
                }
            }
        }
    };

    const prepareTableData = (data, column) => {
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
            case "CPC":
            case "CostPerAnyLead":
            case "CostPerLead":
            case "ADP":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${Number(tmpRevenue.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CARC":
            case "CUR":
            case "CRR":
            case "ROI":
            case "CTR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${Number(tmpCR.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${Number(tmpROAS.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}x`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString("en-US");
        };
    };

    const getApprovedSpentData = (ts) => {
        if (dateFilters === undefined || dateFilters === null) return;
        if (timestampRef.current !== ts) return;

        if (!data) return setApprovedSpentData();
        if (data.status === "error") return setApprovedSpentData();

        let tmp = [];
        for (let item of data.data) {
            if (!tmp.includes(item.CreatedBy)) tmp.push(item.CreatedBy);
        };
        if (tmp.length === 0) return setApprovedSpentData();

        let filters = [];
        if (dateFilters) {
            if (dateFilters?.start && dateFilters?.end) {
                filters.push({ name: "Date", op: "dgeq", value: moment(dateFilters.start).add(12, "hours").toDate().getTime() });
                filters.push({ name: "Date", op: "dleq", value: moment(dateFilters.end).toDate().getTime() });
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/approvedSpendsPerAccount/getApprovedSpends`,
            data: {
                allUsers: true,
                filters: [
                    ...filters,
                    {name: "CreatedBy", op: "in", value: tmp}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                let totalSpent = 0;
                for (let s of res.data.data) {
                    totalSpent += +s.Amount;
                };

                let dataSpent = getAggregateDataByDate(null, "Spent", {}, data);
                dataSpent = Number(String(dataSpent).split(" ")[0].replace(",", ""));

                if (dataSpent === 0 && totalSpent === 0) return setApprovedSpentData();
                
                if (Number(totalSpent).toFixed(0) >= Number(dataSpent).toFixed(0)) {
                    setApprovedSpentData(true);
                } else {
                    setApprovedSpentData(false);
                };
            } else {
                setApprovedSpentData(false);
            };
        }).catch(() => {
            setApprovedSpentData();
        });
    };

    const getData = (ts, fetchSites = false, stateFn, fetchCompare = false) => {
        setWhatif_edit(false);
        setSelectedRows([]);

        // if (fetchedSites) fetchSites = true;
        fetchSites = true;
        if (timestampRef.current !== ts) return;
        if (stateFn[0]) stateFn[1]();
        if (!selectedTrackingProfileSelector) return;
        if (dateFilters === undefined || dateFilters === null) return; // prevent "all" filter

        let trackFilters = [];
        let filters = [];
        if (dateFilters) {
            if (dateFilters?.start && dateFilters?.end) {
                if (!fetchCompare) {
                    trackFilters.push({ name: "createdAt", op: "pdgeq", value: dateFilters.start.toDate().getTime() });
                    trackFilters.push({ name: "createdAt", op: "pdleq", value: dateFilters.end.toDate().getTime() });
                } else {
                    let ds = moment(dateFilters?.start);
                    let de = moment(dateFilters?.end);
                    let dateDiff = ds.diff(de, "days") - 1;

                    trackFilters.push({ name: "createdAt", op: "pdgeq", value: moment(dateFilters.start).add(dateDiff, "days").toDate().getTime() });
                    trackFilters.push({ name: "createdAt", op: "pdleq", value: moment(dateFilters.end).add(dateDiff, "days").toDate().getTime() });
                };
            } else {
                stateFn[1]({status: "ok", data: []});
                if (isCompare && !fetchCompare) getData(ts, fetchSites, [compareData, setCompareData], true);
                return;
            };
        };
        let smsFilters = [...trackFilters];

        let uInfo = {
            UserID: null,
            IntegrationID: selectedTrackingProfileSelector,
            ...(String(selectedTrackingProfileSelector).startsWith("-") ? {} : {
                IntegrationType: "campaigns"
            }),

            utcOffset: (new Date()).getTimezoneOffset()
        };

        if (selectedUser) {
            for (let key of Object.keys(selectedUser)) {
                uInfo[key] = selectedUser[key];
            };
            if (uInfo?.IntegrationID) {
                if (!uInfo.IntegrationID?.startsWith?.("-")) {
                    uInfo.IntegrationType = "campaigns";
                };
            };
        };

        if (selectedOffer.length > 0) {
            trackFilters.push({ name: "SiteID", op: "in", value: selectedOffer.map(s => s?.Sites ?? []).flat(3) ?? [] });
            filters.push({
                or: [
                    { name: "PreLandingSiteID", op: "in", value: selectedOffer.map(s => s?.Sites ?? []).flat(3) ?? [] },
                    { name: "LandingSiteID", op: "in", value: selectedOffer.map(s => s?.Sites ?? []).flat(3) ?? [] }
                ]
            });
        };

        if (selectedCountry && allOffers.status === "ok") {
            let found = false;
            for (let offer of allOffers.data) {
                if (offer.Country !== selectedCountry) continue;
                found = true;

                filters.push({
                    or: [
                        { name: "PreLandingSiteID", op: "in", value: offer?.Sites ?? [] },
                        { name: "LandingSiteID", op: "in", value: offer?.Sites ?? [] }
                    ]
                });
            };

            if (!found) {
                stateFn[1]({ status: "ok", data: [] });
                if (isCompare && !fetchCompare) getData(ts, fetchSites, [compareData, setCompareData], true);
                return;
            };
        };

        let th = [
            "Conversions",
            "Approved",
            "AR",
            "ARC",
            "Cost",
            "Revenue",
            "Spent",
            "Profit",
            "CPA",
            "Visits",
            "CR",
            "CTR_IN",
            "CPC",
            "CARC",
            "ARC",
            "DRC",

            "Date_day", "Date_month", "Date_year",
            "ROI", "ROAS", "ADP",
            "--ADP",
            ...(fetchSites ? [
                "Date_SiteID",
                "--SiteID"
            ] : [])
        ];
        if (fetchSites && !fetchedSites) setFetchedSites(true);

        if (Array.isArray(chartColumns)) {
            let tcc = [...chartColumns];
            th.push(...tcc.map(c => {
                if (c.startsWith("Site_")) return null;
                c = c.replace(/ /g, "_");
                return c;
            }).filter(t => t));
        };
        if (chartColumns.includes("Site_Visits")) th.push("Visits", "Date_SiteID");
        if (chartColumns.includes("Site_Conversions")) th.push("Conversions", "Date_SiteID");
        if (chartColumns.includes("Site_CR")) th.push("Visits", "Conversions", "Date_SiteID");

        let curDates = getDates();
        if (curDates.length === 1) {
            th.push("Date_hour");
        };

        th = [...new Set(th)];

        if (selectedUser || userTrafficSources === "all") {
            if (selectedUser?.UserID?.startsWith("all-team-") || selectedUser?.IntegrationID?.endsWith("-all") || userTrafficSources === "all") {
                return getDataTeam(ts, {
                    ...uInfo,

                    trackFilters: [
                        ...trackFilters
                    ],
                    filters,
                    smsFilters,
                    trackGroupByDate: true,
                    offset: 0,
                    limit: null,
                    TableHeaders: th,
                    fetchNextNode: true
                }, selectedUser?.IntegrationID !== "usr-all", stateFn).then(() => {
                    if (isCompare && !fetchCompare) getData(ts, fetchSites, [compareData, setCompareData], true);
                });
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                ...uInfo,

                trackFilters: [
                    ...trackFilters
                ],
                filters,
                smsFilters: trackFilters,
                trackGroupByDate: true,
                offset: 0,
                limit: null,
                TableHeaders: th,
                fetchNextNode: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;

            stateFn[1](res.data);
            if (isCompare && !fetchCompare) getData(ts, fetchSites, [compareData, setCompareData], true);
        }).catch(() => {
            if (timestampRef.current !== ts) return;

            stateFn[1](backendModule.axiosConfig);
            if (isCompare && !fetchCompare) getData(ts, fetchSites, [compareData, setCompareData], true);
        });
    };

    const getDataTeam = async (ts, info, includeAllUsers = true, stateFn) => {
        let finalUsers = [];
        let batches = [];

        if (includeAllUsers && selectedUser?.UserID) {
            if (!allUsers) return stateFn[1](backendModule.genericError);
            if (allUsers.status === "error") return stateFn[1](backendModule.genericError);

            finalUsers = [
                {
                    ID: userInfoSelector.ID,
                    Team: userInfoSelector?.Team ?? null,
                    _profiles: allTrackingProfilesSelector
                },
                ...(allUsers?.data ? allUsers.data : [])
            ];
        } else {
            if (selectedUser?.UserID) {
                let fUsr = allUsersWithMe.data.find(d => d.ID === selectedUser?.UserID);
                if (fUsr) {
                    finalUsers.push({
                        ID: fUsr.ID,
                        Team: fUsr?.Team ?? null,
                        _profiles: Array.isArray(fUsr._profiles) ? fUsr._profiles : []
                    });
                };
            };
        };
        if (userTrafficSources === "all" && (!selectedUser || !selectedUser?.UserID)) {
            finalUsers = [
                {
                    ID: userInfoSelector.ID,
                    Team: userInfoSelector?.Team ?? null,
                    _profiles: allTrackingProfilesSelector
                }
            ];
            info.IntegrationID = "all-all";
        };

        if (selectedUser?.UserID?.startsWith("all-team--")) {
            //? filter by team name...
            let teamName = selectedUser.UserID.substring(10, selectedUser.UserID.length);
            finalUsers = finalUsers.filter(f => f?.Team === teamName);
        };

        if (ts !== timestampRef.current) return;
        let out = [];
        if (!["fb-all", "mg-all", "md-all", "an-all", "all-all", "usr-all"].includes(info?.IntegrationID)) {
            let completedIntegrations = [];
            await Promise.allSettled(finalUsers.map(usr => new Promise(async r => {
                let tmpReq = { ...info, UserID: usr.ID };

                if (completedIntegrations.includes(tmpReq?.IntegrationID)) return r();
                completedIntegrations.push(tmpReq?.IntegrationID);

                setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                    batches.push({...tmpReq});
                    setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                    r();
                } else {
                    await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                        data: tmpReq,
                        ...backendModule.axiosConfig
                    }).then(final => {
                        if (final.data.status === "ok") out.push(final.data.data);
                        setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                    }).finally(r);
                };
            })));

        } else {
            if (info.IntegrationID === "fb-all") {
                let completedIntegrations = [];
                let isFixedUser = false;
                if (selectedUser?.UserID) {
                    if (selectedUser?.UserID?.split?.("-").length === 5) isFixedUser = true;
                };
                await Promise.allSettled(finalUsers.map(usr => {
                    if (isFixedUser) {
                        if (selectedUser?.UserID !== usr?.ID) return null;
                    };
                    return usr._profiles.filter(p => p.ID.startsWith("fb-")).map(profile => new Promise(async r => {
                        let tmpReq = { ...info, UserID: usr.ID, IntegrationID: profile.ID };

                        if (completedIntegrations.includes(tmpReq?.IntegrationID)) return r();
                        completedIntegrations.push(tmpReq?.IntegrationID);

                        setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                            batches.push({...tmpReq});
                            setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            r();
                        } else {
                            await axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                                data: tmpReq,
                                ...backendModule.axiosConfig
                            }).then(final => {
                                if (final.data.status === "ok") out.push(final.data.data);
                                setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            }).finally(r);
                        };
                    }));
                }).flat(5).filter(t => t));
            } else if (info.IntegrationID === "mg-all") {
                let completedIntegrations = [];
                await Promise.allSettled(finalUsers.map(usr => {
                    return usr._profiles.filter(p => p.ID.startsWith("mg-")).map(profile => new Promise(async r => {
                        let tmpReq = { ...info, UserID: usr.ID, IntegrationID: profile.ID };

                        if (completedIntegrations.includes(tmpReq?.IntegrationID)) return r();
                        completedIntegrations.push(tmpReq?.IntegrationID);

                        setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                            batches.push({...tmpReq});
                            setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            r();
                        } else {
                            await axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                                data: tmpReq,
                                ...backendModule.axiosConfig
                            }).then(final => {
                                if (final.data.status === "ok") out.push(final.data.data);
                                setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            }).finally(r);
                        };
                    }));
                }).flat(5).filter(t => t));
            } else if (info.IntegrationID === "md-all") {
                let completedIntegrations = [];
                await Promise.allSettled(finalUsers.map(usr => {
                    return usr._profiles.filter(p => p.ID === "md").map(profile => new Promise(async r => {
                        let tmpReq = { ...info, UserID: usr.ID, IntegrationID: profile.ID };

                        if (completedIntegrations.includes(`${usr.ID}-${tmpReq?.IntegrationID}`)) return r();
                        completedIntegrations.push(`${usr.ID}-${tmpReq?.IntegrationID}`);

                        setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                            batches.push({...tmpReq});
                            setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            r();
                        } else {
                            await axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                                data: tmpReq,
                                ...backendModule.axiosConfig
                            }).then(final => {
                                if (final.data.status === "ok") out.push(final.data.data);
                                setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            }).finally(r);
                        };
                    }));
                }).flat(5).filter(t => t));
            } else if (info.IntegrationID === "an-all") {
                let completedIntegrations = [];
                await Promise.allSettled(finalUsers.map(usr => {
                    return usr._profiles.filter(p => p.ID === "an").map(profile => new Promise(async r => {
                        let tmpReq = { ...info, UserID: usr.ID, IntegrationID: profile.ID };

                        if (completedIntegrations.includes(`${usr.ID}-${tmpReq?.IntegrationID}`)) return r();
                        completedIntegrations.push(`${usr.ID}-${tmpReq?.IntegrationID}`);

                        setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                            batches.push({...tmpReq});
                            setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            r();
                        } else {
                            await axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                                data: tmpReq,
                                ...backendModule.axiosConfig
                            }).then(final => {
                                if (final.data.status === "ok") out.push(final.data.data);
                                setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            }).finally(r);
                        };
                    }));
                }).flat(5).filter(t => t));
            } else if (info.IntegrationID === "all-all" || info.IntegrationID === "usr-all") {
                let completedIntegrations = [];
                await Promise.allSettled(finalUsers.map(usr => {
                    return usr._profiles.map(profile => new Promise(async r => {
                        let tmpReq = { ...info, UserID: usr.ID, IntegrationID: profile.ID };

                        let tmp_str = tmpReq?.IntegrationID;
                        if (!String(tmp_str).includes("-")) tmp_str = `${usr.ID}-${tmp_str}`;
                        if (completedIntegrations.includes(tmp_str)) return r();
                        completedIntegrations.push(tmp_str);

                        setProgress(p => !p ? { current: 0, maximum: 1 } : { ...p, maximum: p.maximum + 1 });

                        tmpReq.TableHeaders = [...tmpReq.TableHeaders].map(th => {
                            if (["mg-", "fb-"].includes(profile.ID)) return th;

                            if (th.includes("_IN")) return th.substring(0, th.length - 3);
                            return th;
                        });

                        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
                            batches.push({...tmpReq});
                            setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            r();
                        } else {
                            await axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                                data: tmpReq,
                                ...backendModule.axiosConfig
                            }).then(final => {
                                if (final.data.status === "ok") out.push(final.data.data);
                                setProgress(p => !p ? { current: 1, maximum: 1 } : { ...p, current: p.current + 1 });
                            }).catch(() => null).finally(r);
                        };
                    }));
                }).flat(5).filter(t => t));
            };
        };

        if (userInfoSelector?.Flags?.["canRequestBatch"]) {
            for (let b of batches) b["BatchMethod"] = "/campaigns/getAllCampaigns";

            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/requestBatching/create`,
                data: {
                    Batches: batches
                },
                ...backendModule.axiosConfig
            }).then(final => {
                if (final.data.status === "ok") {
                    for (let d of final.data.data) {
                        if (d?.status === "ok") out.push(d.data);
                    };
                };
            });
        };

        if (ts !== timestampRef.current) return;
        stateFn[1]({ status: "ok", data: out.flat(5) });
        setProgress();
    };

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsersTeamLead`,
            data: {
                excludeSelf: true,
                includeProfiles: true,
                allUsers: true,
                filters: [
                    { name: "Flags:userVisibleInReports", op: "eq", value: true }
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllUsers(res.data);
            if (res.data.status === "ok") {
                return setAllUsersWithMe({
                    status: "ok", data: [{
                        ...userInfoSelector,
                        _profiles: allTrackingProfilesSelector
                    }, ...res.data.data]
                });
            };
            setAllUsersWithMe(res.data);
        }).catch(() => {
            setAllUsers(backendModule.genericError);
            setAllUsersWithMe(backendModule.genericError);
        });
    };

    const getKPIData = React.useCallback((column, dataSource) => {
        if (!dataSource) return "-";
        if (dataSource.status !== "ok") return "-";
        let finalData = filterCampaigns(null, dataSource);

        let total = 0;

        if (column === "BounceRate") {
            return "-";
        };
        if (column === "CTR") {
            let totalCTR = 0;
            let totalCount = 0;

            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "CTR", "INT")) {
                    if (!Number(final.Value)) continue;
                    console.log(final);
                    totalCTR += +final.Value;
                    totalCount += 1;
                };
            };

            if (totalCount === 0) return prepareTableData(0, "CR");
            let trc = totalCTR / totalCount;
            if (isNaN(trc)) return prepareTableData(0, "CR");
            return prepareTableData(trc, "CR");
        };
        if (column === "CPC") {
            let totalVisits = Number(getAggregateDataByDate(null, "Visits", {}, dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByDate(null, "Spent", {}, dataSource).split(" ")[0].replace(",", ""));

            if (totalVisits === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData(totalSpent / totalVisits, "Spent");
        };
        if (column === "ROI") {
            let totalProfit = Number(getAggregateDataByDate(null, "Profit", {}, dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByDate(null, "Spent", {}, dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROI");
            return prepareTableData((totalProfit / totalSpent) * 100, "ROI");
        };
        if (column === "ROAS") {
            let totalProfit = Number(getAggregateDataByDate(null, "Revenue", {}, dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByDate(null, "Spent", {}, dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROAS");
            return prepareTableData((totalProfit / totalSpent) * 100, "ROAS");
        };
        if (column === "Cost") {
            let totalSpent = 0;
            let totalConversions = 0;

            for (let item of finalData.data) {
                totalSpent += getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent")).reduce((acc, val) => acc + +val.Value, 0);
                totalConversions += getTableIntegrationValues(item.TableData, "Conversions", "ST").reduce((acc, val) => acc + +val.Value, 0);
            };
            if (totalConversions === 0) totalConversions = 1;
            return prepareTableData(Number(totalSpent / totalConversions).toFixed(2), "Spent");
        };
        if (column === "Profit") {
            let totalSpent = Number(getAggregateDataByDate(null, "Spent", {}, dataSource).split(" ")[0].replace(",", ""));
            let totalRevenue = Number(getAggregateDataByDate(null, "Revenue", {}, dataSource).split(" ")[0].replace(",", ""));

            return prepareTableData(Number(totalRevenue - totalSpent).toFixed(2), "Spent");
        };

        if (["CostPerLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            let totalARC = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    totalARC += +final.Value;
                };
            };
            totalSpent = getKPIData("CostPerAnyLead", dataSource);
            totalSpent = Number(totalSpent.split(" ")[0]);
            let final = 0;
            if (totalARC === 0 || totalSpent === 0 || totalLeads === 0) {
                final = 0;
            } else {
                let tmparc = (100 / totalARC) * totalLeads;
                if (tmparc > 0) {
                    final = totalSpent / (tmparc / 100);
                };
            };
            return prepareTableData(final, "Spent");
        };

        if (["CostPerAnyLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    totalLeads += final.Value ? +final.Value : 0;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent"))) {
                    totalSpent += final.Value ? +final.Value : 0;
                };
            };
            if (totalLeads === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData(totalSpent / totalLeads ?? 0, "Spent");
        };

        if (["ADP"].includes(column)) {
            let totalADP = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "--ADP", "ST")) {
                    if (Array.isArray(final.Value)) {
                        for (let final2 of final.Value) {
                            totalCount += 1;
                            totalADP += final2 ? +final2 : 0;
                        };
                    };
                };
            };

            let final = 0;
            if (totalCount === 0 && totalADP === 0) return "-";
            if (totalCount <= 0) totalCount = 1;
            if (!totalADP) {
                final = 0;
            } else {
                final = totalADP / totalCount;
            };
            return prepareTableData(final, "Spent");
        };

        if (["CR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };

        if (["AR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };

        for (let item of finalData.data) {
            // dont forget to handle ST and INT
            // handle AR -_-
            if (["ADP", "AR"].includes(column)) {
                let tmp = getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column)).reduce((acc, val) => acc + +val.Value, 0);
                let tc = getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column)).length;
                if (tc === 0) return "-";
                tmp /= tc;
                total += tmp;
            } else {
                total += getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column)).reduce((acc, val) => acc + +val.Value, 0);
            };
            if (isNaN(total)) total = 0;
        };

        if (column === "AR") total /= finalData.data.length;

        return prepareTableData(total, column);
    }, [data, compareData]);

    const getAggregateDataByDate = React.useCallback((date, column, additionalFilters = {}, dataSource = data) => {
        let finalData = filterCampaigns(additionalFilters, dataSource);
        if (!finalData) return "-";
        if (finalData.status === "error") return "-";

        let [dd, h] = date ? date.split(" ") : [null, null];
        if (h) h = Number(h);
        let [d, m, y] = dd ? dd.split("-") : [null, null, null];
        if (date) [d, m, y] = [+d, +m, +y];

        if ([
            "Conversions", "Approved", "Revenue", "Spent", "Profit",
            "Visits", "CTR", "CPC", "CARC", "ARC", "DRC"
        ].includes(column)) {
            let total = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column))) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        total += final.Value ? +final.Value : 0;
                        totalCount += 1;
                    }
                };
            };
            if (["CR", "CTR", "CPC", "CARC"].includes(column)) total /= totalCount;
            if (isNaN(total)) total = 0;
            return prepareTableData(total, column);
        };

        if (["DR"].includes(column)) {
            let totalApproved = Number(getAggregateDataByDate(date, "Approved", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            let totalDRC = Number(getAggregateDataByDate(date, "DRC", additionalFilters, dataSource).split(" ")[0].replace(",", ""));

            if (totalApproved === 0 || totalDRC === 0) return prepareTableData(0, "CR");
            return prepareTableData((100 / totalApproved * totalDRC), "CR");
        };
        if (["ROI"].includes(column)) {
            let totalProfit = Number(getAggregateDataByDate(date, "Profit", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByDate(date, "Spent", additionalFilters, dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROI");
            return prepareTableData((totalProfit / totalSpent) * 100, "ROI");
        };
        if (["ROAS"].includes(column)) {
            let totalProfit = Number(getAggregateDataByDate(date, "Revenue", additionalFilters, dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByDate(date, "Spent", additionalFilters, dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROAS");
            return prepareTableData((totalProfit / totalSpent), "ROAS");
        };
        if (["AR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalLeads += +final.Value;
                    };
                };
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalSpent += +final.Value;
                    };
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["PaymentPerLead"].includes(column)) {
            let finalOut = 0;
            let finalSites = [];

            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        if (!final.Date_SiteID) continue;
                        if (!finalSites.includes(final.Date_SiteID)) finalSites.push(final.Date_SiteID);
                    };
                };
            };

            for (let o of (allOffers?.status === "ok" ? allOffers.data : [])) {
                for (let site of o.Sites) {
                    if (finalSites.includes(site)) {
                        finalOut = Number(o.Price);
                        break;
                    };
                };
                if (finalOut) break;
            };

            return prepareTableData(finalOut, "Spent");
        };

        if (["CostPerLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            let totalARC = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalLeads += +final.Value;
                    };
                };
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalARC += +final.Value;
                    };
                };
            };
            totalSpent = getAggregateDataByDate(date, "CostPerAnyLead", additionalFilters, dataSource);
            totalSpent = Number(totalSpent.split(" ")[0].replace(",", ""));
            let final = 0;
            if (totalARC === 0 || totalSpent === 0 || totalLeads === 0) {
                final = 0;
            } else {
                let tmparc = (100 / totalARC) * totalLeads;
                if (tmparc > 0) {
                    final = totalSpent / (tmparc / 100);
                };
            };
            return prepareTableData(final, "Spent");
        };

        if (["CostPerAnyLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalLeads += final.Value ? +final.Value : 0;
                    };
                };
                for (let final of getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent"))) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalSpent += final.Value ? +final.Value : 0;
                    };
                };
            };

            if (totalLeads === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData(totalSpent / totalLeads ?? 0, "Spent");
        };

        if (["CR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalLeads += +final.Value;
                    };
                };
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalSpent += +final.Value;
                    };
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };

        if (["Profit"].includes(column)) {
            let totalRevenue = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Revenue", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalRevenue += +final.Value;
                    };
                };
                for (let final of getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent"))) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        totalSpent += +final.Value;
                    };
                };
            };
            let final = totalRevenue - totalSpent;

            return prepareTableData(final, "Spent");
        };

        if (["ADP"].includes(column)) {
            let totalADP = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "--ADP", "ST")) {
                    if (d === null || (final.Date_day === d && final.Date_month === m && final.Date_year === y)) {
                        if (h !== null && (h !== final.Date_hour)) continue;
                        if (Array.isArray(final.Value)) {
                            for (let final2 of final.Value) {
                                totalCount += 1;
                                totalADP += final2 ? +final2 : 0;
                            };
                        };
                    };
                };
            };

            let final = 0;
            if (totalCount === 0 && totalADP === 0) return "-";
            if (totalCount <= 0) totalCount = 1;
            if (!totalADP) {
                final = 0;
            } else {
                final = totalADP / totalCount;
            };
            return prepareTableData(final, "Spent");
        };
    }, [data, compareData]);

    const getAggregateDataByOffer = React.useCallback((offer, column, additionalFilters = {}, dataSource = data, premadeData = null) => {
        if (Object.keys(additionalFilters).length === 0 && offer) {
            additionalFilters = {
                _or: true,
                LandingSiteID: offer.Sites,
                PreLandingSiteID: offer.Sites
            };
        };
        let finalData = premadeData ? premadeData : filterCampaigns(additionalFilters, dataSource);
        if (!finalData) return "-";
        if (finalData.status === "error") return "-";

        if ([
            "Conversions", "Approved", "Revenue", "Spent", "Profit",
            "Visits", "CTR", "CPC", "ARC", "DRC"
        ].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let total = 0;
            let totalCount = 0;
            
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column))) {
                    if (offer !== null && !offer.Sites.includes(final.Date_SiteID)) continue;
                    total += final.Value ? +final.Value : 0;
                    totalCount += 1;
                };
            };
            if (["CR", "CTR", "CPC", "ROI", "ROAS"].includes(column)) total /= totalCount;
            if (isNaN(total)) total = 0;
            return prepareTableData(total, column);
        };

        if (["DR"].includes(column)) {
            let totalApproved = Number(getAggregateDataByOffer(offer, "Approved", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            let totalDRC = Number(getAggregateDataByOffer(offer, "DRC", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));

            if (totalApproved === 0 || totalDRC === 0) return prepareTableData(0, "CR");
            return prepareTableData((100 / totalApproved * totalDRC), "CR");
        };
        if (["ROI"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalProfit = Number(getAggregateDataByOffer(offer, "Profit", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByOffer(offer, "Spent", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROI");
            return prepareTableData((totalProfit / totalSpent) * 100, "ROI");
        };
        if (["ROAS"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalProfit = Number(getAggregateDataByOffer(offer, "Revenue", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByOffer(offer, "Spent", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROAS");
            return prepareTableData((totalProfit / totalSpent), "ROAS");
        };
        if (["AR"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalLeads = Number(getAggregateDataByOffer(offer, "ARC", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByOffer(offer, "Approved", additionalFilters, dataSource, finalData).split(" ")[0].replace(",", ""));

            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["PaymentPerLead"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let finalOut = 0;
            if (offer !== null) return prepareTableData(Number(offer.Price), "Spent");

            let finalSites = [];

            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (offer !== null && !offer.Sites.includes(final.Date_SiteID)) continue;
                    if (!finalSites.includes(final.Date_SiteID)) finalSites.push(final.Date_SiteID);
                };
            };

            for (let o of (allOffers?.status === "ok" ? allOffers.data : [])) {
                for (let site of o.Sites) {
                    if (finalSites.includes(site)) {
                        finalOut = Number(o.Price);
                        break;
                    };
                };
                if (finalOut) break;
            };

            return prepareTableData(finalOut, "Spent");
        };

        if (["CostPerLead"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalLeads = Number(getAggregateDataByOffer(offer, "Approved", additionalFilters, dataSource, finalData).replace(",", ""));
            let totalSpent = 0;
            let totalARC = Number(getAggregateDataByOffer(offer, "ARC", additionalFilters, dataSource, finalData).replace(",", ""));

            totalSpent = getAggregateDataByOffer(offer, "CostPerAnyLead", additionalFilters, dataSource, finalData);
            totalSpent = Number(totalSpent.split(" ")[0].replace(",", "")) ?? 0;

            let final = 0;
            if (totalARC === 0 || totalSpent === 0 || totalLeads === 0) {
                final = 0;
            } else {
                let tmparc = (100 / totalARC) * totalLeads;
                if (tmparc > 0) {
                    final = totalSpent / (tmparc / 100);
                };
            };
            return prepareTableData(final, "Spent");
        };

        if (["CostPerAnyLead"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalLeads = Number(getAggregateDataByOffer(offer, "Conversions", additionalFilters, dataSource, finalData).replace(",", ""));
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent"))) {
                    if (offer !== null && !offer.Sites.includes(final.Date_SiteID)) continue;
                    totalSpent += +final.Value;
                };
            };
            if (totalLeads === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData(totalSpent / totalLeads ?? 0, "Spent");
        };

        if (["CR"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (offer !== null && !offer.Sites.includes(final.Date_SiteID)) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    if (offer !== null && !offer.Sites.includes(final.Date_SiteID)) continue;
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["ADP"].includes(column)) {
            if (offer !== null && ![undefined, null].includes(whatif_edit?.[offer.ID]?.[column])) {
                return prepareTableData(whatif_edit[offer.ID][column], column);
            };

            let totalADP = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "--ADP", "ST")) {
                    if (offer !== null && !offer.Sites.includes(final.Date_SiteID)) continue;
                    if (Array.isArray(final.Value)) {
                        for (let final2 of final.Value) {
                            totalCount += 1;
                            totalADP += final2 ? +final2 : 0;
                        };
                    };
                };
            };

            let final = 0;
            if (totalCount === 0 && totalADP === 0) return "-";
            if (totalCount <= 0) totalCount = 1;
            if (!totalADP) {
                final = 0;
            } else {
                final = totalADP / totalCount;
            };
            return prepareTableData(final, "Spent");
        };
    }, [data, compareData, whatif_edit]);

    const getAggregateDataByUser = React.useCallback((userID, column, dataSource = data) => {
        let finalData = filterCampaigns({}, dataSource);
        if (!finalData) return "-";
        if (finalData.status === "error") return "-";

        if ([
            "Conversions", "Approved", "Revenue", "Spent", "Profit",
            "Visits", "CTR", "CPC", "ARC", "DRC"
        ].includes(column)) {
            let total = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, column, checkIfExternalIntegration(item, column))) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    total += final.Value ? +final.Value : 0;
                    totalCount += 1;
                };
            };
            if (["CR", "CTR", "CPC", "ROI", "ROAS"].includes(column)) total /= totalCount;
            if (isNaN(total)) total = 0;
            return prepareTableData(total, column);
        };

        if (["AR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["DR"].includes(column)) {
            let totalProfit = Number(getAggregateDataByUser(userID, "Profit", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByUser(userID, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROI");
            return prepareTableData((totalProfit / totalSpent), "ROI");
        };
        if (["ROI"].includes(column)) {
            let totalProfit = Number(getAggregateDataByUser(userID, "Profit", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByUser(userID, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROI");
            return prepareTableData((totalProfit / totalSpent) * 100, "ROI");
        };
        if (["ROAS"].includes(column)) {
            let totalProfit = Number(getAggregateDataByUser(userID, "Revenue", dataSource).split(" ")[0].replace(",", ""));
            let totalSpent = Number(getAggregateDataByUser(userID, "Spent", dataSource).split(" ")[0].replace(",", ""));

            if (totalProfit === 0 || totalSpent === 0) return prepareTableData(0, "ROAS");
            return prepareTableData((totalProfit / totalSpent), "ROAS");
        };
        if (["PaymentPerLead"].includes(column)) {
            let finalOut = 0;

            let finalSites = [];

            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    if (!finalSites.includes(final.Date_SiteID)) finalSites.push(final.Date_SiteID);
                };
            };

            for (let o of (allOffers?.status === "ok" ? allOffers.data : [])) {
                for (let site of o.Sites) {
                    if (finalSites.includes(site)) {
                        finalOut = Number(o.Price);
                        break;
                    };
                };
                if (finalOut) break;
            };

            return prepareTableData(finalOut, "Spent");
        };

        if (["CostPerLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            let totalARC = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Approved", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "ARC", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalARC += +final.Value;
                };
            };
            totalSpent = getAggregateDataByUser(userID, "CostPerAnyLead", dataSource);
            totalSpent = Number(totalSpent.split(" ")[0]) ?? 0;
            let final = 0;
            if (totalARC === 0 || totalSpent === 0 || totalLeads === 0) {
                final = 0;
            } else {
                let tmparc = (100 / totalARC) * totalLeads;
                if (tmparc > 0) {
                    final = totalSpent / (tmparc / 100);
                };
            };
            return prepareTableData(final, "Spent");
        };

        if (["CostPerAnyLead"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Spent", checkIfExternalIntegration(item, "Spent"))) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalSpent += +final.Value;
                };
            };
            if (totalLeads === 0 || totalSpent === 0) return prepareTableData(0, "Spent");
            return prepareTableData(totalSpent / totalLeads ?? 0, "Spent");
        };

        if (["CR"].includes(column)) {
            let totalLeads = 0;
            let totalSpent = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalLeads += +final.Value;
                };
                for (let final of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    totalSpent += +final.Value;
                };
            };
            let final = 0;
            if (!totalSpent || !totalLeads) {
                final = 0;
            } else {
                final = 100 / totalLeads * totalSpent;
            };
            return prepareTableData(final, "CR");
        };
        if (["ADP"].includes(column)) {
            let totalADP = 0;
            let totalCount = 0;
            for (let item of finalData.data) {
                for (let final of getTableIntegrationValues(item.TableData, "--ADP", "ST")) {
                    if (userID !== null && item.CreatedBy !== userID) continue;
                    if (Array.isArray(final.Value)) {
                        for (let final2 of final.Value) {
                            totalCount += 1;
                            totalADP += final2 ? +final2 : 0;
                        };
                    };
                };
            };

            let final = 0;
            if (totalCount <= 0) totalCount = 1;
            if (!totalADP) {
                final = 0;
            } else {
                final = totalADP / totalCount;
            };
            if (totalCount === 0) return "-";
            return prepareTableData(final, "Spent");
        };
    }, [data, compareData]);

    const getDates = (includeHours = false, usePreviousTime = false) => {
        if (!data) return [];
        if (!dateFilters) return;
        if (data.status === "error") return [];

        let tmp = [];

        let ds = moment(dateFilters.start);
        let de = moment(dateFilters.end);
        if (usePreviousTime) {
            let diff = ds.diff(de, "days") - 1;
            ds.add(diff, "days");
            de.add(diff, "days");
        };

        tmp = [];
        while (ds.isBefore(de)) {
            tmp.push(ds.format("DD-MM-YYYY"));
            ds = ds.add(1, "days");
        };

        if (tmp.length === 1 && includeHours) {
            tmp = [];

            ds = moment(dateFilters.start);
            de = moment(dateFilters.end);
            if (usePreviousTime) {
                let diff = ds.diff(de, "days") - 1;
                ds.add(diff, "days");
                de.add(diff, "days");
            };

            while (ds.isBefore(de)) {
                tmp.push(ds.format("DD-MM-YYYY HH"));
                ds = ds.add(1, "hours");
            };
        };

        return tmp;
    };

    const getChartDataDates = () => {
        if (!data) return { datasets: [], labels: [] };
        if (data.status !== "ok") return { datasets: [], labels: [] };
        let finalData = filterCampaigns();

        // totalLeads, goodLeads, profit, spent
        let labels = getDates(true);
        if (!Array.isArray(labels)) return { datasets: [], labels: [] };

        let datasets = [];

        let tcc = Array.isArray(chartColumns) ? chartColumns : [];
        let count = 0;
        let sitesExist = false;

        for (let item of tcc) {
            if (item.startsWith("Site_")) {
                sitesExist = true;
                continue;
            };
            datasets.push({
                fill: false,
                label: item,
                data: [],
                borderColor: chartColorSets[count] ?? "gray",
                backgroundColor: chartBackgroundColorSets[count] ?? "gray"
            });
            count += 1;
        };

        if (sitesExist) {
            let cmpSites = getCampaignSiteIDs();

            for (let site of cmpSites) {
                let curSites = allSites.data.find(s => s.ID === site);
                if (!curSites) continue;

                if (tcc.includes("Site_Visits")) datasets.push({
                    fill: false,
                    label: `Site_Visits ${curSites.SiteName}`,
                    _key: curSites.ID,
                    data: [],
                    borderColor: chartColorSets[count] ?? "gray",
                    backgroundColor: chartBackgroundColorSets[count] ?? "gray"
                });
                if (tcc.includes("Site_Conversions")) datasets.push({
                    fill: false,
                    label: `Site_Conversions ${curSites.SiteName}`,
                    _key: curSites.ID,
                    data: [],
                    borderColor: chartColorSets[count] ?? "gray",
                    backgroundColor: chartBackgroundColorSets[count] ?? "gray"
                });
                if (tcc.includes("Site_CR")) datasets.push({
                    fill: false,
                    label: `Site_CR ${curSites.SiteName}`,
                    _key: curSites.ID,
                    data: [],
                    borderColor: chartColorSets[count] ?? "gray",
                    backgroundColor: chartBackgroundColorSets[count] ?? "gray"
                });
                count += 1;
            };
        };

        let hourFound = false;
        for (let dates of labels) {
            let [dd, h] = dates.split(" ");
            if (h) {
                h = Number(h);
                hourFound = true;
            };
            let [d, m, y] = dd.split("-");
            [d, m, y] = [+d, +m, +y];


            let totals = {};
            let siteData = {};

            if (sitesExist) {
                let cmpSites = getCampaignSiteIDs();

                for (let site of cmpSites) {
                    let curSites = allSites.data.find(s => s.ID === site);
                    if (!curSites) continue;

                    siteData[curSites.ID] = {
                        Name: curSites.SiteName,
                        Visits: 0,
                        Conversions: 0,
                        CR: 0
                    };
                };
            };

            for (let item of datasets) totals[item.label] = 0;

            for (let item of finalData.data) {
                for (let ds of datasets) {
                    if (!totals[ds.label]) totals[ds.label] = 0;

                    let finalColumn = ds.label.replace(/ /g, "_");
                    let int = "ST";
                    if (finalColumn.endsWith("_IN")) int = "INT";
                    if (finalColumn.endsWith("_ST") || finalColumn.endsWith("_IN")) finalColumn = finalColumn.substring(0, finalColumn.length - 3);
                    for (let obj of getTableIntegrationValues(item.TableData, finalColumn, int)) {
                        if (obj.Date_year === y && obj.Date_month === m && obj.Date_day === d) {
                            if (h !== null && (obj.Date_hour !== h)) continue;
                            totals[ds.label] += +obj.Value;
                        };
                    };
                };

                if (sitesExist && item.TableData["--SiteID"]) {
                    //? process site data
                    if (tcc.includes("Site_Visits") || tcc.includes("Site_CR")) {
                        for (let obj of getTableIntegrationValues(item.TableData, "Visits", "ST")) {
                            if (obj.Date_year === y && obj.Date_month === m && obj.Date_day === d) {
                                if (h !== null && (obj.Date_hour !== h)) continue;
                                if (!obj.Date_SiteID) continue;
                                if (!siteData[obj.Date_SiteID]) continue;
                                siteData[obj.Date_SiteID].Visits += +obj.Value;
                            };
                        };
                    };
                    if (tcc.includes("Site_Conversions") || tcc.includes("Site_CR")) {
                        for (let obj of getTableIntegrationValues(item.TableData, "Conversions", "ST")) {
                            if (obj.Date_year === y && obj.Date_month === m && obj.Date_day === d) {
                                if (h !== null && (obj.Date_hour !== h)) continue;
                                if (!obj.Date_SiteID) continue;
                                if (!siteData[obj.Date_SiteID]) continue;
                                siteData[obj.Date_SiteID].Conversions += +obj.Value;
                            };
                        };
                    };
                };
            };
            for (let ds of datasets) {
                if (ds.label.startsWith("Site_")) continue;
                ds.data.push(totals[ds.label]);
            };
            if (tcc.includes("Site_CR")) {
                for (let key of Object.keys(siteData)) {
                    let cr = 100 / siteData[key].Visits * siteData[key].Conversions;
                    cr = Number(cr).toFixed(0);
                    if (isNaN(cr)) cr = 0;

                    siteData[key]["CR"] = cr;
                };
            };
            if (sitesExist) {
                for (let key of Object.keys(siteData)) {
                    for (let ds of datasets) {
                        if (ds._key !== key) continue;
                        if (ds.label.startsWith("Site_Visits")) ds.data.push(siteData[key].Visits);
                        if (ds.label.startsWith("Site_Conversions")) ds.data.push(siteData[key].Conversions);
                        if (ds.label.startsWith("Site_CR")) ds.data.push(siteData[key].CR);
                    };
                };
            };
        };


        if (!hourFound) {
            labels = labels.map(l => moment(l, "DD-MM-YYYY").toDate().toLocaleDateString());
        } else {
            labels = labels.map(l => moment(l, "DD-MM-YYYY HH").format("HH:00 A"));
        };

        return { datasets, labels };
    };

    const getChartDataOffers = () => {
        if (!allOffers) return { datasets: [], labels: [] };
        if (allOffers.status !== "ok") return { datasets: [], labels: [] };
        if (!data) return { datasets: [], labels: [] };
        if (data.status !== "ok") return { datasets: [], labels: [] };
        let finalData = filterCampaigns();

        let labels = [];
        let datasets = [];

        let tcc = Array.isArray(chartColumns) ? chartColumns : [];
        let count = 0;

        for (let item of tcc) {
            datasets.push({
                barThickness: 9,
                borderWidth: 1,
                fill: false,
                label: item,
                data: [],
                borderColor: chartColorSets[count] ?? "gray",
                backgroundColor: chartBackgroundColorSets[count] ?? "gray"
            });
            count += 1;
        };

        for (let offer of allOffers.data) {
            labels.push(offer.Name);

            for (let ds of datasets) {
                let finalColumn = ds.label.replace(/ /g, "_");
                let int = "ST";
                if (finalColumn.endsWith("_IN")) int = "INT";
                if (finalColumn.endsWith("_ST") || finalColumn.endsWith("_IN")) finalColumn = finalColumn.substring(0, finalColumn.length - 3);

                let count = 0;
                let total = 0;
                for (let cmp of finalData.data) {
                    if (!cmp.TableData[ds.label.replace(/ /g, "_")]) continue;

                    count += 1;
                    for (let item of getTableIntegrationValues(cmp.TableData, finalColumn, int)) {
                        if (!offer.Sites.includes(item.Date_SiteID)) continue;
                        if (item?.Value) total += +item.Value;
                    };
                };

                if (["CR", "CPA", "CPAO"].includes(finalColumn)) total /= count;
                ds.data.push(total);
            };
        };

        //? remove unneeded labels and datasets
        let removedLabels = [];
        labels = labels.filter((l, lID) => {
            if (datasets.reduce((acc, val) => acc + val.data[lID], 0) === 0) {
                removedLabels.push(lID);
                return false;
            };
            return true;
        });
        if (removedLabels.length !== 0) datasets = datasets.map(ds => {
            ds.data = ds.data.filter((_, dID) => !removedLabels.includes(dID));
            return ds;
        });

        while (labels.length < 20) labels.push("");
        return { datasets, labels };
    };

    const getChartDataTeam = () => {
        if (!allUsersWithMe) return { datasets: [], labels: [] };
        if (allUsersWithMe.status !== "ok") return { datasets: [], labels: [] };
        if (!data) return { datasets: [], labels: [] };
        if (data.status !== "ok") return { datasets: [], labels: [] };
        let finalData = filterCampaigns();

        let labels = [];
        let datasets = [];

        let tcc = Array.isArray(chartColumns) ? chartColumns : [];
        let count = 0;

        for (let item of tcc) {
            datasets.push({
                barThickness: 9,
                borderWidth: 1,
                fill: false,
                label: item,
                data: [],
                borderColor: chartColorSets[count] ?? "gray",
                backgroundColor: chartBackgroundColorSets[count] ?? "gray"
            });
            count += 1;
        };

        for (let usr of allUsersWithMe.data) {
            labels.push(usr.Username);

            for (let ds of datasets) {
                let finalColumn = ds.label.replace(/ /g, "_");
                let int = "ST";
                if (finalColumn.endsWith("_IN")) int = "INT";
                if (finalColumn.endsWith("_ST") || finalColumn.endsWith("_IN")) finalColumn = finalColumn.substring(0, finalColumn.length - 3);

                let count = 0;
                let total = 0;
                for (let cmp of finalData.data) {
                    if (cmp.CreatedBy !== usr.ID) continue;
                    if (!cmp.TableData[ds.label.replace(/ /g, "_")]) continue;

                    count += 1;
                    for (let item of getTableIntegrationValues(cmp.TableData, finalColumn, int)) {
                        if (item?.Value) total += +item.Value;
                    };
                };

                if (["CR", "CPA", "CPAO"].includes(finalColumn)) total /= count;
                ds.data.push(total);
            };
        };

        //? remove unneeded labels and datasets
        let removedLabels = [];
        labels = labels.filter((l, lID) => {
            if (datasets.reduce((acc, val) => acc + val.data[lID], 0) === 0) {
                removedLabels.push(lID);
                return false;
            };
            return true;
        });
        if (removedLabels.length !== 0) datasets = datasets.map(ds => {
            ds.data = ds.data.filter((_, dID) => !removedLabels.includes(dID));
            return ds;
        });

        while (labels.length < 20) labels.push("");
        return { datasets, labels };
    };

    const performSort = (data, headers) => {
        let finalOrd = localOrder;

        if (!finalOrd) finalOrd = {name: "Leads", order: "desc"};
        if (!finalOrd?.name || !finalOrd?.order) finalOrd = {name: "Leads", order: "desc"};
        if (finalOrd?.name === "Date" || finalOrd?.name === "Offer") return data;

        
        headers = headers ? headers : ["-", "Visits", "Leads", "CLR", "CLs", "CARC", "PPL", "CPL", "CPAL", "CR", "CTR", "CPC", "ROI", "ROAS", "Revenue", "Spent", "ADP", "Profit"];
        let curHeaderIndex = ["Date", "Offer", "User"].includes(finalOrd.name) ? 0 : headers.indexOf(finalOrd.name);
        if (curHeaderIndex < 0) return data;

        const checkExists = item => item !== null && item !== undefined;
        const removeExtra = item => {
            switch (headers[curHeaderIndex]) {
                case "CLR":
                case "PPL":
                case "CPAL":
                case "Revenue":
                case "Spent":
                case "Profit":
                case "CR":
                case "CTR":
                case "CPC":
                case "ROI":
                    return Number(String(item).split(" ")[0].replace(",", "")) ?? 0;
                case "ROAS":
                    return Number(String(item).replace("x", "")) ?? 0;
                default: return Number(String(item).split(" ")[0].replace(",", ""));
            };
        };
        return data.sort((a, b) => {
            if (
                (
                    !checkExists(a.columns[curHeaderIndex]?.text) ||
                    !checkExists(b.columns[curHeaderIndex]?.text)
                ) &&
                (
                    !checkExists(a.columns[curHeaderIndex]?.group?.[0]?.text) ||
                    !checkExists(b.columns[curHeaderIndex]?.group?.[0]?.text)
                )
            ) return -1;

            let a1 = null;
            let b1 = null;

            if (Array.isArray(a.columns[curHeaderIndex]?.group)) {
                a1 = removeExtra(a.columns[curHeaderIndex]?.group?.[0]?.text);
            } else {
                a1 = removeExtra(a.columns[curHeaderIndex].text);
            };
            if (Array.isArray(b.columns[curHeaderIndex]?.group)) {
                b1 = removeExtra(b.columns[curHeaderIndex]?.group?.[0]?.text);
            } else {
                b1 = removeExtra(b.columns[curHeaderIndex].text);
            };

            if (finalOrd.order === "asc") {
                if (+a1 > +b1) return 1; else return -1;
            } else {
                if (+b1 > +a1) return 1; else return -1;
            };
        });
    };

    const getAllIntegrationIDs = () => {
        if (!data) return [];
        let finalData = data;
        if (!finalData) return [];
        if (finalData.status === "error") return [];

        let finalUsers = [
            {
                ID: userInfoSelector.ID,
                Username: userInfoSelector?.Username,
                Team: userInfoSelector?.Team ?? null,
                _profiles: allTrackingProfilesSelector
            },
            ...(allUsers?.data ? allUsers.data : [])
        ];

        let allIntegrations = [];
        for (let item of finalData.data) {
            let foundProfile = false;
            for (let usr of finalUsers) {
                for (let profile of (usr._profiles ?? [])) {
                    if (profile.ID === item.IntegrationID && usr.ID === item.CreatedBy) {

                        for (let p of allIntegrations) {
                            if (p.UserID === usr.ID && p.IntegrationID === item.IntegrationID) {
                                foundProfile = true;
                                break;
                            };
                        };
                        if (!foundProfile) allIntegrations.push({
                            Username: usr.Username,
                            UserID: usr.ID,
                            IntegrationID: item.IntegrationID,
                            IntegrationName: profile.name
                        });
                        foundProfile = true;
                        break;
                    };
                };
                if (foundProfile) break;
            };
        };

        allIntegrations.sort((a, b) => {
            return a?.Username > b?.Username ? 1 : -1;
        });
        allIntegrations.push({
            Username: "System user",
            UserID: -1,
            IntegrationID: "social-spent-flow",
            IntegrationName: `Social spent flow (50 ${currencySignSelector})`
        });
        return allIntegrations;
    };

    const getCampaignData_offers = (offer) => {
        if (!offer) return null;
        if (!data) return null;

        let final = {};
        let campaignTypes = [];
        for (let item of (filterCampaigns()?.data ?? [])) {
            if (!campaignTypes.includes(item.CampaignType)) campaignTypes.push(item.CampaignType);
        };

        if (campaignTypes.length === 0) return null;
        for (let cType of campaignTypes) {
            final[cType ?? "Other"] = {
                Visits: getAggregateDataByOffer(offer, "Visits", {CampaignType: cType}),
                Conversions: getAggregateDataByOffer(offer, "Conversions", {CampaignType: cType}),
                CR: getAggregateDataByOffer(offer, "CR", {CampaignType: cType}),
                Approved: getAggregateDataByOffer(offer, "Approved", {CampaignType: cType}),
                AR: getAggregateDataByOffer(offer, "AR", {CampaignType: cType}),
                Spent: getAggregateDataByOffer(offer, "Spent", {CampaignType: cType}),
                CPA: getAggregateDataByOffer(offer, "CostPerAnyLead", {CampaignType: cType}),
                CPAO: getAggregateDataByOffer(offer, "CostPerLead", {CampaignType: cType}),
                ROI: getAggregateDataByOffer(offer, "ROI", {CampaignType: cType}),
                ROAS: getAggregateDataByOffer(offer, "ROAS", {CampaignType: cType}),
                DR: getAggregateDataByOffer(offer, "DR", {CampaignType: cType})
            };
        };

        return final;
    };

    const getCampaignData_offers_dates = offer => {
        if (!offer) return null;
        if (!data) return null;
        if (!Array.isArray(offer?.Sites)) return null;
        if (offer.Sites.length === 0) return null;

        let final = {};
        let dates = getDates(false);

        if (dates.length === 0) return null;
        for (let cType of dates) {
            final[cType ?? "Other"] = {
                Visits: getAggregateDataByDate(dates.length === 1 ? null : cType, "Visits", {LandingSiteID: offer.Sites}),
                ARC: getAggregateDataByDate(dates.length === 1 ? null : cType, "ARC", {LandingSiteID: offer.Sites}),
                Conversions: getAggregateDataByDate(dates.length === 1 ? null : cType, "Conversions", {LandingSiteID: offer.Sites}),
                CR: getAggregateDataByDate(dates.length === 1 ? null : cType, "CR", {LandingSiteID: offer.Sites}),
                Approved: getAggregateDataByDate(dates.length === 1 ? null : cType, "Approved", {LandingSiteID: offer.Sites}),
                AR: getAggregateDataByDate(dates.length === 1 ? null : cType, "AR", {LandingSiteID: offer.Sites}),
                Spent: getAggregateDataByDate(dates.length === 1 ? null : cType, "Spent", {LandingSiteID: offer.Sites}),
                CPA: getAggregateDataByDate(dates.length === 1 ? null : cType, "CostPerAnyLead", {LandingSiteID: offer.Sites}),
                CPAO: getAggregateDataByDate(dates.length === 1 ? null : cType, "CostPerLead", {LandingSiteID: offer.Sites}),
                ROI: getAggregateDataByDate(dates.length === 1 ? null : cType, "ROI", {LandingSiteID: offer.Sites}),
                ROAS: getAggregateDataByDate(dates.length === 1 ? null : cType, "ROAS", {LandingSiteID: offer.Sites}),
                DR: getAggregateDataByDate(dates.length === 1 ? null : cType, "DR", {LandingSiteID: offer.Sites})
            };
        };

        return final;
    };

    const getCampaignData_offers_campaigns = offer => {
        if (!data) return null;
        if (data.status !== "ok") return null;
        if (!offer) return null;
        if (!data) return null;

        let final = {};
        for (let item of filterCampaigns({LandingSiteID: offer.Sites}).data) {
            final[item.CampaignName] = {
                Visits: getAggregateDataByOffer(offer, "Visits", {ID: item.ID}),
                ARC: getAggregateDataByOffer(offer, "ARC", {ID: item.ID}),
                Conversions: getAggregateDataByOffer(offer, "Conversions", {ID: item.ID}),
                CR: getAggregateDataByOffer(offer, "CR", {ID: item.ID}),
                Approved: getAggregateDataByOffer(offer, "Approved", {ID: item.ID}),
                AR: getAggregateDataByOffer(offer, "AR", {ID: item.ID}),
                Spent: getAggregateDataByOffer(offer, "Spent", {ID: item.ID}),
                CPA: getAggregateDataByOffer(offer, "CostPerAnyLead", {ID: item.ID}),
                CPAO: getAggregateDataByOffer(offer, "CostPerLead", {ID: item.ID}),
                ROI: getAggregateDataByOffer(offer, "ROI", {ID: item.ID}),
                ROAS: getAggregateDataByOffer(offer, "ROAS", {ID: item.ID}),
                DR: getAggregateDataByOffer(offer, "DR", {ID: item.ID}),

                ID: item.ID,
                createdAt: item.createdAt,
                IntegrationType: item.IntegrationType,
                IntegrationID: item.IntegrationID
            };
        };

        return final;
    };

    const getCamapinData_IDs = (offer) => {
        if (!offer) return [];
        if (!data) return [];

        let out = [];
        for (let item of (filterCampaigns()?.data ?? [])) {
            if (!offer.Sites.includes(item.LandingSiteID) && !offer.Sites.includes(item.PreLandingSiteID)) continue;
            if (!out.includes(item.ID)) out.push(item.ID);
        };

        return out;
    };

    const getTeams = () => {
        if (!allUsers) return [];
        if (allUsers?.status !== "ok") return [];

        let finalUsers = [
            {
                ID: userInfoSelector.ID,
                Username: userInfoSelector?.Username,
                Team: userInfoSelector?.Team ?? null,
                _profiles: allTrackingProfilesSelector
            },
            ...(allUsers?.data ? allUsers.data : [])
        ];

        let out = [];
        for (let item of finalUsers) {
            if (!item.Team) continue;
            if (!out.includes(item.Team)) out.push(item.Team);
        };
        return out;
    };

    const getKPIDifference = (column) => {
        let oldKPIData = getKPIData(column, compareData);
        let newKPIData = getKPIData(column, data);

        if (column === "ROAS") {
            oldKPIData = String(oldKPIData);
            oldKPIData = oldKPIData.substring(0, oldKPIData.length - 1);

            newKPIData = String(newKPIData);
            newKPIData = newKPIData.substring(0, newKPIData.length - 1);
        };

        oldKPIData = Number(oldKPIData.split(" ")[0].replace(",", ""));
        newKPIData = Number(newKPIData.split(" ")[0].replace(",", ""));

        let biggerColor = themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight;
        let smallerColor = themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight;
        let biggerSign = "+";
        let smallerSign = "";

        if ([
            "CostPerAnyLead",
            "CostPerLead",
            "Spent"
        ].includes(column)) {
            [biggerColor, smallerColor] = [smallerColor, biggerColor];
        };

        if (isNaN(oldKPIData)) oldKPIData = 0;
        if (isNaN(newKPIData)) newKPIData = 0;

        if (oldKPIData === newKPIData) return <span style={{fontWeight: "normal", fontSize: "13px"}}>-</span>;
        if (oldKPIData > newKPIData) return <span style={{color: smallerColor, fontWeight: "normal", fontSize: "13px"}}>{smallerSign}{prepareTableData(newKPIData - oldKPIData, column)}</span>
        if (oldKPIData < newKPIData) return <span style={{color: biggerColor, fontWeight: "normal", fontSize: "13px"}}>{biggerSign}{prepareTableData(newKPIData - oldKPIData, column)}</span>
    };

    const setRowColor = React.useCallback((item, index) => {
        let color = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";

        let searchString = item?.columns?.[0]?.keyID;
        if (selectedRows.includes(searchString)) return color;
        return null;
    }, [selectedRows]);

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            getData(ts, true, [data, setData]);
        };

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));
        curDefer(handler, 500);

        return () => curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
    }, [selectedTrackingProfileSelector, dateFilters, selectedUser, selectedOffer, selectedCountry, chartColumns, userTrafficSources, isCompare]);

    React.useEffect(() => {
        if (selectedOffer);
        if (groupBy !== "offer") setGroupBy("offer");
        if (userTrafficSources !== "selected") setUserTrafficSources("selected");
    }, [selectedUser]);

    React.useEffect(() => {
        if (!userInfoSelector?.Flags?.isAdmin && !userInfoSelector?.Flags?.isTeamLeader && !userInfoSelector?.Flags?.isManager) return setAllUsers({ status: "ok", data: [] });
        if (allUsers) return;

        getAllUsers();
    }, [userInfoSelector]);

    React.useEffect(() => {
        setSelectedOffer([]);
    }, [selectedTrackingProfileSelector]);

    React.useEffect(() => {
        setChartColumns();
        let curSelector = selectedTrackingProfileSelector;
        if (selectedUser) {
            if (selectedUser?.IntegrationID) {
                curSelector = selectedUser.IntegrationID
            };
        };

        if (!curSelector) return setChartColumns();
        setChartColumns([
            "Conversions", "Approved", "Profit",
            (curSelector?.startsWith?.("fb-") || curSelector?.startsWith?.("mg-") || curSelector === "all-all") ? "Spent IN" : "Spent"
        ]);
        setChartColumnsTimestamp(Date.now());
    }, [selectedTrackingProfileSelector, selectedUser]);

    React.useEffect(() => {
        setLocalOrder(null);
        setSelectedRows([]);
    }, [groupBy]);

    React.useEffect(() => {
        getApprovedSpentData(timestampRef.current);
    }, [data]);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                limit: null,
                offset: 0,
                getOfferInfo: true,
                extended: false
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setAllSites(res.data);

            if (res.data.status === "ok") {
                let out = {};

                for (let item of res.data.data) {
                    if (!item?._Offer) continue;
                    if (!item._Offer?.ID) continue;

                    if (!out[item._Offer.ID]) {
                        out[item._Offer.ID] = {
                            Name: item._Offer.OfferName,
                            Price: item._Offer.OfferPrice,
                            OfferType: item._Offer.OfferType,
                            OfferNiche: item._Offer.OfferNiche,
                            Country: item._Offer.Country,
                            ResponsiblePerson: item._Offer.ResponsiblePerson,
                            Sites: [item.ID]
                        };
                    } else {
                        out[item._Offer.ID].Sites.push(item.ID);
                    };
                };

                setAllOffers({
                    status: "ok", data: Object.keys(out).map(id => {
                        return {
                            ID: id,
                            ...out[id]
                        };
                    })
                });
            } else {
                setAllOffers(backendModule.genericError);
            };
        }).catch(() => {
            setAllOffers(backendModule.genericError);
        });
    }, []);

    return <div className="route__reports__marketingOffers">
        <div className={`route__reports__marketingOffers__filters ${(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isTeamLeader || userInfoSelector?.Flags?.isManager) ? " route__reports__marketingOffers__filters--additional" : ""}`}>
            {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isTeamLeader || userInfoSelector?.Flags?.isManager) && <div className="route__reports__marketingOffers__filters__userFilter">
                <FilterByUserAccount
                    headline="Select user / account"
                    data={(() => {
                        if (!allUsers) return null;
                        if (allUsers.status === "error") return [];
                        return [
                            {
                                ID: "all-team-users",
                                Username: "All teams",
                                _profiles: [
                                    { ID: "-1", type: "scale-track", name: "Generic tracking" },
                                    { ID: "-2", type: "scale-track-sms", name: "SMS marketing" },
                                    { ID: "-3", type: "scale-track-social", name: "Social campaigns" },
                                    { ID: "fb-all", type: "facebook", name: "Facebook", generic: true },
                                    { ID: "mg-all", type: "mgid", name: "Mgid", generic: true },
                                    { ID: "md-all", type: "midas", name: "Midas Network", generic: true },
                                    { ID: "an-all", type: "adnow", name: "AdNow One", generic: true },
                                    { ID: "all-all", type: "all", name: "All traffic sources", generic: true }
                                ]
                            },
                            ...(getTeams().map(t => {
                                return {
                                    ID: `all-team--${t}`,
                                    style: {color: "orange"},
                                    Username: `[Team] ${t}`,
                                    _profiles: [
                                        { ID: "-1", type: "scale-track", name: "Generic tracking" },
                                        { ID: "-2", type: "scale-track-sms", name: "SMS marketing" },
                                        { ID: "-3", type: "scale-track-social", name: "Social campaigns" },
                                        { ID: "fb-all", type: "facebook", name: "Facebook", generic: true },
                                        { ID: "mg-all", type: "mgid", name: "Mgid", generic: true },
                                        { ID: "md-all", type: "midas", name: "Midas Network", generic: true },
                                        { ID: "an-all", type: "adnow", name: "AdNow One", generic: true },
                                        { ID: "all-all", type: "all", name: "All traffic sources", generic: true }
                                    ]
                                }
                            })),
                            ...(allUsers.data.map(ud => {
                                return {
                                    ...ud,
                                    _profiles: [
                                        ...ud._profiles,
                                        { ID: "usr-all", type: "all", name: "All traffic sources", generic: true },
                                        ...(ud._profiles.find(p => p?.ID?.startsWith?.("fb-")) ? [
                                            { ID: "fb-all", type: "facebook", name: "All accounts" }
                                        ] : [])
                                    ]
                                };
                            }))
                        ];
                    })()}
                    onChange={e => setSelectedUser(e)}
                    defaultUser={null}
                />
                {selectedUser?.IntegrationID === "all-all" && <div className="route__reports__marketingOffers__filters__userFilter__image" onClick={e => {
                    animateBox(<FilterByIntegration integrations={getAllIntegrationIDs()} excluded={excludedAccounts} onChange={e => setExcludedAccounts([...e])} />)
                }}>
                    <img src="/images/filterlight.svg" />
                </div>}
                {progress && <div className="route__reports__marketingOffers__filters__userFilter__progress">
                    <div className="route__reports__marketingOffers__filters__userFilter__progress__item" style={{
                        width: `${100 / progress.maximum * progress.current}%`,
                        maxWidth: "100%"
                    }}></div>
                </div>}
            </div>}

            <AdvancedDropdown
                headline="Country"
                showSearch={true}
                data={[{ key: "all-countries", name: "All countries", value: null }, ...countries.map(c => {
                    return { key: c.code, name: c.name, value: c.code, image: `/images/countryFlags/${c.code.toLowerCase()}.png` }
                })]}
                onChange={e => {
                    if (selectedCountry !== e?.value) setSelectedCountry(e?.value);
                }}
                selected={(() => {
                    if (!selectedCountry) return 0
                    return countries.indexOf(countries.find(c => c.code === selectedCountry)) + 1;
                })()}
            />

            <FilterByDate
                defaultValue="yesterday"
                onChange={e => setDateFilters(e)}
                disableAll={true}
                disable24h={true}
                {...(isCompare ? {
                    textToday: <><span style={{color: "rgb(122, 108, 219)"}}>Today</span> vs <span style={{color: "rgb(244, 203, 126)"}}>Yesterday</span></>,
                    textYesterday: <><span style={{color: "rgb(122, 108, 219)"}}>Yesterday</span> vs <span style={{color: "rgb(244, 203, 126)"}}>Previous day</span></>,
                    text7Days: <><span style={{color: "rgb(122, 108, 219)"}}>7 Days</span> vs <span style={{color: "rgb(244, 203, 126)"}}>Previos 7 days</span></>,
                    text30Days: <><span style={{color: "rgb(122, 108, 219)"}}>30 days</span> vs <span style={{color: "rgb(244, 203, 126)"}}>Previos 30 days</span></>,
                    textThisMonth: <><span style={{color: "rgb(122, 108, 219)"}}>This month</span> vs <span style={{color: "rgb(244, 203, 126)"}}>Last month</span></>,
                    textLastMonth: <><span style={{color: "rgb(122, 108, 219)"}}>Last month</span> vs <span style={{color: "rgb(244, 203, 126)"}}>Previous mont</span>h</>
                } : {})}
            />

            {allOffers?.status === "ok" && <div className="route__reports__marketingOffers__filters__btn" onClick={e => animateBox(e, <OfferSelectModal
                onChange={e => setSelectedOffer(e)}
                defaultValue={selectedOffer}
                data={allOffers?.data ?? []}
            />)}>Select offers ({selectedOffer?.length ?? 0} / {allOffers?.data?.length ?? 0})</div>}
        </div>

        <div className="route__reports__marketingOffers__kpi">

            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>Visits</span>
                    <span>Conversions</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("Visits", data)}</span>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("Conversions", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Visits", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Conversions", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("Visits")}
                                {getKPIDifference("Conversions")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>Processed</span>
                    <span>Approved</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {data ? <>
                        <span style={{fontSize: "16px", color: isCompare ? "rgb(122, 108, 219)" : null}}>{getKPIData("ARC", data)}</span>
                        <span style={{fontSize: "16px", color: isCompare ? "rgb(122, 108, 219)" : null}}>{getKPIData("Approved", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{fontSize: "16px", color: "#f4cb7e"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("ARC", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{fontSize: "16px", color: "#f4cb7e"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Approved", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("ARC")}
                                {getKPIDifference("Approved")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>CR %</span>
                    <span>AR %</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("CR", data)}</span>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("AR", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("CR", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("AR", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("CR")}
                                {getKPIDifference("AR")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>CTR</span>
                    <span>CPC</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("CTR", data)}</span>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("CPC", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("CTR", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("CPC", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("CTR")}
                                {getKPIDifference("CPC")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>
            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">Bounce rate</div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("BounceRate", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("BounceRate", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("BounceRate")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>

            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>CPA</span>
                    <span>CPAO</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{fontSize: "16px", color: isCompare ? "rgb(122, 108, 219)" : null}}>{getKPIData("CostPerAnyLead", data)}</span>
                        <span style={{fontSize: "16px", color: isCompare ? "rgb(122, 108, 219)" : null}}>{getKPIData("CostPerLead", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{fontSize: "16px", color: "#f4cb7e"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("CostPerAnyLead", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{fontSize: "16px", color: "#f4cb7e"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("CostPerLead", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("CostPerAnyLead")}
                                {getKPIDifference("CostPerLead")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>
            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">Revenue</div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("Revenue", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Revenue", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("Revenue")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>
            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span style={{display: "flex", alignItems: "center"}}>
                        <span>Spent</span>
                        {(approvedSpentData !== null && approvedSpentData !== undefined) && <span style={{
                            marginLeft: "5px",
                            fontSize: "10px",
                            color: approvedSpentData ? (themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight) : (themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight)
                        }}>({approvedSpentData ? "Approved" : "Not approved"})</span>}
                    </span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("Spent", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Spent", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("Spent")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>
            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">Profit</div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{color: isCompare ? "rgb(122, 108, 219)" : null, fontSize: "16px"}}>{getKPIData("Profit", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{color: "#f4cb7e", fontSize: "16px"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("Profit", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("Profit")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>
            <div className="route__reports__marketingOffers__kpi__item">
                <div className="route__reports__marketingOffers__kpi__item__top">
                    <span>ROI</span>
                    <span>ROAS</span>
                </div>
                <div className="route__reports__marketingOffers__kpi__item__bottom">
                    {(data && data?.status === "ok") ? <>
                        <span style={{fontSize: "16px", color: isCompare ? "rgb(122, 108, 219)" : null}}>{getKPIData("ROI", data)}</span>
                        <span style={{fontSize: "16px", color: isCompare ? "rgb(122, 108, 219)" : null}}>{getKPIData("ROAS", data)}</span>

                        {isCompare && <>
                            <div style={{flexBasis: "100%"}}></div>
                            <span style={{fontSize: "16px", color: "#f4cb7e"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("ROI", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>
                            <span style={{fontSize: "16px", color: "#f4cb7e"}}>{compareData ? (compareData?.status === "ok" ? getKPIData("ROAS", compareData) : "-") : <Spinner style={{width: "17px", height: "17px"}} color="#f4cb7e" />}</span>

                            {compareData?.status === "ok" && <>
                                <div style={{flexBasis: "100%"}}></div>
                                {getKPIDifference("ROI")}
                                {getKPIDifference("ROAS")}
                            </>}
                        </>}
                    </> : <Spinner style={{ width: "32px", height: "32px" }} color="white" />}
                </div>
            </div>

        </div>

        {chartColumns && <div className="route__reports__marketingOffers__columns">
            {(allUsers && data?.status === "ok") && <div className="route__reports__marketingOffers__columns__export" style={{ backgroundImage: `url("/images/icon_export.svg")` }} title="Export data" onClick={e => animateBox(e, <ExportSessions allUsers={allUsers?.status === "ok" ? allUsers.data : []} selectedUser={selectedUser} data={data?.data} dateFilters={dateFilters} />)}></div>}

            <div className="route__reports__marketingOffers__columns__groupBy">
                <p>Compare:</p>

                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${isCompare ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => {
                    if (!isCompare) setIsCompare(true);
                    setTimeout(() => document.querySelector(".route__reports__marketingOffers__filters .component__filters__filterByDate").click(), 100);
                }}>Yes</div>
                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${!isCompare ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => isCompare && setIsCompare(false)}>No</div>
            </div>

            {(!selectedUser || selectedUser?.UserID === null) && <div className="route__reports__marketingOffers__columns__groupBy">
                <p>Traffic source:</p>

                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${userTrafficSources === "selected" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => (userTrafficSources !== "selected") && setUserTrafficSources("selected")}>Selected</div>
                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${userTrafficSources === "all" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => {
                    if (userTrafficSources !== "all") setUserTrafficSources("all");
                }}>All</div>
            </div>}

            <div className="route__reports__marketingOffers__columns__groupBy">
                <p>Group by:</p>

                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${groupBy === "date" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => (groupBy !== "date") && setGroupBy("date")}>Date</div>
                <div className={`route__reports__marketingOffers__columns__groupBy__btn ${groupBy === "offer" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => {
                    if (groupBy !== "offer") setGroupBy("offer");
                    if (!fetchedSites) {
                        getData(timestampRef.current, true, [data, setData]);
                    };
                }}>Offers</div>
                {(((userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isTeamLeader || userInfoSelector?.Flags?.isManager)) && selectedUser?.UserID?.startsWith("all-team-")) && <div className={`route__reports__marketingOffers__columns__groupBy__btn ${groupBy === "team" ? "route__reports__marketingOffers__columns__groupBy__btn--active" : ""}`} onClick={() => (groupBy !== "team") && setGroupBy("team")}>Team</div>}
            </div>

            <FilterColumns
                key={`filter-columns-${selectedTrackingProfileSelector}-${chartColumnsTimestamp}`}
                defaultValue={chartColumns}
                columns={returnPossibleChartColumns()}
                onChange={e => setChartColumns(e)}
                integrationID={`user-marketing-report-offers`}
                style={{
                    width: "200px",
                }}
            />
        </div>}

        <div className="route__dashboard__chart">
            <div className={`route__reports__marketingOffers__chart__spinner ${!data ? "route__reports__marketingOffers__chart__spinner--active" : ""}`}>
                <Spinner color={themeSelector === "dark" ? "white" : "black"} />
            </div>

            {(chartColumns && groupBy === "date") && <Line
                style={{ width: "100%", height: "350px" }}
                options={options}
                data={getChartDataDates()}
                key="report-chart-perDate"
            />}
            {(chartColumns && groupBy === "offer") && <Bar
                style={{ width: "100%", height: "350px" }}
                options={options}
                data={getChartDataOffers()}
                key="report-chart-perOffer"
            />}
            {(chartColumns && groupBy === "team") && <Bar
                style={{ width: "100%", height: "350px" }}
                options={options}
                data={getChartDataTeam()}
                key="report-chart-perTeam"
            />}
        </div>

        <div className="route__reports__marketingOffers__table genericTableWrap genericTableWrap--sticky">
            {groupBy === "date" && <MarketingOffersReport_tableWrap_date
                data={data}
                compareData={compareData}
                getAggregateDataByDate={getAggregateDataByDate}
                isCompare={isCompare}
                performSort={performSort}
                themeSelector={themeSelector}
                getDates={getDates}
                handleOrderCallback={handleOrderCallback}
                localOrder={localOrder}
            />}

            {groupBy === "offer" && <MarketingOffersReport_tableWrap_offer
                data={data}
                compareData={compareData}
                getAggregateDataByOffer={getAggregateDataByOffer}
                isCompare={isCompare}
                performSort={performSort}
                themeSelector={themeSelector}
                getDates={getDates}
                handleOrderCallback={handleOrderCallback}
                localOrder={localOrder}

                whatif_edit={whatif_edit}
                setWhatif_edit={setWhatif_edit}
                allOffers={allOffers}
                selectedUser={selectedUser}
                userInfoSelector={userInfoSelector}
                dateFilters={dateFilters}
                allUsers={allUsers}
                allTrackingProfilesSelector={allTrackingProfilesSelector}
                prepareTableData={prepareTableData}

                getCampaignData_offers={getCampaignData_offers}
                getCampaignData_offers_dates={getCampaignData_offers_dates}
                getCampaignData_offers_campaigns={getCampaignData_offers_campaigns}
                getCamapinData_IDs={getCamapinData_IDs}
            />}

            {groupBy === "team" && <MarketingOffersReport_tableWrap_team
                data={data}
                compareData={compareData}
                themeSelector={themeSelector}
                allUsersWithMe={allUsersWithMe}
                isCompare={isCompare}
                getAggregateDataByUser={getAggregateDataByUser}
                allUsers={allUsers}
                performSort={performSort}
                handleOrderCallback={handleOrderCallback}
                dateFilters={dateFilters}
            />}
        </div>
    </div>
};

const FilterByIntegration = props => {
    const [excluded, setExcluded] = React.useState(props.excluded ?? []);
    const [search, setSearch] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const wrapRef = React.useRef();

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const checkIsExcluded = (UserID, IntegrationID) => {
        for (let item of excluded) {
            if (item?.UserID === UserID && item?.IntegrationID === IntegrationID) return true;
        };
        return false;
    };

    const performSearch = item => {
        if (!search) return true;

        let searchStrings = search.trim().split(" ").map(s => s.trim().toLowerCase());
        if (searchStrings.length === 0) return true;

        for (let s of searchStrings) {
            if (
                !String(item.IntegrationID).toLowerCase().includes(s) &&
                !String(item.IntegrationName).toLowerCase().includes(s) &&
                !String(item.UserID).toLowerCase().includes(s) &&
                !String(item.Username).toLowerCase().includes(s)
            ) return false;
        };

        return true;
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    return <div className="route__reports__marketingOffers__filterByIntegration" onClick={() => onClose()}>
        <div className="route__reports__marketingOffers__filterByIntegration__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>

            <div className="route__user__creatives__as__viewHistory__wrap__top">
                <div className="route__user__creatives__as__viewHistory__wrap__top__left">Filter integrations</div>

                <StyledButton style={{ marginLeft: "auto", marginRight: "20px", height: "30px" }} onClick={() => {
                    if (typeof (props.onChange) === "function") props.onChange(excluded);
                    props.onClose();
                }}>Save</StyledButton>
                <div className="route__user__creatives__as__viewHistory__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__reports__marketingOffers__filterByIntegration__wrap__content">
                <FilterBySearch onChange={e => search !== e && setSearch(e)} />

                <div className="route__reports__marketingOffers__filterByIntegration__wrap__content__buttons">
                    <StyledButton onClick={() => setExcluded([])}>Select all</StyledButton>
                    <StyledButton onClick={() => {
                        setExcluded(props.integrations.filter(performSearch).map(i => {
                            return { UserID: i.UserID, IntegrationID: i.IntegrationID };
                        }));
                    }}>Deselect all{search && ` filtered`}</StyledButton>
                </div>

                <div className="route__reports__marketingOffers__filterByIntegration__wrap__content__integrations">
                    {props.integrations.filter(performSearch).map(i => {
                        return <div className="route__reports__marketingOffers__filterByIntegration__wrap__content__integrations__integration">
                            <div className="route__reports__marketingOffers__filterByIntegration__wrap__content__integrations__integration__left">
                                <RadioButton checked={!checkIsExcluded(i.UserID, i.IntegrationID)} onChange={e => {
                                    if (e && checkIsExcluded(i.UserID, i.IntegrationID)) {
                                        setExcluded(e => e.filter(ef => ef.UserID !== i.UserID && ef.IntegrationID !== i.IntegrationID));
                                    } else if (!e && !checkIsExcluded(i.UserID, i.IntegrationID)) {
                                        setExcluded(e => {
                                            return [
                                                ...e,
                                                { UserID: i.UserID, IntegrationID: i.IntegrationID }
                                            ];
                                        });
                                    };
                                }} />
                            </div>
                            <div className="route__reports__marketingOffers__filterByIntegration__wrap__content__integrations__integration__right">
                                <p style={{ color: i.UserID === -1 ? (themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight) : null }}>{i.Username}</p>
                                <p>{i.IntegrationName}</p>
                            </div>
                        </div>
                    })}
                </div>
            </div>

        </div>
    </div>
};

const ExportSessions = props => {
    const [progress, setProgress] = React.useState();
    const [infoP, setInfoP] = React.useState("");
    const [name, setName] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const selectedTrackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? "-1");
    const userInfoSelector = useSelector(state => state?.usetData?.userData?.UserInfo ?? {});

    const exportNameRef = React.useRef();

    const getData = async () => {
        setInfoP("");

        let filters = [];
        if (props?.dateFilters) {
            if (props?.dateFilters?.start && props?.dateFilters?.end) {
                filters.push({ name: "createdAt", op: "pdgeq", value: props.dateFilters.start.toDate().getTime() });
                filters.push({ name: "createdAt", op: "pdleq", value: props.dateFilters.end.toDate().getTime() });
            };
        };

        let fetched = [];
        let success = 0;
        let failed = 0;

        if (props.selectedUser) {
            // handle selection (probably team or specific team user)
            let allCampaigns = {};
            for (let c of props.data) {
                if (!allCampaigns[c.CreatedBy]) allCampaigns[c.CreatedBy] = {};
                if (!allCampaigns[c.CreatedBy][c.IntegrationType]) allCampaigns[c.CreatedBy][c.IntegrationType] = [];

                allCampaigns[c.CreatedBy][c.IntegrationType].push(c.ID);
            };
            let totalCampaigns = 0;
            for (let key of Object.keys(allCampaigns)) {
                totalCampaigns += Object.keys(allCampaigns[key]).length;
            };
            setProgress({ current: 0, maximum: totalCampaigns ?? 0 });

            for (let usrKey of Object.keys(allCampaigns)) {
                for (let typeKey of Object.keys(allCampaigns[usrKey])) {
                    let final = await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/campaigns/getAllOrders`,
                        data: {
                            CampaignIDs: allCampaigns[usrKey][typeKey],
                            IntegrationType: typeKey === "null" ? null : typeKey,
                            UserID: usrKey,
                            limit: null,
                            offset: 0,
                            filters
                        },
                        ...backendModule.axiosConfig
                    }).then(res => res.data).catch(() => backendModule.genericError);

                    if (final.status === "ok") {
                        success += 1;
                        fetched.push(...final.data);
                    } else {
                        failed += 1;
                    };
                    setProgress(p => { return { ...p, current: p.current + 1 } });
                };
            };
        } else {
            // handle this user and its integration
            let allCampaigns = {};
            for (let c of props.data) {
                if (!allCampaigns[c.IntegrationType]) allCampaigns[c.IntegrationType] = [];
                allCampaigns[c.IntegrationType].push(c.ID);
            };
            setProgress({ current: 0, maximum: Object.keys(allCampaigns).length });

            for (let key of Object.keys(allCampaigns)) {
                let final = await axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/campaigns/getAllOrders`,
                    data: {
                        CampaignIDs: allCampaigns[key],
                        IntegrationType: key,
                        UserID: userInfoSelector?.ID,
                        limit: null,
                        offset: 0,
                        filters
                    },
                    ...backendModule.axiosConfig
                }).then(res => res.data).catch(() => backendModule.genericError);

                if (final.status === "ok") {
                    success += 1;
                    fetched.push(...final.data);
                } else {
                    failed += 1;
                };
                setProgress(p => { return { ...p, current: p.current + 1 } });
            };
        };

        fetched = fetched.sort((a, b) => {
            return moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1;
        });

        if (failed > 0) {
            let resp = await new Promise(r => {
                animateBox(<YesNoModal
                    heading="Fetch errors"
                    text={`There were ${failed} errors while fetching the session/conversion data. Do You want to continue with the export?`}
                    isRightButtonNormal={true}
                    buttonLeftCallback={args => {
                        args.close();
                        r(false)
                    }}
                    buttonRightCallback={args => {
                        args.close();
                        r(true);
                    }}
                />);
            });
            if (!resp) return props.onClose();
        };

        const ip_local_replace = ip => {
            if (!ip) return ip;
            ip = String(ip);
            if (ip.startsWith("::ffff:")) {
                ip = ip.substring(7, ip.length);
            };
            return ip;
        };

        let csvOut = [];
        // Click ID, Date, Ip Address, Scale CRM ID
        for (let item of fetched) {
            let curOrderID = undefined;
            if (item?.ClientInfo?.ID) {
                curOrderID = item.ClientInfo.ID;
            } else if (item?.ClientInfo?.["integration_scalecrm"]) {
                curOrderID = item.ClientInfo["integration_scalecrm"];
            };

            csvOut.push({
                "Click ID": item.ID,
                Date: moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss"),
                "IP Address": ip_local_replace(item?.DeviceInfo?.IP),
                "Scale-CRM ID": curOrderID ?? ""
            });
        };

        if (csvOut.length === 0) return setInfoP("Nothing to export");

        let keysSplit = [...Object.keys(csvOut[0])];

        const workbook = new exceljs.Workbook();
        const sheet = workbook.addWorksheet("Offer report");
        let headersInserted = [];
        for (let elem of csvOut) {
            if (headersInserted.length === 0) {
                headersInserted = Object.keys(elem).map(key => {
                    return { header: key, key: key };
                });
                break;
            };
        };
        sheet.addTable({
            name: "Conversions",
            "ref": "A1",
            headerRow: true,
            style: {
                theme: 'TableStyleLight1',
                showRowStripes: true,
            },
            columns: headersInserted.map(hi => { return { name: hi.header } }),
            rows: csvOut.map(c => {
                let out = [];
                for (let item of keysSplit) {
                    out.push(c[item]);
                };
                return out;
            })
        });
        sheet.views = [
            { state: "frozen", ySplit: 1, xSplit: 1 }
        ];
        sheet.columns.forEach(function (column, i) {
            let maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
                var columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        workbook.xlsx.writeBuffer().then(data => {
            let file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            var a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = `${name}.xlsx`;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                props.onClose();
            }, 0);
            animateBox(<YesNoModal
                heading="Export successful"
                text={`${success} entries parsed successfully, and encountered ${failed} errors!`}
                buttonLeftHidden={true}
                buttonRightText="Ok"
                isRightButtonNormal={true}
            />);
        }).catch(() => {
            setInfoP("A generic error occured while saving the file, please try again later...");
        });
    };

    React.useEffect(() => {
        if (!name) return;
        getData();
    }, [name]);

    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Export</div>
                <div className="genericModal__wrap__head__right" style={{ backgroundImage: `url("/images/icon_close.svg")` }} onClick={props.onClose}></div>
            </div>

            {!name ? <>
                <div className="genericModal__wrap__input">
                    <p>Export name</p>
                    <input ref={exportNameRef} type="text" defaultValue="ScaleTrack-Offer-Export" />
                </div>
                <div className="genericModal__wrap__buttons">
                    <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                    <div className="genericModal__wrap__buttons__btn" onClick={e => {
                        if (exportNameRef.current.value) setName(exportNameRef.current.value);
                    }}>Export</div>
                </div>
            </> : <>
                {infoP ? <>
                    <p className="genericModal__wrap__infoP" style={{ opacity: 1 }}>{infoP}</p>
                </> : <>
                    <p>Preparing export...{progress ? ` (${progress.current} / ${progress.maximum})` : ""}</p>
                    {progress ? <>
                        <div className="genericModal__wrap__progress">
                            <div className="genericModal__wrap__progress__item" style={{
                                width: `${100 / progress.maximum * progress.current}%`
                            }}></div>
                        </div>
                    </> : <Spinner color={themeSelector === "dark" ? "white" : "black"} />}
                </>}
            </>}
        </div>
    </div>
};

const MarketingOffersReport_showCampaignAds = props => {
    const wrapRef = React.useRef();

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };
    
        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;
    
        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);


    return <div className="route__reports__marketingOffers__filterByIntegration route__reports__marketingOffers__filterByIntegration__perCampaign" onClick={() => onClose()}>
        <div className="route__reports__marketingOffers__filterByIntegration__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{width: "calc(100% - 84px)"}}>
            <div className="route__user__creatives__as__viewHistory__wrap__top">
                <div className="route__user__creatives__as__viewHistory__wrap__top__left">Campaign Ads for {props.headline}</div>

                <div className="route__user__creatives__as__viewHistory__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <CampaignAdsModal
                ID={props.ID}
                filters={props.filters}
                allUsers={true}
            />
        </div>
    </div>
};

const MarketingOffersReport_perCampaignType = (props) => {
    const [data, setData] = React.useState();
    const [orders, setOrders] = React.useState();
    const [reportType, setReportType] = React.useState("campaignType");

    const wrapRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteType?.theme ?? "dark");

    const onClose = () => {
        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };
    
        props.onClose();
    };

    const getData = () => {
        if (!Array.isArray(props.campaignIDs)) return setData(backendModule.genericError);
        if (props.campaignIDs.length === 0) return setData(backendModule.genericError);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getTrackingTrafficSources`,
            data: {
                filters: [
                    {name: "CampaignID", op: "in", value: props.campaignIDs},
                    ...props.filters   
                ],
                orders: orders ? [orders] : []
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    };

    const orderData = (d, headers) => {
        if (!orders) return d;

        let headerIndex = headers.indexOf(orders.name);

        if (headerIndex === -1) return d;

        let asc = orders.order === "asc" ? 1 : -1;
        let desc = orders.order === "asc" ? -1 : 1;

        return [...d].sort((a, b) => {
            let a1 = a[headerIndex];
            let b1 = b[headerIndex];

            if (!a1 || !b1) return 0;

            a1 = a1?.text ?? "";
            b1 = b1?.text ?? "";

            if (headerIndex > 0 && !["Created At"].includes(headers[headerIndex])) {
                a1 = String(a1).split(" ")[0].replace(",", "");
                b1 = String(b1).split(" ")[0].replace(",", "");
            };

            if (Number(a1) && Number(b1)) {
                return Number(a1) > Number(b1) ? asc : desc;
            } else if (headers[headerIndex] === "Created at") {
                return moment(a1).isAfter(moment(b1)) ? asc : desc;
            } else {
                return a1 > b1 ? asc : desc;
            };
        });
    };

    const checkHasAds = integrationType => {
        switch (String(integrationType)) {
            case "0":
            case "1":
                return true;
            default: return false;
        };
    };

    const getIntegrationInfo = IntegrationID => {
        if (!props.allUsers) return null;

        let iID = String(IntegrationID);
        for (let usr of props.allUsers) {
            for (let profile of (Array.isArray(usr?._profiles) ? usr._profiles : [])) {
                if (profile?.ID === iID) {
                    return <p className="route__reports__marketingOffers__filterByIntegration__wrap__content__tableProfileItem">
                        <img src={miscModule.getTrackingProfileImage(profile?.type)} />
                        <p>{profile?.name ?? "-"}</p>
                        <p>{iID}</p>
                    </p>;
                };
            };
        };

        return null;
    };
    
    React.useEffect(() => {
        if (!wrapRef.current) return;
    
        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        getData();
    }, [orders]);

    React.useEffect(() => {
        setOrders();
    }, [reportType]);

    return <div className="route__reports__marketingOffers__filterByIntegration route__reports__marketingOffers__filterByIntegration__perCampaign" onClick={() => onClose()}>
        <div className="route__reports__marketingOffers__filterByIntegration__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()} style={{width: "calc(100% - 84px)"}}>
            <div className="route__user__creatives__as__viewHistory__wrap__top">
                <div className="route__user__creatives__as__viewHistory__wrap__top__left">Report for {props.headline ?? "?"}</div>

                <div className="route__user__creatives__as__viewHistory__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__reports__marketingOffers__filterByIntegration__wrap__content">
                <div className="genericTabs" style={{position: "sticky", left: 0}}>
                    <div className={`genericTabs__tab ${reportType === "campaignType" ? "genericTabs__tab--active" : ""}`} onClick={() => reportType !== "campaignType" && setReportType("campaignType")}>Report by campaign type</div>
                    <div className={`genericTabs__tab ${reportType === "trafficSource" ? "genericTabs__tab--active" : ""}`} onClick={() => reportType !== "trafficSource" && setReportType("trafficSource")}>Report by traffic source</div>
                    {props.dateData && <div className={`genericTabs__tab ${reportType === "date" ? "genericTabs__tab--active" : ""}`} onClick={() => reportType !== "date" && setReportType("date")}>Report by date</div>}
                    {props.campaignData && <div className={`genericTabs__tab ${reportType === "campaign" ? "genericTabs__tab--active" : ""}`} onClick={() => reportType !== "campaign" && setReportType("campaign")}>Report by campaign</div>}
                </div>

                {reportType === "campaignType" && <FilteredCustomTable
                    orderCB={e => setOrders(e)}
                    accent="#6C5DD3"
                    theme={themeSelector}
                    style={{columnGap: "40px"}}
                    customColumns={["max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content"]}
                    headers={["Campaign type", "Visits", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO", "ROI", "ROAS", "DR"]}

                    data={(()=>{
                        let out = [];

                        for (let key of Object.keys(props.data)) {
                            out.push([
                                {keyID: key, type: "text", text: key},
                                {keyID: key, type: "text", text: props.data[key]["Visits"]},
                                {keyID: key, type: "text", text: props.data[key]["Conversions"]},
                                {keyID: key, type: "text", text: props.data[key]["CR"]},
                                {keyID: key, type: "text", text: props.data[key]["Approved"]},
                                {keyID: key, type: "text", text: props.data[key]["AR"]},
                                {keyID: key, type: "text", text: props.data[key]["Spent"]},
                                {keyID: key, type: "text", text: props.data[key]["CPA"]},
                                {keyID: key, type: "text", text: props.data[key]["CPAO"]},
                                {keyID: key, type: "text", text: props.data[key]["ROI"]},
                                {keyID: key, type: "text", text: props.data[key]["ROAS"]},
                                {keyID: key, type: "text", text: props.data[key]["DR"]}
                            ]);
                        };

                        if (orders) out = orderData(out, ["Campaign type", "Visits", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO", "ROI", "ROAS", "DR"]);
                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                        return out;
                    })()}
                />}

                {reportType === "date" && <FilteredCustomTable
                    orderCB={e => setOrders(e)}
                    accent="#6C5DD3"
                    theme={themeSelector}
                    style={{columnGap: "40px"}}
                    customColumns={["max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content"]}
                    headers={["Date", "Visits", "Processed", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO", "ROI", "ROAS", "DR"]}

                    data={(()=>{
                        let out = [];

                        for (let key of Object.keys(props.dateData)) {
                            if (props.dateData[key]?.["Visits"] === "0") continue;
                            out.push([
                                {keyID: key, type: "text", text: key},
                                {keyID: key, type: "text", text: props.dateData[key]["Visits"]},
                                {keyID: key, type: "text", text: props.dateData[key]["ARC"]},
                                {keyID: key, type: "text", text: props.dateData[key]["Conversions"]},
                                {keyID: key, type: "text", text: props.dateData[key]["CR"]},
                                {keyID: key, type: "text", text: props.dateData[key]["Approved"]},
                                {keyID: key, type: "text", text: props.dateData[key]["AR"]},
                                {keyID: key, type: "text", text: props.dateData[key]["Spent"]},
                                {keyID: key, type: "text", text: props.dateData[key]["CPA"]},
                                {keyID: key, type: "text", text: props.dateData[key]["CPAO"]},
                                {keyID: key, type: "text", text: props.dateData[key]["ROI"]},
                                {keyID: key, type: "text", text: props.dateData[key]["ROAS"]},
                                {keyID: key, type: "text", text: props.dateData[key]["DR"]}
                            ]);
                        };

                        if (orders) out = orderData(out, ["Date", "Visits", "Processed", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO", "ROI", "ROAS", "DR"]);
                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                        return out;
                    })()}
                />}

                {reportType === "campaign" && <FilteredCustomTable
                    orderCB={e => setOrders(e)}
                    accent="#6C5DD3"
                    theme={themeSelector}
                    style={{columnGap: "40px"}}
                    customColumns={["max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content"]}
                    headers={["Campaign", "Visits", "Processed", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO", "ROI", "ROAS", "DR", "Created at", "Campaign ID", "Account Name"]}

                    data={(()=>{
                        let out = [];

                        for (let key of Object.keys(props.campaignData)) {
                            if (props.campaignData[key]?.["Visits"] === "0") continue;
                            out.push([
                                {keyID: key, type: "text", text: !checkHasAds(props.campaignData[key]["IntegrationType"]) ? key : <span className="route__reports__marketingOffers__filterByIntegration__wrap__content__clickable" onClick={() => {
                                    animateBox(<MarketingOffersReport_showCampaignAds
                                        ID={props.campaignData[key]["ID"]}
                                        filters={props.filters}
                                        headline={key}
                                    />);
                                }}><span style={{color: "#9390FF"}}>[Has Ads]</span> {key}</span>},
                                {keyID: key, type: "text", text: props.campaignData[key]["Visits"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["ARC"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["Conversions"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["CR"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["Approved"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["AR"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["Spent"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["CPA"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["CPAO"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["ROI"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["ROAS"]},
                                {keyID: key, type: "text", text: props.campaignData[key]["DR"]},
                                {keyID: key, type: "text", text: moment(props.campaignData[key]["createdAt"]).toDate().toLocaleString()},
                                {keyID: key, type: "text", text: props.campaignData[key]["ID"]},
                                {keyID: key, type: "text", text: getIntegrationInfo(props.campaignData[key]["IntegrationID"])},
                            ]);
                        };

                        if (orders) out = orderData(out, ["Campaign", "Visits", "Processed", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO", "ROI", "ROAS", "DR", "Created at", "Campaign ID", "Account Name"]);
                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                        return out;
                    })()}
                />}

                {reportType === "trafficSource" && <FilteredCustomTable
                    orderCB={o => setOrders(o)}
                    accent="#6C5DD3"
                    theme={themeSelector}
                    style={{columnGap: "40px"}}
                    customColumns={["max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content","max-content"]}
                    headers={["Campaign type", "Visits", "Conversions", "CR", "Approved", "AR", "Spent", "CPA", "CPAO"]}

                    data={(()=>{
                        if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                        if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];
                        let out = [];

                        for (let item of data.data) {
                            out.push([
                                {keyID: item.SourceName, type: "text", text: item.SourceName},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.Visits, "Visits")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.Conversions, "Conversions")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.CR, "CR")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.Approved, "Approved")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.AR, "AR")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.Spent, "Spent")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.CPA, "Spent")},
                                {keyID: item.SourceName, type: "text", text: props.prepareTableData(item.CPAO, "Spent")}
                            ]);
                        };

                        if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                        return out;
                    })()}
                />}
            </div>
        </div>
    </div>
};

const MarketingOffersReport_tableWrap_date = React.memo(function MarketingOffersReport_tableWrap_date({
    data,
    compareData,
    getAggregateDataByDate,
    isCompare,
    performSort,
    themeSelector,
    getDates,
    handleOrderCallback,
    localOrder
}) {
    const [selected, setSelected] = React.useState([]);

    const setRowColor = (item) => {
        let color = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
        if (selected.includes(item?.columns[0]?.keyID)) return color;
        return null;
    };

    let finalData = React.useMemo(() => {
        setSelected([]);
        if (isCompare) {
            if (!data || !compareData) return [];
            if (data.status === "error" || compareData.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        } else {
            if (!data) return [];
            if (data.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        };

        let cDates = getDates(true);
        let cmpCDates = isCompare ? getDates(true, true) : [];
        if (!cDates) return [];

        let out = [];
        for (let date of cDates) {
            if (isCompare) {
                out.push({
                    columns: [
                        {
                            keyID: date, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                                let t = e.target;
                                setSelected(s => {
                                    if (s.includes(date)) {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                        return s.filter(sf => sf !== date);
                                    } else {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                        return [...s, date];
                                    };
                                });
                            }}>
                                <div className="genericTableWrap__campaignWithImg__padded"></div>
                                <p>
                                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{date.includes(" ") ? moment(date, "DD-MM-YYYY HH").format("HH:00 A") : moment(date, "DD-MM-YYYY").toDate().toLocaleDateString()}</span>
                                    <span></span>
                                </p>
                            </p>, style: { width: null }
                        },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Visits", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Visits", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Conversions", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Conversions", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "CR", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "CR", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "ARC", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "ARC", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Approved", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Approved", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "AR", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "AR", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "PaymentPerLead", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "PaymentPerLead", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "CostPerAnyLead", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "CostPerAnyLead", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "CostPerLead", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "CostPerLead", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "ADP", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "ADP", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "ROI", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "ROI", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "ROAS", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "ROAS", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "DR", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "DR", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Revenue", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Revenue", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Spent", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Spent", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "Profit", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "Profit", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "CTR", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "CTR", {}, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: date, type: "group", group: [
                            { keyID: date, type: "text", text: getAggregateDataByDate(date, "CPC", {}, data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: date, type: "text", text: getAggregateDataByDate(cmpCDates[cDates.indexOf(date)], "CPC", {}, compareData), style: {color: "#f4cb7e"} }
                        ] }
                    ]
                });
                continue;
            };
            out.push({
                columns: [
                    {
                        keyID: date, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                            let t = e.target;
                            setSelected(s => {
                                if (s.includes(date)) {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                    return s.filter(sf => sf !== date);
                                } else {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                    return [...s, date];
                                };
                            });
                        }}>
                            <div className="genericTableWrap__campaignWithImg__padded"></div>
                            <p>
                                <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{date.includes(" ") ? moment(date, "DD-MM-YYYY HH").format("HH:00 A") : moment(date, "DD-MM-YYYY").toDate().toLocaleDateString()}</span>
                                <span></span>
                            </p>
                        </p>, style: { width: null }
                    },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Visits") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Conversions") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "CR") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "ARC") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Approved") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "AR") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "PaymentPerLead") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "CostPerAnyLead") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "CostPerLead") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "ADP") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "ROI") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "ROAS") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "DR") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Revenue") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Spent") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "Profit") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "CTR") },
                    { keyID: date, type: "text", text: getAggregateDataByDate(date, "CPC") },
                ]
            });
        };

        if (out.length > 0) {
            out = performSort(out, ["Date", "Visits", "Leads", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ROI", "ROAS", "DR", "Revenue", "Spent", "ADP", "Profit", "CTR", "CPC"]);
            if (isCompare) {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Visits", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Visits", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Conversions", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Conversions", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CR", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CR", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ARC", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ARC", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Approved", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Approved", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "AR", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "AR", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "PaymentPerLead", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "PaymentPerLead", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerAnyLead", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerAnyLead", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerLead", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerLead", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ADP", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ADP", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROI", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROI", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROAS", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROAS", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "DR", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "DR", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Revenue", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Revenue", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Spent", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Spent", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Profit", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Profit", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CTR", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CTR", {}, compareData), style: {color: "#f4cb7e"} }
                        ]},
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CPC", {}, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CPC", {}, compareData), style: {color: "#f4cb7e"} }
                        ]}
                    ]
                });
            } else {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Visits") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Conversions") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ARC") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Approved") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "AR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "PaymentPerLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerAnyLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CostPerLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ADP") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROI") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "ROAS") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "DR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Revenue") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Spent") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "Profit") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CTR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByDate(null, "CPC") }
                    ]
                });
            };
        } else {
            out.push({ columns: [{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }] });
        };

        return out;
    }, [data, compareData, isCompare, localOrder]);

    return <FilteredCustomTable
        canAnimate={false}
        colors={setRowColor}
        orderCB={handleOrderCallback}
        columnBorders={data?.status === "ok"}
        headers={["Date", "Visits", "Leads", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ADP", "ROI", "ROAS", "DR", "Revenue", "Spent", "Profit", "CTR", "CPC"]}
        customHeaders={{
            "Date": <div>
                <p className="genericTableWrap__campaignWithImg__headingPadded"></p>
                Date
            </div>,
            "Leads": "Conversions",
            "CLR": <span title="Conversion rate">CR %</span>,
            "CLs": <span title="Approved leads">Approved</span>,
            "CARC": <span title="Approved leads rate">AR %</span>,
            "PPL": <span title="Payment per lead">PPL</span>,
            "CPL": <span title="Cost per lead">CPA</span>,
            "CPAL": <span title="Cost per approved order">CPAO</span>,
            "CTR": <span title="Click-through rate">CTR</span>,
            "CPC": <span title="Cost per click">CPC</span>,
            "ROI": <span title="Return on investment">ROI</span>,
            "ROAS": <span title="Return on ad spend">ROAS</span>
        }}
        key="user-report-marketing-table"
        accent="#6C5DD3"
        theme={themeSelector}
        style={{
            columnGap: "35px"
        }}
        stickyHeader={894}
        stickyHeaderClass="component__contentWrapper"
        stickyFooter={-1}
        stickyFooterClass="component__contentWrapper"
        spinnerColor={themeSelector === "dark" ? "white" : "black"}
        showSpinner={!data}
        customColumns={[
            "200px",
            ...(new Array(18).fill("auto"))
        ]}
        data={finalData}
    />
});

const MarketingOffersReport_tableWrap_offer = React.memo(function MarketingOffersReport_tableWrap_offer({
    data,
    compareData,
    getAggregateDataByOffer,
    isCompare,
    performSort,
    themeSelector,
    handleOrderCallback,
    localOrder,

    whatif_edit,
    setWhatif_edit,
    allOffers,
    selectedUser,
    userInfoSelector,
    dateFilters,
    allUsers,
    allTrackingProfilesSelector,
    prepareTableData,

    getCampaignData_offers,
    getCampaignData_offers_dates,
    getCampaignData_offers_campaigns,
    getCamapinData_IDs
}) {
    const [selected, setSelected] = React.useState([]);
    const [offerSearch, setOfferSearch] = React.useState("");

    const setRowColor = (item) => {
        let color = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
        if (selected.includes(item?.columns[0]?.keyID)) return color;
        return null;
    };

    const finalData = React.useMemo(() => {
        setSelected([]);
        if (isCompare) {
            if (!data || !compareData || !allOffers) return [];
            if (data.status === "error" || compareData.status === "error" || allOffers.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        } else {
            if (!data || !allOffers) return [];
            if (data.status === "error" || allOffers.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        };

        let flt = {};
        let offerSearchArr = [];
        if (offerSearch) {
            let searchSplit = offerSearch.split(" ").map(s => s.trim().toLowerCase()).filter(s => s);

            if (searchSplit.length > 0) {
                let sites = [];

                for (let offer of (allOffers?.status === "ok" ? allOffers.data : [])) {
                    let found = true;
                    for (let item of searchSplit) {
                        if (
                            !String(offer.ID).toLowerCase().includes(item) &&
                            !String(offer.Name).toLowerCase().includes(item) &&
                            !String(offer.OfferNiche).toLowerCase().includes(item) &&
                            !String(offer.OfferType).toLowerCase().includes(item) &&
                            !String(offer.Country).toLowerCase().includes(item)
                        ) {
                            found = false;
                            break;
                        };
                    };

                    if (found) {
                        sites.push(...(offer?.Sites ?? []));
                        offerSearchArr.push(offer?.ID);
                    };
                };

                sites = [...new Set(sites)];
                if (sites.length > 0) {
                    flt["LandingSiteID"] = sites;
                };
            };
        };

        let out = [];
        for (let offer of allOffers.data.filter(d => {
            if (offerSearch.length > 0 && !offerSearchArr.includes(d?.ID)) return false;

            if (selectedUser?.UserID?.startsWith("all-team-")) return true;

            if (!selectedUser || !selectedUser?.UserID) {
                let userID = userInfoSelector?.ID;
                if (userID) {
                    if (d.ResponsiblePerson !== userID) return false;
                };
                return true;
            };
            return d.ResponsiblePerson === selectedUser.UserID;
        })) {
            if (isCompare) {
                out.push({
                    columns: [
                        {
                            keyID: offer.ID, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onDoubleClick={() => {
                                let outData = getCampaignData_offers(offer);
                                if (!outData) return;

                                let dateData = getCampaignData_offers_dates(offer);
                                let campaignData = getCampaignData_offers_campaigns(offer);

                                let campaignIDs = getCamapinData_IDs(offer);
                                animateBox(<MarketingOffersReport_perCampaignType
                                    data={outData}
                                    dateData={dateData}
                                    campaignData={campaignData}
                                    headline={`${offer.Name} (${offer.OfferType ?? "-"}, ${offer.Country ?? "?"})`}
                                    campaignIDs={campaignIDs}
                                    allUsers={allUsers?.status === "ok" ? [
                                        {
                                            ID: userInfoSelector.ID,
                                            Team: userInfoSelector?.Team ?? null,
                                            _profiles: allTrackingProfilesSelector
                                        },
                                        ...(allUsers?.data ? allUsers.data : [])
                                    ] : []}
                                    filters={(()=>{
                                        let trackFilters = [];
                                        if (dateFilters) {
                                            if (dateFilters?.start && dateFilters?.end) {
                                                trackFilters.push({ name: "createdAt", op: "pdgeq", value: dateFilters.start.toDate().getTime() });
                                                trackFilters.push({ name: "createdAt", op: "pdleq", value: dateFilters.end.toDate().getTime() });
                                            };
                                        };
                                        return trackFilters;
                                    })()}
                                    prepareTableData={(data, column) => prepareTableData(data, column)}
                                />)
                            }} onClick={(e) => {
                                let t = e.target;
                                setSelected(s => {
                                    if (s.includes(offer?.ID)) {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                        return s.filter(sf => sf !== offer?.ID);
                                    } else {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                        return [...s, offer?.ID];
                                    };
                                });
                            }}>
                                <div className="genericTableWrap__campaignWithImg__padded"></div>
                                <p>
                                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={`${offer.Name} (${offer.OfferType ?? "-"}, ${offer.Country ?? "?"})`}>{allOffers.data.indexOf(offer) + 1}. {offer.Name} ({offer.OfferType ?? "-"}, {offer.Country ?? "?"})</span>
                                    <span></span>
                                </p>
                            </p>, style: { width: null }
                        },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Visits", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Visits", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Conversions", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Conversions", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CR", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CR", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ARC", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ARC", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Approved", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Approved", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "AR", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "AR", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "PaymentPerLead", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "PaymentPerLead", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CostPerAnyLead", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CostPerAnyLead", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CostPerLead", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CostPerLead", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ADP", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ADP", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ROI", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ROI", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ROAS", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ROAS", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "DR", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "DR", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Revenue", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Revenue", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Spent", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Spent", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Profit", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Profit", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CTR", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CTR", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                        { keyID: offer.ID, type: "group", group: [
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CPC", {}, data), style: {color: "rgb(122, 108, 219)"}},
                            {keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CPC", {}, compareData), style: {color: "#f4cb7e"}},
                        ] },
                    ]
                })
                continue;
            };
            out.push({
                columns: [
                    {
                        keyID: offer.ID, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onDoubleClick={() => {
                            let outData = getCampaignData_offers(offer);
                            if (!outData) return;
                            
                            let dateData = getCampaignData_offers_dates(offer);
                            let campaignData = getCampaignData_offers_campaigns(offer);
                            
                            let campaignIDs = getCamapinData_IDs(offer);
                            animateBox(<MarketingOffersReport_perCampaignType
                                data={outData}
                                dateData={dateData}
                                campaignData={campaignData}
                                headline={`${offer.Name} (${offer.OfferType ?? "-"}, ${offer.Country ?? "?"})`}
                                campaignIDs={campaignIDs}
                                allUsers={allUsers?.status === "ok" ? [
                                    {
                                        ID: userInfoSelector.ID,
                                        Team: userInfoSelector?.Team ?? null,
                                        _profiles: allTrackingProfilesSelector
                                    },
                                    ...(allUsers?.data ? allUsers.data : [])
                                ] : []}
                                filters={(()=>{
                                    let trackFilters = [];
                                    if (dateFilters) {
                                        if (dateFilters?.start && dateFilters?.end) {
                                            trackFilters.push({ name: "createdAt", op: "pdgeq", value: dateFilters.start.toDate().getTime() });
                                            trackFilters.push({ name: "createdAt", op: "pdleq", value: dateFilters.end.toDate().getTime() });
                                        };
                                    };
                                    return trackFilters;
                                })()}
                                prepareTableData={(data, column) => prepareTableData(data, column)}
                            />)
                        }} onClick={(e) => {
                            let t = e.target;
                                setSelected(s => {
                                    if (s.includes(offer?.ID)) {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                        return s.filter(sf => sf !== offer?.ID);
                                    } else {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                        return [...s, offer?.ID];
                                    };
                                });
                        }}>
                            <div className="genericTableWrap__campaignWithImg__padded"></div>
                            <p>
                                <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={`${offer.Name} (${offer.OfferType ?? "-"}, ${offer.Country ?? "?"})`}>{allOffers.data.indexOf(offer) + 1}. {offer.Name} ({offer.OfferType ?? "-"}, {offer.Country ?? "?"})</span>
                                <span></span>
                            </p>
                        </p>, style: { width: null }
                    },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Visits") },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Conversions") },
                    (whatif_edit ? {
                        keyID: offer.ID,
                        type: "custom",
                        data: <StyledInput
                            type="number"
                            step=".01"
                            style={{height: "17px", width: "120px"}}
                            value={whatif_edit?.[offer?.ID]?.["CR"] ?? getAggregateDataByOffer(offer, "CR").split(" ")[0]}
                            onChange={e => {
                                let val = e?.target?.value;
                                if (val === null || val === undefined) return;
                                val = Number(val);
                                if (isNaN(val)) return;
                                if (val < 0 || val > 100) return;

                                let wf = {...whatif_edit};
                                if (!wf[offer?.ID]) wf[offer?.ID] = {};
                                wf[offer?.ID]["CR"] = val;

                                let totalVisits = getAggregateDataByOffer(offer, "Visits");
                                totalVisits = Number(totalVisits.replace(",", ""));
                                wf[offer?.ID]["Conversions"] = (val === 0) ? 0 : Number(totalVisits / 100 * val).toFixed(0);
                                wf[offer?.ID]["ARC"] = wf[offer?.ID]["Conversions"];

                                // also include Approved/AR
                                let totalConversions = wf[offer?.ID]["Conversions"];
                                let totalAR = getAggregateDataByOffer(offer, "AR");
                                totalAR = Number(totalAR.split(" ")[0].replace(",", ""));
                                wf[offer?.ID]["Approved"] = (totalAR === 0) ? 0 : Number(totalConversions / 100 * totalAR).toFixed(0);

                                setWhatif_edit(wf);
                            }}
                        />
                    } : { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CR") }),
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ARC") },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Approved") },
                    (whatif_edit ? {
                        keyID: offer.ID,
                        type: "custom",
                        data: <StyledInput
                            type="number"
                            step=".01"
                            style={{height: "17px", width: "120px"}}
                            value={whatif_edit?.[offer?.ID]?.["AR"] ?? getAggregateDataByOffer(offer, "AR").split(" ")[0]}
                            onChange={e => {
                                let val = e?.target?.value;
                                if (val === null || val === undefined) return;
                                val = Number(val);
                                if (isNaN(val)) return;
                                if (val < 0 || val > 100) return;

                                let wf = {...whatif_edit};
                                if (!wf[offer?.ID]) wf[offer?.ID] = {};
                                wf[offer?.ID]["AR"] = val;

                                let totalVisits = getAggregateDataByOffer(offer, "Conversions");
                                totalVisits = Number(totalVisits.replace(",", ""));
                                wf[offer?.ID]["Approved"] = (val === 0) ? 0 : Number(totalVisits / 100 * val).toFixed(0);

                                setWhatif_edit(wf);
                            }}
                        />
                    } : { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "AR") }),
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "PaymentPerLead") },
                    (whatif_edit ? {
                        keyID: offer.ID,
                        type: "custom",
                        data: <StyledInput
                            type="number"
                            step=".01"
                            style={{height: "17px", width: "120px"}}
                            value={whatif_edit?.[offer?.ID]?.["CostPerAnyLead"] ?? getAggregateDataByOffer(offer, "CostPerAnyLead").split(" ")[0].replace(",", "")}
                            onChange={e => {
                                let val = e?.target?.value;
                                if (val === null || val === undefined) return;
                                val = Number(val);
                                if (isNaN(val)) return;
                                if (val < 0) return;

                                let wf = {...whatif_edit};
                                if (!wf[offer?.ID]) wf[offer?.ID] = {};
                                wf[offer?.ID]["CostPerAnyLead"] = val;

                                setWhatif_edit(wf);
                            }}
                        />
                    } : { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CostPerAnyLead") }),
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CostPerLead") },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ADP") },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ROI") },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "ROAS") },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "DR") },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Revenue") },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Spent") },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "Profit") },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CTR") },
                    { keyID: offer.ID, type: "text", text: getAggregateDataByOffer(offer, "CPC") }
                ]
            });
        };

        if (out.length > 0 && !whatif_edit) {
            out = performSort(out, ["Offer", "Visits", "Leads", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ROI", "ROAS", "DR", "Revenue", "Spent", "ADP", "Profit", "CTR", "CPC"]);
            out = out.filter(t => {
                if (String(t.columns[1].text) === "0") return false;
                return true;
            });
            
            if (isCompare) {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Visits", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Visits", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Conversions", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Conversions", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CR", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CR", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ARC", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ARC", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Approved", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Approved", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "AR", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "AR", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "PaymentPerLead", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "PaymentPerLead", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CostPerAnyLead", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CostPerAnyLead", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CostPerLead", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CostPerLead", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ADP", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ADP", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ROI", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ROI", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ROAS", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ROAS", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "DR", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "DR", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Revenue", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Revenue", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Spent", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Spent", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Profit", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Profit", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CTR", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CTR", flt, compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CPC", flt, data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CPC", flt, compareData), style: {color: "#f4cb7e"} }
                        ] }
                    ]
                })
            } else {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Visits", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Conversions", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CR", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ARC", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Approved", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "AR", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "PaymentPerLead", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CostPerAnyLead", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CostPerLead", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ADP", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ROI", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "ROAS", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "DR", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Revenue", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Spent", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "Profit", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CTR", flt) },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByOffer(null, "CPC", flt) }
                    ]
                });
            };
        } else if (out.length === 0) {
            out.push({ columns: [{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }] });
        };

        return out;
    }, [data, compareData, isCompare, whatif_edit, offerSearch, localOrder]);

    return <FilteredCustomTable
        canAnimate={false}
        colors={setRowColor}
        orderCB={handleOrderCallback}
        columnBorders={data?.status === "ok"}
        headers={["Offer", "Visits", "Leads", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ADP", "ROI", "ROAS", "DR", "Revenue", "Spent", "Profit", "CTR", "CPC"]}
        customHeaders={{
            "Offer": <div>
                <p className="genericTableWrap__campaignWithImg__headingPadded"></p>
                <FilterBySearch placeholder="Search..." style={{width: "calc(100% - 60px)", height: "40px"}} onChange={e => setOfferSearch(e)} onClick={e => {
                    e?.stopPropagation?.();
                    e?.preventDefault?.();
                }} />
            </div>,
            "Leads": "Conversions",
            "CLR": <span title="Conversion rate" style={{display: "flex", alignItems: "center", gap: "10px"}}>
                <span>CR %</span>
                {(!whatif_edit && !isCompare) && <img src="/images/icon_edit.svg" style={{width: "17px", height: "17px"}} onClick={e => {
                    e?.stopPropagation?.();
                    e?.preventDefault?.();
                    setWhatif_edit({});
                }} />}
            </span>,
            "CLs": <span title="Approved leads">Approved</span>,
            "CARC": <span title="Approved leads rate">AR %</span>,
            "PPL": <span title="Payment per lead">PPL</span>,
            "CPL": <span title="Cost per lead">CPA</span>,
            "CPAL": <span title="Cost per approved order">CPAO</span>,
            "CTR": <span title="Click-through rate">CTR</span>,
            "CPC": <span title="Cost per click">CPC</span>,
            "ROI": <span title="Return on investment">ROI</span>,
            "ROAS": <span title="Return on ad spend">ROAS</span>
        }}
        key="user-report-marketing-table"
        accent="#6C5DD3"
        theme={themeSelector}
        style={{
            columnGap: "35px"
        }}
        stickyHeader={894}
        stickyHeaderClass="component__contentWrapper"
        stickyFooter={-1}
        stickyFooterClass="component__contentWrapper"
        spinnerColor={themeSelector === "dark" ? "white" : "black"}
        showSpinner={!data}
        customColumns={[
            "300px",
            ...(new Array(18).fill("auto"))
        ]}
        data={finalData}
    />
});

const MarketingOffersReport_tableWrap_team = React.memo(function MarketingOffersReport_tableWrap_team({
    data,
    compareData,
    themeSelector,
    allUsersWithMe,
    isCompare,
    getAggregateDataByUser,
    allUsers,
    performSort,
    handleOrderCallback,
    dateFilters
}) {
    const [selected, setSelected] = React.useState([]);

    const setRowColor = (item) => {
        let color = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
        if (selected.includes(item?.columns[0]?.keyID)) return color;
        return null;
    };

    const VerifySpent = (props2) => {
        const [data, setData] = React.useState();

        const getApprovedSpentData = async (userID) => {
            if (props2.dateFilters === undefined || props2.dateFilters === null) return null;
    
            let tmp = [userID];
            if (tmp.length === 0) return null;
    
            let filters = [];
            if (props2.dateFilters) {
                if (props2.dateFilters?.start && props2.dateFilters?.end) {
                    filters.push({ name: "Date", op: "dgeq", value: moment(props2.dateFilters.start).add(12, "hours").toDate().getTime() });
                    filters.push({ name: "Date", op: "dleq", value: moment(props2.dateFilters.end).toDate().getTime() });
                };
            };
    
            return await axios({
                method: "POST",
                url: `${backendModule.backendURL}/approvedSpendsPerAccount/getApprovedSpends`,
                data: {
                    allUsers: true,
                    filters: [
                        ...filters,
                        {name: "CreatedBy", op: "in", value: tmp}
                    ]
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    let totalSpent = 0;
                    for (let s of res.data.data) {
                        totalSpent += +s.Amount;
                    };
    
                    let dataSpent = getAggregateDataByUser(userID, "Spent", props2.data);
                    dataSpent = Number(String(dataSpent).split(" ")[0].replace(",", ""));
    
                    if (dataSpent === 0 && totalSpent === 0) return null;
                    
                    if (Number(totalSpent).toFixed(0) >= Number(dataSpent).toFixed(0)) {
                        return true;
                    } else {
                        return false;
                    };
                } else {
                    return false;
                };
            }).catch(() => {
                return null;
            });
        };

        React.useEffect(() => {
            getApprovedSpentData(props2.ID).then(setData);
        }, []);

        if (data === undefined) return <Spinner style={{width: "18px", height: "18px"}} color={props2.themeSelector === "dark" ? "white" : "black"} />
        return <span style={{
            color: data === null ? null : (data ? (props2.themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight) : (props2.themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight))
        }}>{props2.Spent}</span>
    };

    const finalData = React.useMemo(() => {
        if (isCompare) {
            if (!data || !compareData || !allUsersWithMe) return [];
            if (data.status === "error" || compareData.status === "error" || allUsersWithMe.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        } else {
            if (!data || !allUsersWithMe) return [];
            if (data.status === "error" || allUsersWithMe.status === "error") return [[{ keyID: "noData-error", type: "text", text: "Error while fetching data!" }]];
        };
        let out = [];
        for (let usr of allUsersWithMe.data) {
            if (isCompare) {
                out.push({
                    columns: [
                        {
                            keyID: usr.ID, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                                let t = e.target;
                                setSelected(s => {
                                    if (s.includes(usr.ID)) {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                        return s.filter(sf => sf !== usr.ID);
                                    } else {
                                        if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                        return [...s, usr.ID];
                                    };
                                });
                            }}>
                                <div className="genericTableWrap__campaignWithImg__padded"></div>
                                <p>
                                    <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={usr.Username}>{allUsers.data.indexOf(usr) + 1}. {usr.Username}</span>
                                    <span></span>
                                </p>
                            </p>, style: { width: null }
                        },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Visits", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Visits", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Conversions", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Conversions", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ARC", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ARC", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Approved", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Approved", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "AR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "AR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "PaymentPerLead", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "PaymentPerLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CostPerAnyLead", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CostPerAnyLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CostPerLead", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CostPerLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ADP", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ADP", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ROI", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ROI", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ROAS", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ROAS", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "DR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "DR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Revenue", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Revenue", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Spent", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Spent", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Profit", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Profit", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CTR", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CTR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: usr.ID, type: "group", group: [
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CPC", data), style: {color: "rgb(122, 108, 219)"} },
                            { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CPC", compareData), style: {color: "#f4cb7e"} }
                        ] }
                    ]
                })
                continue;
            };

            out.push({
                columns: [
                    {
                        keyID: usr.ID, type: "custom", data: <p className="genericTableWrap__campaignWithImg" onClick={(e) => {
                            let t = e.target;
                            setSelected(s => {
                                if (s.includes(usr.ID)) {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = null;
                                    return s.filter(sf => sf !== usr.ID);
                                } else {
                                    if (t) t.parentNode.parentNode.querySelector("div").style.backgroundColor = themeSelector === "dark" ? "rgb(55, 51, 81)" : "rgb(196, 191, 227)";
                                    return [...s, usr.ID];
                                };
                            });
                        }}>
                            <div className="genericTableWrap__campaignWithImg__padded"></div>
                            <p>
                                <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={usr.Username}>{allUsers.data.indexOf(usr) + 1}. {usr.Username}</span>
                                <span></span>
                            </p>
                        </p>, style: { width: null }
                    },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Visits") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Conversions") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CR") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ARC") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Approved") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "AR") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "PaymentPerLead") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CostPerAnyLead") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CostPerLead") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ADP") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ROI") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "ROAS") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "DR") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Revenue") },
                    { keyID: usr.ID, type: "text", text: <VerifySpent Spent={getAggregateDataByUser(usr.ID, "Spent")} ID={usr.ID} themeSelector={themeSelector} dateFilters={dateFilters} data={data} /> },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "Profit") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CTR") },
                    { keyID: usr.ID, type: "text", text: getAggregateDataByUser(usr.ID, "CPC") }
                ]
            });
        };

        if (out.length > 0) {
            out = performSort(out, ["User", "Visits", "Leads", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ROI", "ROAS", "DR", "Revenue", "Spent", "ADP", "Profit", "CTR", "CPC"]);
            if (isCompare) {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Visits", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Visits", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Conversions", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Conversions", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ARC", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ARC", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Approved", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Approved", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "AR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "AR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "PaymentPerLead", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "PaymentPerLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CostPerAnyLead", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CostPerAnyLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CostPerLead", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CostPerLead", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ADP", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ADP", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ROI", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ROI", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ROAS", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ROAS", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "DR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "DR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Revenue", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Revenue", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Spent", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Spent", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Profit", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Profit", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CTR", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CTR", compareData), style: {color: "#f4cb7e"} }
                        ] },
                        { keyID: "footer-1", type: "group", group: [
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CPC", data) },
                            { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CPC", compareData), style: {color: "#f4cb7e"} }
                        ] }
                    ]
                })
            } else {
                out.push({
                    isFooter: true,
                    columns: [
                        { keyID: "footer-1", type: "custom", data: <p className="genericTableWrap__campaignWithImg"><div className="genericTableWrap__campaignWithImg__padded"></div>Total</p>, style: { width: null } },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Visits") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Conversions") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ARC") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Approved") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "AR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "PaymentPerLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CostPerAnyLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CostPerLead") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ADP") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ROI") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "ROAS") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "DR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Revenue") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Spent") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "Profit") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CTR") },
                        { keyID: "footer-1", type: "text", text: getAggregateDataByUser(null, "CPC") }
                    ]
                })
            };
        } else {
            out.push({ columns: [{ keyID: "noData-noData", type: "custom", data: "Nothing to show..." }] });
        };

        return out;
    }, [data, compareData]);

    return <FilteredCustomTable
        canAnimate={false}
        colors={setRowColor}
        orderCB={handleOrderCallback}
        columnBorders={data?.status === "ok"}
        headers={["User", "Visits", "Leads", "CLR", "Processed", "CLs", "CARC", "PPL", "CPL", "CPAL", "ADP", "ROI", "ROAS", "DR", "Revenue", "Spent", "Profit", "CTR", "CPC"]}
        customHeaders={{
            "User": <div>
                <p className="genericTableWrap__campaignWithImg__headingPadded"></p>
                User
            </div>,
            "Leads": "Conversions",
            "CLR": <span title="Conversion rate">CR %</span>,
            "CLs": <span title="Approved leads">Approved</span>,
            "CARC": <span title="Approved leads rate">AR %</span>,
            "PPL": <span title="Payment per lead">PPL</span>,
            "CPL": <span title="Cost per lead">CPA</span>,
            "CPAL": <span title="Cost per approved order">CPAO</span>,
            "CTR": <span title="Click-through rate">CTR</span>,
            "CPC": <span title="Cost per click">CPC</span>,
            "ROI": <span title="Return on investment">ROI</span>,
            "ROAS": <span title="Return on ad spend">ROAS</span>
        }}
        key="user-report-marketing-table"
        accent="#6C5DD3"
        theme={themeSelector}
        style={{
            columnGap: "35px"
        }}
        stickyHeader={894}
        stickyHeaderClass="component__contentWrapper"
        stickyFooter={-1}
        stickyFooterClass="component__contentWrapper"
        spinnerColor={themeSelector === "dark" ? "white" : "black"}
        showSpinner={!data}
        customColumns={[
            "200px",
            ...(new Array(18).fill("auto"))
        ]}
        data={finalData}
    />
});

export default MarketingOffersReport;