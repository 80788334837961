export const getSenderGroups = () => {
    return {
        "Prostata": [
            "PROSTAZD",
            "PROSTCARE",
            "PROSTSAVT",
            "ZDPROSTA",
            "PROSTAVET",
            "PROSTATIP",
            "ZDRPROSTA",
            "PROSTINFO",
            "LECIPROST",
            "PROSTZBRG",
            "PROSTZDRA",
            "PROSTVODI",
            "BRIGAPRO",
            "PROSTTERA",
            "ZDPROSTAN",
            "PROSTZDRI",
            "SAVJPROST",
            "PROSTPOMO",
            "PROSTZNAN",
            "PROSTZIVI"
        ],
        "Mršanje": [
            "MRSAVLJEN",
            "DIJETASAV",
            "FITZDRAVL",
            "ZDRAVKILO",
            "SLIMSAVET",
            "DIJETATIP",
            "MRSAVZIVI",
            "ZDRAVTELO",
            "FITSAVJET",
            "ZDRAVJELO",
            "SLIMZIVOT",
            "ZDRAVSTIL",
            "KILOSTOP",
            "FITBRIGA",
            "SLIMPLAN",
            "FITZIVOT",
            "ZDRMRSAV",
            "MRSAVINFO",
            "DIJETINFO",
            "FITZDRAVJ"
        ],
        "Sluh": [
            "SLUHSAVET",
            "SLUHZDRAV",
            "CARESLUH",
            "SLUHINFO",
            "ZDRAVSLUH",
            "SLUHTIP",
            "SLUHBRIGA",
            "SLUHZIVOT",
            "AUDIOSAVJ",
            "ZVUKZDRAV",
            "UHOSAVJET",
            "SLUHPLAN",
            "AUDIOINFO",
            "UHOZDRAVL",
            "SLUHVODI",
            "UHOTERA",
            "SLUHNEWS",
            "UHOBRIGA"
        ],
        "Hemoroidi": [
            "HEMOREMED",
            "HEMOSAVET",
            "HEMOBRIGA",
            "HEMOTERA",
            "HEMOINFO",
            "ZDRAVHEMO",
            "HEMOTIP",
            "HEMOCARE",
            "HEMOZDRAV",
            "HEMOZIVOT",
            "HEMOLJEK",
            "HEMOPOMOC",
            "HEMOVODI",
            "ZDRAVHEM",
            "HEMOSAVJ",
            "HEMOLIST",
            "HEMOHELP",
            "HEMOSIG",
            "HEMOSTOP",
            "HEMOZBRIN"
        ],
        "Potencija": [
            "POTENCIJA",
            "POTENCSAV",
            "POTENCZDR",
            "POTENCTIP",
            "SEXZDRAVL",
            "POTENCVOD",
            "LIBIDOSAV",
            "POTENCLJ",
            "EROTSZDR",
            "ZDRAVSEX",
            "POTENCARE",
            "LIBIDOINF",
            "EROTPOMOC",
            "SEXZIVOT",
            "POTENCUP",
            "POTENCPOM",
            "LIBIDOZDR",
            "POTENCZIV",
            "EROTINFO",
            "SEXZDRAVJ"
        ],
        "Pritisak": [
            "PRITISAK",
            "KRVPRITIS",
            "HIPERTENZ",
            "PRITSIG",
            "PRITZDRAV",
            "PRITSAVET",
            "PRITCARE",
            "PRITINFO",
            "KRVPRITIP",
            "HIPERINFO",
            "ZDRAVPRIT",
            "PRITPOMOC",
            "PRITZIVOT",
            "PRITISKAR",
            "HIPERZDRA",
            "PRITSAVJ",
            "KRVZDRAVL",
            "PRITBRIGA",
            "KRVZIVOT",
            "PRITZBRIN"
        ],
        "Dijabetes": [
            "DIJABETIK",
            "SEKERZDRA",
            "DIJABESAV",
            "DIJABINFO",
            "SEKERINFO",
            "DIJABETIP",
            "ZDRAVSEKR",
            "DIJABCARE",
            "DIJABZIVI",
            "SEKERBRIG",
            "DIJABVODI",
            "SEKERZIV",
            "DIJABPOMO",
            "ZDRAVSEK",
            "SEKERZDRI",
            "DIJABSIG",
            "DIJABTERA",
            "SEKERSTOP",
            "DIJABZBRG",
            "SEKERPLAN"
        ],
        "Gljivice": [
            "GLJIVICE",
            "FUNGIZDRA",
            "GLJIVSAVJ",
            "GLJIVCARE",
            "ANTIFUNG",
            "GLJIVSTOP",
            "GLJIVPOMO",
            "FUNGISIG",
            "GLJIVINFO",
            "GLJIVZBRG",
            "FUNGICARE",
            "GLJIVTIP",
            "FUNGIZIV",
            "GLJIVZDRA",
            "FUNGIVODI",
            "ANTIMICO",
            "GLJIVBRIG",
            "FUNGITERA",
            "GLJIVLJEC",
            "MICOSAVJ"
        ],
        "Lice": [
            "LICEZDRAV",
            "LICESAVJ",
            "KOZALICE",
            "LICECARE",
            "NEGA LICA",
            "LICEINFO",
            "LICEZBRG",
            "LICEPOMOC",
            "LICEVODI",
            "KOZALJEP",
            "LICETIP",
            "LICEBRIGA",
            "LJEPOLICE",
            "LICETERA",
            "LICEZIVOT",
            "LICEGLAD",
            "ZDRAVLICE",
            "NEGA KOZE",
            "LICESIG",
            "LICEZDRI"
        ],
        "Oči": [
            "ZdravVid",
            "VidClear",
            "OkoPlus",
            "VidSharp",
            "BistVid",
            "OkoZdrav",
            "FocusEye",
            "VidLife",
            "SvetOko",
            "VidJasno",
            "OkoVita",
            "EyeCareV",
            "VidSnaga",
            "ProVid",
            "JasnoOko",
            "VidZdrav",
            "VidKvalt",
            "OciSvetl",
            "VidPerf",
            "OkoHelth"
        ]
    };
};