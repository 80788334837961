import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import useDefer from "../../modules/hooks/useDefer";
import * as backendModule from "../../modules/backendModule";
import * as siteFunctionsActions from "../../actions/siteFunctionsActions";
import { countries } from "../../modules/countryModule";

import FilterByDate from "../../components/filters/FilterByDate";
import FilterColumns from "../../components/filters/FilterColumns";
import AdvancedDropdown from "../../components/customComponents/AdvancedDropdown";

import Spinner from "../../components/customComponents/Spinner";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FilteredCustomTable } from "../../components/customComponents/Table";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const generic_availableCampaignColumns = [
    "Visits", "Engagement",
    "Unique visits", "Conversions", "Spent", "Revenue", "CR", "AR", "Approved",
    "CUR", "CURC", "Realtime", "Visits PL", "Visits LP", "Profit", "EPV", "CPA", "CPAO",
    "CRR", "CRS"
];

const sms_availableCampaignColumns = [
    ...generic_availableCampaignColumns,
    "SMS total", "SMS pending",
    "SMS success", "SMS fail", "SMS CTR", "SMS CR"
];

const facebook_availableCampaignColumns = [
    "Visits", "Clicks", "CPC", "CTR", "CPR", "CPR1000",
    "Engagement", "Unique visits", "Conversions", "Revenue", "Profit",
    "CPV", "CR", "ROI", "CPA",
    "AR", "Approved", "CUR", "CURC", "Realtime", "Visits PL", "Visits LP",
    "EPV", "Spent"
];

const integrationTypes = {
    "facebook": {
        "campaigns": [
            {name: "Campaigns Scale-Track", type: "ST", image: "/images/logo.svg"},
            {name: "Campaigns Facebook", type: "INT", image: "/images/integrations/integration_header_facebook.svg"},
        ],
        "adsets": [
            {name: "Ad sets Scale-Track", type: "ST", image: "/images/logo.svg"},
            {name: "Ad sets Facebook", type: "INT", image: "/images/integrations/integration_header_facebook.svg"},
        ],
        "ads": [
            {name: "Ads Scale-Track", type: "ST", image: "/images/logo.svg"},
            {name: "Ads Facebook", type: "INT", image: "/images/integrations/integration_header_facebook.svg"},
        ]
    }
};

const Dashboard = () => {
    const trackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? null);

    const selectColumns = () => {
        if (!trackingProfileSelector) return [];
        if (trackingProfileSelector === "-1") return generic_availableCampaignColumns;
        if (trackingProfileSelector === "-2") return sms_availableCampaignColumns;
        if (trackingProfileSelector.startsWith("fb-")) return facebook_availableCampaignColumns;
        if (trackingProfileSelector.startsWith("mg-")) return facebook_availableCampaignColumns;

        return [];
    };

    return <div className="route__dashboard">
        <Dashboard_body integrationID={trackingProfileSelector} key={`dashboard-stats-${trackingProfileSelector}`} columns={selectColumns()} />
    </div>
};

const Dashboard_body = props => {
    const [activeTab, setActiveTab] = React.useState(0);
    const [integrationType, setIntegrationType] = React.useState();
    const [integrationParam, setIntegrationParam] = React.useState("ST");

    const [selectedColumn, setSelectedColumn] = React.useState();
    const [data, setData] = React.useState();
    const [tableData, setTableData] = React.useState();
    const [tableSites, setTableSites] = React.useState();
    const [chartData, setChartData] = React.useState({
        labels: [],
        datasets: []
    });
    const [dateFilter, setDateFilter] = React.useState();
    const [visibleColumns, setVisibleColumns] = React.useState();
    const [tableTimestamp, setTableTimestamp] = React.useState(Date.now());

    const trackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? null);
    const trackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const timestampRef = React.useRef();
    const timestampRef2 = React.useRef();

    const visibleColumnsDefer = useDefer();
    const dataDefer = useDefer();
    const dataTableDefer = useDefer();
    const curDispatch = useDispatch();

    const colorSets = ['#6D71F9', '#4BBEA0', '#E861D8', '#00AFFF', '#4A57DA'];
    const backgroundColorSets = ['rgba(109, 114, 249, 0.5)', 'rgba(75, 190, 160, 0.5)', 'rgba(232, 97, 216, 0.5)', 'rgba(0, 175, 255, 0.5)', 'rgba(74, 87, 218, 0.5)'];
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom'
            },
            title: {
                display: false
            },
        },
        scales: {
            yAxes: {
                grid: {
                    color: "#373A43"
                }
            },
            xAxes: {
                grid: {
                    color: "#373A43"
                }
            }
        }
    };

    const prepareTableData = (data, column) => {
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
            case "CPC":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${tmpRevenue.toFixed(2)} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CUR":
            case "CRR":
            case "ROI":
            case "CTR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${tmpCR.toFixed(2)} %`;
            case "ROAS":
                let tmpROAS = Number(data);
                if (isNaN(tmpROAS)) return "-";
                return `${tmpROAS.toFixed(2)}x`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString();
        };
    };

    const getTrackingProfile = () => {
        if (!trackingProfileSelector) return null;

        for (let item of trackingProfilesSelector) {
            if (item.ID === trackingProfileSelector) return item;
        };
        return null;
    };

    const filterVisibleColumns = () => {
        if (!visibleColumns) return [];
        if (visibleColumns.length > 5) return visibleColumns.slice(visibleColumns.length - 5);
        return visibleColumns;
    };

    const getData = (ts) => {
        if (!trackingProfileSelector) return null;
        if (timestampRef.current !== ts) return;
        if (!visibleColumns) return;
        if (visibleColumns.length === 0) return setData(backendModule.genericError);

        setData();

        let smsFilters = [];
        let trackFilters = [];

        let tmpDateFilter = dateFilter;

        if (!tmpDateFilter) {
            tmpDateFilter = {
                start: moment().add(-1, "month").startOf("day"),
                end: moment().endOf("day")
            };
        };
        if (tmpDateFilter) {
            if (tmpDateFilter?.start && tmpDateFilter?.end) {
                if (tmpDateFilter.start.diff(tmpDateFilter.end, "days") > 30) tmpDateFilter.start = tmpDateFilter.end.add(-1, "month").startOf("day");

                trackFilters.push({ name: "createdAt", op: "pdgeq", value: tmpDateFilter.start.toDate().getTime() });
                trackFilters.push({ name: "createdAt", op: "pdleq", value: tmpDateFilter.end.toDate().getTime() });

                smsFilters.push({ name: "createdAt", op: "pdgeq", value: tmpDateFilter.start.toDate().getTime() });
                smsFilters.push({ name: "createdAt", op: "pdleq", value: tmpDateFilter.end.toDate().getTime() });
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                IntegrationType: integrationType,
                IntegrationID: trackingProfileSelector,
                TableHeaders: [
                    ...filterVisibleColumns().map(vc => vc.replace(" ", "_")),
                    "Date_day",
                    "Date_month",
                    "Date_year"
                ],
                smsFilters,
                trackFilters,
                trackGroupByDate: true,
                limit: null,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError)
        });
    };

    const getMinMaxDate = () => {
        if (!data) return {min: moment().startOf("day"), max: moment().endOf("day"), labels: [moment().format("DD-MM")]};
        if (data.status === "error") return {min: moment().startOf("day"), max: moment().endOf("day"), labels: [moment().format("DD-MM")]};

        let minDate = null;
        let maxDate = null;
        let labels = [];

        let isIntegration = !getTrackingProfile()?.type?.startsWith?.("scale-track");

        for (let campaign of data.data) {
            for (let key of Object.keys(campaign.TableData)) {
                if (!campaign.TableData[key]) continue;

                const lookupData = isIntegration ? campaign.TableData[key][integrationParam] : campaign.TableData[key];
                if (!lookupData) continue;

                for (let finalItem of lookupData) {
                    let tmpDate = moment(`${finalItem["Date_day"]}-${finalItem["Date_month"]}-${finalItem["Date_year"]}`, "DD-MM-YYYY");
                    if (!tmpDate.isValid()) continue;

                    if (!labels.includes(tmpDate.format("DD-MM-YYYY"))) labels.push(tmpDate.format("DD-MM-YYYY"));
                    if (!minDate) minDate = tmpDate;
                    if (!maxDate) maxDate = tmpDate;
    
                    if (tmpDate.isBefore(minDate)) minDate = tmpDate;
                    if (tmpDate.isAfter(maxDate)) maxDate = tmpDate;
                };
            };
        };

        labels = labels.sort((a, b) => {
            if (moment(a, "DD-MM-YYYY").isAfter(moment(b, "DD-MM-YYYY"))) return 1;
            return -1;
        })

        return {min: minDate, max: maxDate, labels};
    };

    const getDatasetAggregateData = (key) => {
        if (!chartData) return "-";
        if (!chartData?.datasets) return "-";

        for (let set of chartData.datasets) {
            if (set?.label === key) {
                return prepareTableData(set.data.reduce((acc, val) => +acc + +val, 0), key);
            };
        };
    };

    const getDataForTables = (ts) => {
        if (!trackingProfileSelector) return null;
        if (timestampRef2.current !== ts) return;
        if (!selectedColumn) return;

        let trackFilters = [];
        let smsFilters = [];
        let tmpDateFilter = dateFilter;
        if (tmpDateFilter) {
            if (tmpDateFilter?.start && tmpDateFilter?.end) {
                if (tmpDateFilter.start.diff(tmpDateFilter.end, "days") > 30) tmpDateFilter.start = tmpDateFilter.end.add(-1, "month").startOf("day");

                trackFilters.push({ name: "createdAt", op: "pdgeq", value: tmpDateFilter.start.toDate().getTime() });
                trackFilters.push({ name: "createdAt", op: "pdleq", value: tmpDateFilter.end.toDate().getTime() });

                smsFilters.push({ name: "createdAt", op: "pdgeq", value: tmpDateFilter.start.toDate().getTime() });
                smsFilters.push({ name: "createdAt", op: "pdleq", value: tmpDateFilter.end.toDate().getTime() });
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                IntegrationType: integrationType,
                IntegrationID: trackingProfileSelector,
                trackFilters,
                smsFilters,
                TableHeaders: [selectedColumn].map(vc => vc.replace(" ", "_")),
                orders: [
                    {name: selectedColumn?.replace?.(" ", "_"), order: activeTab === 0 ? "desc" : "asc"}
                ],
                limit: null,
                offset: 0
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef2.current !== ts) return;
            if (integrationType) {
                if (res.data.status === "ok") {
                    for (let item of res.data.data) {
                        for (let key of Object.keys(item.TableData)) {
                            if (item.TableData[key]?.[integrationParam] !== undefined) item.TableData[key] = item.TableData[key][integrationParam];
                        };
                    };
                };
            };
            setTableData(res.data);
        }).catch(() => {
            if (timestampRef2.current !== ts) return;
            setTableData(backendModule.genericError);
        });
    };

    const getSitesForTables = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/sites/getAllSites`,
            data: {
                limit: null,
                offset: 0,
                getOfferInfo: true,
                extended: true
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setTableSites(res.data);
        }).catch(() => {
            setTableSites(backendModule.genericError);
        });
    };

    React.useEffect(() => {
        if (!visibleColumns) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/userSavedColumns/getColumns`,
                data: {
                    Integration: `${props.integrationID}_campaigns`
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setVisibleColumns(res.data.data);
                } else {
                    setVisibleColumns([]);
                };
            }).catch(() => {
                setVisibleColumns([]);
            });
        } else {
            visibleColumnsDefer(() => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/userSavedColumns/updateColumns`,
                    data: {
                        Integration: `${props.integrationID}_campaigns`,
                        Columns: visibleColumns
                    },
                    ...backendModule.axiosConfig
                }).then(() => null).catch(() => null);
            }, 500);
        };
    }, [visibleColumns]);

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            dataDefer(() => {
                getData(ts);
            }, 500);
        };

        handler();

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => {
            try {
                curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
            } catch {};
        };
    }, [dateFilter, visibleColumns, integrationType, integrationParam]);

    React.useEffect(() => {
        let handler = () => {
            let ts = Date.now();
            timestampRef2.current = ts;
            setTableTimestamp(ts);
            setTableData();
            dataTableDefer(() => {
                getDataForTables(ts);
            }, 500);
        };

        handler();

        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));

        return () => {
            try {
                curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
            } catch {};
        };
    }, [dateFilter, visibleColumns, activeTab, selectedColumn, integrationType, integrationParam]);

    React.useEffect(() => {
        if (!data) return;
        if (data.status !== "ok") return;

        //TODO: processing...
        let minMaxDate = getMinMaxDate();
        let datasets = {};
        let usedColors = 0;
        let isIntegration = !getTrackingProfile()?.type?.startsWith?.("scale-track");

        for (let campaign of data.data) {
            for (let key of visibleColumns.map(vc => vc.replace(" ", "_"))) {
                if (key.startsWith("Date_")) continue;
                if (!datasets[key]) {
                    datasets[key] = {
                        fill: visibleColumns?.length === 1,
                        label: key.replace("_", " "),
                        data: [],
                        borderColor: colorSets[usedColors],
                        backgroundColor: backgroundColorSets[usedColors]
                    };
                    usedColors += 1;
                };

                let tmpData = [];
                for (let d of minMaxDate.labels) {
                    let lookupData = [];
                    try {
                        lookupData = isIntegration ? campaign.TableData[key][integrationParam] : campaign.TableData[key];
                    } catch {
                        lookupData = [];
                    };

                    let found = false;
                    if (lookupData) for (let val of lookupData) {
                        let str = moment(`${val["Date_day"]}-${val["Date_month"]}-${val["Date_year"]}`, "DD-MM-YYYY").format("DD-MM-YYYY");

                        if (str === d) {
                            found = true;
                            tmpData.push(+val.Value);
                            break;
                        };
                    };

                    if (!found) tmpData.push(0);
                };

                if (datasets[key].data.length === 0) {
                    datasets[key].data = tmpData;
                } else {
                    datasets[key].data = datasets[key].data.map((d, dIdx) => +d + +tmpData[dIdx]);
                };
            };
        };

        setChartData(d => {
            return {
                ...d,
                labels: minMaxDate.labels,
                datasets: Object.keys(datasets).map(key => datasets[key])
            };
        });
    }, [data]);

    React.useEffect(() => {
        getSitesForTables();
    }, []);

    return <>
        <div className={`route__dashboard__filters ${(trackingProfileSelector?.includes("-") && !trackingProfileSelector?.startsWith("-") && !trackingProfileSelector?.startsWith("mg-")) ? `route__dashboard__filters--additional` : ``}`}>
            {(
                trackingProfileSelector?.includes("-") &&
                !trackingProfileSelector?.startsWith("-") &&
                !trackingProfileSelector?.startsWith("mg-")
            ) && <AdvancedDropdown
                headline="Display data from"
                data={(()=>{
                    let curProfile = getTrackingProfile();
                    if (!curProfile) return;
                    if (!integrationTypes[curProfile.type]) return;

                    let out = [];
                    for (let key of Object.keys(integrationTypes[curProfile.type])) {
                        for (let item of integrationTypes[curProfile.type][key]) {
                            out.push({
                                key: `${key}-${item.type}`,
                                value: item,
                                name: item.name,
                                image: item.image
                            });
                        };
                    };
                    out = out.sort((a, b) => {
                        if (a.key.endsWith("INT") && !b.key.endsWith("INT")) return 1;
                        return -1;
                    });
                    return out;
                })()}
                onChange={e => {
                    if (e?.value === integrationType) return;
                    setIntegrationType(e?.key.split("-")[0]);
                    setIntegrationParam(e?.value?.type);
                }}
                selected={(()=>{
                    if (!integrationType) return 0;
                })()}
            />}
            <FilterByDate defaultValue="7days" onChange={e => setDateFilter(e)} />
            {visibleColumns && <FilterColumns columns={props.columns} onChange={vc => {
                try {
                    if (vc.join(",") === visibleColumns.join(",")) return;
                } catch {};
                setVisibleColumns(vc);
            }} defaultValue={visibleColumns} />}
        </div>

        <div className="route__dashboard__kpi">
            {filterVisibleColumns().map((vc, vcIdx) => {
                return <div key={`campaigns-kpi-${vc}`} className="route__dashboard__kpi__item">
                    <div className="route__dashboard__kpi__item__top" style={{
                        borderBottom: `1px solid ${colorSets[vcIdx]}`,
                        width: "max-content",
                        paddingBottom: "5px"
                    }}>{vc}</div>
                    <div className="route__dashboard__kpi__item__bottom">
                        {(chartData?.datasets && data?.status === "ok") ? <>
                            {getDatasetAggregateData(vc)}
                        </> : <Spinner style={{width: "32px", height: "32px"}} color="white" />}
                    </div>
                </div>
            })}
        </div>

        <div className="route__dashboard__chart">
            <div className={`route__dashboard__chart__spinner ${!data ? "route__dashboard__chart__spinner--active" : ""}`}>
                <Spinner color="white" />
            </div>

            <Line
                style={{width: "100%", height: "300px"}}
                options={options}
                data={{
                    labels: chartData.labels,
                    datasets: chartData.datasets
                }}
            />
        </div>

        <div className="route__dashboard__tableOrders genericTabs">
            <AdvancedDropdown
                showSearch={true}
                headline="Sort by"
                style={{width: "300px"}}
                data={(props.columns ?? []).map(c => {
                    return {
                        key: c,
                        value: c,
                        name: c.replace("_", "")
                    };
                })}
                selected={(()=>{
                    if (selectedColumn === undefined && props?.columns?.length > 0) return 0;
                    if (selectedColumn === undefined) return null;

                    return props.columns.indexOf(selectedColumn);
                })()}
                onChange={(e => selectedColumn !== e?.value && setSelectedColumn(e?.value))}
            />

            <div className={`genericTabs__tab ${activeTab === 0 ? "genericTabs__tab--active" : ""}`} onClick={() => setActiveTab(0)}>Higher</div>
            <div className={`genericTabs__tab ${activeTab === 1 ? "genericTabs__tab--active" : ""}`} onClick={() => setActiveTab(1)}>Lower</div>
        </div>

        <div className="route__dashboard__tables">
            <Table_Campaigns data={tableData} selectedColumn={selectedColumn} sites={tableSites} activeTab={activeTab} ts={tableTimestamp} />
            <Table_Countries data={tableData} selectedColumn={selectedColumn} sites={tableSites} activeTab={activeTab} ts={tableTimestamp} />
            <Table_Offers data={tableData} selectedColumn={selectedColumn} sites={tableSites} activeTab={activeTab} ts={tableTimestamp} />
            <Table_Sites data={tableData} selectedColumn={selectedColumn} sites={tableSites} activeTab={activeTab} ts={tableTimestamp} />
        </div>
    </>;
};

const Table_Campaigns = props => {
    const [data, setData] = React.useState();
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "-");

    const dataDefer = useDefer();

    const prepareTableData = (data, column) => {
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${tmpRevenue.toFixed(2)} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CUR":
            case "CRR":
            case "SMS CTR":
            case "SMS CR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${tmpCR.toFixed(2)} %`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString();
        };
    };

    const getData = (d) => {
        if (!d) return setData(d);
        if (d.status !== "ok") return setData(d);

        d = {...d, data: [...d.data]};
        d.data = d.data.sort((a, b) => {
            if (!props.selectedColumn) return -1;
            let tmp = props.selectedColumn.replace(" ", "_");
            let initial = props.activeTab === 0 ? -1 : 1;

            return (a?.TableData?.[tmp] > b?.TableData?.[tmp]) ? initial : initial*-1;
        });

        return setData(() => {
            return {
                ...d,
                data: [
                    ...d.data.splice(d.data.length - 5)
                ]
            };
        });
    };

    React.useEffect(() => {
        dataDefer(() => getData(props.data), 500);
    }, [props.data, props.activeTab]);

    React.useEffect(() => {
        setData();
    }, [props.ts]);

    return <FilteredCustomTable
        canAnimate={false}
        accent="#6C5DD3"
        theme="dark"
        headers={["Campaigns", props.selectedColumn ?? "-"]}
        customColumns={["1fr", "150px"]}
        data={(()=>{
            if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white", size: "16px"}]];
            if (data.status !== "ok") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", color: "#FF450D"}]];

            let out = [];

            for (let item of data.data) {
                out.push([
                    {keyID: item.ID, type: "text", text: item.CampaignName},
                    {keyID: item.ID, type: "text", text: prepareTableData(item?.TableData?.[props.selectedColumn.replace(" ", "_")] ?? "-", props.selectedColumn)}
                ]);
            };

            if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

            return out;
        })()}
        style={{
            columnGap: "35px",
            height: "max-content"
        }}
    />
};

const Table_Countries = props => {
    const [data, setData] = React.useState();
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "-");

    const dataDefer = useDefer();

    const prepareTableData = (data, column) => {
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${tmpRevenue.toFixed(2)} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CUR":
            case "CRR":
            case "SMS CTR":
            case "SMS CR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${tmpCR.toFixed(2)} %`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString();
        };
    };

    const getData = (d) => {
        if (!d) return setData(d);
        if (!props.sites) return setData();
        if (d.status !== "ok") return setData(d);
        if (props.sites.status !== "ok") return setData(props.sites);

        const curColumn = props.selectedColumn.replace(" ", "_");

        //? prepare the data
        let tmpCampaigns = {};
        for (let item of d.data) {
            tmpCampaigns[item.ID] = {
                CampaignName: "-",
                SiteID: item.LandingSiteID,
                Country: null,
                TableData: {[curColumn]: +item.TableData[curColumn] ?? 0}
            };
            if (!tmpCampaigns[item.ID].TableData[curColumn]) tmpCampaigns[item.ID].TableData[curColumn] = 0;
        };

        //? Fetch all sites
        let allSites = props.sites.data;

        for (let item of allSites) {
            for (let key of Object.keys(tmpCampaigns)) {
                if (tmpCampaigns[key].SiteID === item.ID) {
                    tmpCampaigns[key].CampaignName = (countries.find(c => c.code.toLowerCase() === item.Country.toLowerCase())?.name ?? "-");
                    tmpCampaigns[key].Country = item.Country ?? "-";
                };
            };
        };

        //? now combine it all into a 5 length obj of offers
        let finalOut = {};
        for (let key of Object.keys(tmpCampaigns).sort((a, b) => {
            if (tmpCampaigns[a].TableData[curColumn] < tmpCampaigns[b].TableData[curColumn]) return 1;
            return -1;
        })) {
            if (Object.keys(finalOut).length >= 5) break;
            if (!tmpCampaigns[key].Country) continue;

            let offerID = tmpCampaigns[key].Country;
            if (!finalOut[offerID]) {
                finalOut[offerID] = {
                    ...tmpCampaigns[key]
                };
            } else {
                finalOut[offerID].TableData[curColumn] += +tmpCampaigns[key].TableData[curColumn];
            };
        };

        setData({status: "ok", data: Object.keys(finalOut).map(key => {
            return {
                ...finalOut[key],
                ID: key
            };
        })})
    };

    React.useEffect(() => {
        dataDefer(() => getData(props.data), 500);
    }, [props.data, props.sites, props.activeTab]);

    React.useEffect(() => {
        setData();
    }, [props.ts]);

    return <FilteredCustomTable
    canAnimate={false}
        accent="#6C5DD3"
        theme="dark"
        headers={["Countries", props.selectedColumn ?? "-"]}
        customColumns={["1fr", "150px"]}
        data={(()=>{
            if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white", size: "16px"}]];
            if (data.status !== "ok") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", color: "#FF450D"}]];

            let out = [];

            for (let item of data.data) {
                out.push([
                    {keyID: item.ID, type: "text", text: item.CampaignName},
                    {keyID: item.ID, type: "text", text: prepareTableData(item?.TableData?.[props.selectedColumn.replace(" ", "_")] ?? "-", props.selectedColumn)}
                ]);
            };

            if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

            return out;
        })()}
        style={{
            columnGap: "35px",
            height: "max-content"
        }}
    />
};

const Table_Offers = props => {
    const [data, setData] = React.useState();
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "-");

    const dataDefer = useDefer();

    const prepareTableData = (data, column) => {
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${tmpRevenue.toFixed(2)} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CUR":
            case "CRR":
            case "SMS CTR":
            case "SMS CR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${tmpCR.toFixed(2)} %`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString();
        };
    };

    const getData = (d) => {
        if (!d) return setData(d);
        if (!props.sites) return setData();
        if (d.status !== "ok") return setData(d);
        if (props.sites.status !== "ok") return setData(props.sites);

        const curColumn = props.selectedColumn.replace(" ", "_");

        //? prepare the data
        let tmpCampaigns = {};
        for (let item of d.data) {
            tmpCampaigns[item.ID] = {
                CampaignName: "-",
                SiteID: item.LandingSiteID,
                OfferID: null,
                TableData: {[curColumn]: +item.TableData[curColumn] ?? 0}
            };
            if (!tmpCampaigns[item.ID].TableData[curColumn]) tmpCampaigns[item.ID].TableData[curColumn] = 0;
        };

        //? Fetch all sites
        let allSites = props.sites.data;

        for (let item of allSites) {
            for (let key of Object.keys(tmpCampaigns)) {
                if (tmpCampaigns[key].SiteID === item.ID) {
                    tmpCampaigns[key].OfferID = item.OfferID;
                    tmpCampaigns[key].CampaignName = item._Offer?.OfferName ?? "-"
                };
            };
        };

        //? now combine it all into a 5 length obj of offers
        let finalOut = {};
        for (let key of Object.keys(tmpCampaigns).sort((a, b) => {
            if (tmpCampaigns[a].TableData[curColumn] < tmpCampaigns[b].TableData[curColumn]) return 1;
            return -1;
        })) {
            if (Object.keys(finalOut).length >= 5) break;
            if (!tmpCampaigns[key].OfferID) continue;

            let offerID = tmpCampaigns[key].OfferID;
            if (!finalOut[offerID]) {
                finalOut[offerID] = {
                    ...tmpCampaigns[key]
                };
            } else {
                finalOut[offerID].TableData[curColumn] += +tmpCampaigns[key].TableData[curColumn];
            };
        };

        setData({status: "ok", data: Object.keys(finalOut).map(key => {
            return {
                ...finalOut[key],
                ID: key
            };
        })})
    };

    React.useEffect(() => {
        dataDefer(() => getData(props.data), 500);
    }, [props.data, props.sites, props.activeTab]);

    React.useEffect(() => {
        setData();
    }, [props.ts]);

    return <FilteredCustomTable
    canAnimate={false}
        accent="#6C5DD3"
        theme="dark"
        headers={["Offers", props.selectedColumn ?? "-"]}
        customColumns={["1fr", "150px"]}
        data={(()=>{
            if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white", size: "16px"}]];
            if (data.status !== "ok") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", color: "#FF450D"}]];

            let out = [];

            for (let item of data.data) {
                out.push([
                    {keyID: item.ID, type: "text", text: item.CampaignName},
                    {keyID: item.ID, type: "text", text: prepareTableData(item?.TableData?.[props.selectedColumn.replace(" ", "_")] ?? "-", props.selectedColumn)}
                ]);
            };

            if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

            return out;
        })()}
        style={{
            columnGap: "35px",
            height: "max-content"
        }}
    />
};

const Table_Sites = props => {
    const [data, setData] = React.useState();
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "-");

    const dataDefer = useDefer();

    const prepareTableData = (data, column) => {
        switch (column) {
            case "Revenue":
            case "Spent":
            case "Profit":
            case "EPV":
            case "CPA":
            case "CPAO":
                let tmpRevenue = Number(data);
                if (isNaN(tmpRevenue)) return "-";
                return `${tmpRevenue.toFixed(2)} ${currencySignSelector}`;
            case "CR":
            case "AR":
            case "CUR":
            case "CRR":
            case "SMS CTR":
            case "SMS CR":
                let tmpCR = Number(data);
                if (isNaN(tmpCR)) return "-";
                return `${tmpCR.toFixed(2)} %`;
            default:
                let tmp = Number(data);
                if (isNaN(tmp)) return data;
                return tmp.toLocaleString();
        };
    };

    const getData = (d) => {
        if (!d) return setData(d);
        if (!props.sites) return setData();
        if (d.status !== "ok") return setData(d);
        if (props.sites.status !== "ok") return setData(props.sites);

        const curColumn = props.selectedColumn.replace(" ", "_");

        //? find the unique pages
        let uniqueLanders = [];
        let finalCampaigns = []
        for (let item of d.data) {
            if (!uniqueLanders.includes(item.LandingSiteID)) {
                uniqueLanders.push(item.LandingSiteID);
            };
            finalCampaigns.push(item);
            if (uniqueLanders.length >= 5) break;
        };

        let out = {};
        for (let item of uniqueLanders) {
            out[item] = {
                CampaignName: "-",
                LandingSiteID: item,
                TableData: {[curColumn]: 0}
            };
        };

        for (let item of finalCampaigns) {
            if (!item.TableData[curColumn]) item.TableData[curColumn] = 0;
            out[item.LandingSiteID].TableData[curColumn] += +item.TableData[curColumn];
        };

        //? after sort, we pull the websites
        for (let site of props.sites.data) 
            if (out[site.ID]) out[site.ID].CampaignName = site.SiteName;

        setData({status: "ok", data: Object.keys(out).map(key => {
            return {
                ...out[key],
                ID: key
            };
        })});
    };

    React.useEffect(() => {
        dataDefer(() => getData(props.data), 500);
    }, [props.data, props.sites, props.activeTab]);

    React.useEffect(() => {
        setData();
    }, [props.ts]);

    return <FilteredCustomTable
    canAnimate={false}
        accent="#6C5DD3"
        theme="dark"
        headers={["Sites", props.selectedColumn ?? "-"]}
        customColumns={["1fr", "150px"]}
        data={(()=>{
            if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: "white", size: "16px"}]];
            if (data.status !== "ok") return [[{keyID: "noData-error", type: "text", text: "Error while fetching data", color: "#FF450D"}]];

            let out = [];

            for (let item of data.data) {
                out.push([
                    {keyID: item.ID, type: "text", text: item.CampaignName},
                    {keyID: item.ID, type: "text", text: prepareTableData(item?.TableData?.[props.selectedColumn.replace(" ", "_")] ?? "-", props.selectedColumn)}
                ]);
            };

            if (out.length === 0) out.push([{keyID: "noData-noData", type: "text", text: "Nothing to show..."}]);

            return out;
        })()}
        style={{
            columnGap: "35px",
            height: "max-content"
        }}
    />
};

export default Dashboard;