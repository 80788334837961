import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";

import {animateBox} from "../../../modules/componentAnimation";
import { getFileBase64 } from "../../../modules/miscModule";
import { parseCSV } from "../../../modules/csvParserModule";
import useDefer from "../../../modules/hooks/useDefer";
import * as backendModule from "../../../modules/backendModule";

import Spinner from "../../customComponents/Spinner";
import Dropdown from "../../customComponents/Dropdown";
import StyledInput from "../../styledComponents/Input";
import StyledButton from "../../styledComponents/Button";
import FilterBySearch from "../../filters/FilterBySearch";
import { FilteredCustomTable } from "../../customComponents/Table";

import YesNoModal from "../YesNoModal";

const BulkDataChangeRequestModal = props => {
    const [finalData, setFinalData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    if (!finalData) return <BulkDataChangeRequestModal__1 theme={themeSelector} {...props} onClose={d => {
        if (d) return setFinalData(d);
        return props.onClose();
    }} />
    if (!finalData.updates) return <BulkDataChangeRequestModal__2 theme={themeSelector} {...props} data={finalData} onClose={d => {
        if (d) return setFinalData(d);
        return props.onClose();
    }} />
    return <BulkDataChangeRequestModal__3 theme={themeSelector} {...props} data={finalData} />
};

const BulkDataChangeRequestModal__1 = props => {
    const [selectedData, setSelectedData] = React.useState(props.selected ?? []);
    const [curDate, setCurDate] = React.useState(() => {
        if (props.date) {
            if (props.date?.start && props.date?.end) {
                let s = props.date.start.format("MM.DD.YYYY");
                let e = props.date.end.format("MM.DD.YYYY");

                if (s === e) {
                    return props.date.start.format("YYYY-MM-DD");
                };
            };
        };
    });
    const [curColumn, setCurColumn] = React.useState();

    const wrapRef = React.useRef();

    const onClose = (newData) => {
        if (wrapRef.current) {
            return wrapRef.current.animate([
                {right: getComputedStyle(wrapRef.current).right},
                {right: "-100%"}
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            }).onfinish = () => {
                props.onClose(newData);
            };
        };

        props.onClose();
    };

    const checkDate = () => {
        if (!curDate) return false;
        let tmp = moment(curDate);
        if (!tmp.isValid()) return false;
        if (tmp.isAfter(moment().endOf("day"))) return false;
        return true;
    };
    const openBulkEdit = (e) => {
        if (!checkDate() || !curColumn) return;

        let d = moment(curDate).format("DD.MM.YYYY");
        let c = curColumn;
        let s = null;
        if (selectedData.length > 0) s = [...selectedData];

        e.target.remove();
        onClose({
            date: d,
            column: c,
            selected: s,
            integrationID: props.integrationID
        });
    };

    React.useEffect(() => {
        if (!props.columns) return props.onClose();
        if (!Array.isArray(props.columns)) return props.onClose();
        if (props.columns.length === 0) return props.onClose();

        if (!wrapRef.current) return;

        wrapRef.current.animate([
            {right: getComputedStyle(wrapRef.current).right},
            {right: 0}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    return <div className="modals__bulkDataChange" onClick={() => onClose()}>
        <div className="modals__bulkDataChange__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>
            <div className="modals__bulkDataChange__wrap__top">
                <div className="modals__bulkDataChange__wrap__top__left">Bulk data change</div>
                <div className="modals__bulkDataChange__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="modals__bulkDataChange__wrap__content">
                <div className="modals__bulkDataChange__wrap__content__infoPanel">
                    <p>Please enter the required info below. The date that should be changed and what data is changing.</p>
                    <p>To request a data change, date must be at least a day before today. you CAN'T change the data for today!</p>
                </div>
                {selectedData.length > 0 && <div className="modals__bulkDataChange__wrap__content__infoPanel modals__bulkDataChange__wrap__content__infoPanel--red">
                    <p>You have {selectedData.length} campaigns selected and will only see those campaigns in the next step</p>
                    <p>If you want to edit all campaigns, please deselect them from the list before performing bulk change.</p>
                </div>}

                <div className="modals__bulkDataChange__wrap__content__input">
                    <StyledInput placeholder="Date" type="date" onChange={e => setCurDate(e?.target?.value)} value={curDate} />
                </div>
                <div className="modals__bulkDataChange__wrap__content__input">
                    <Dropdown
                        accent="#6C5DD3"
                        theme={props.theme}
                        inlinePlaceholder="Column to change"
                        data={props.columns.map(c => {
                            return {name: c?.name ?? c, value: c}
                        })}
                        onChange={e => setCurColumn(e?.value)}
                    />
                </div>
                <div className="modals__bulkDataChange__wrap__content__input">
                    <StyledButton isDisabled={!checkDate() || !curColumn} style={{width: "100%"}} onClick={openBulkEdit}>Proceed</StyledButton>
                </div>
            </div>
        </div>
    </div>
};

const BulkDataChangeRequestModal__2 = props => {
    const [campaigns, setCampaigns] = React.useState();
    const [timestamp, setTimestamp] = React.useState(Date.now());
    const [inputUpdate, setInputUpdate] = React.useState(1);
    const [search, setSearch] = React.useState("");

    const wrapRef = React.useRef();
    const updatedDataRef = React.useRef({});

    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const tsDefer = useDefer();

    const finalizeData = (e) => {
        let final = {
            ...props.data,
            updates: updatedDataRef.current
        };
        for (let key of Object.keys(final.updates)) {
            if (!final.updates[key]) delete final.updates[key];
        };

        if (Object.keys(final.updates).length === 0) return animateBox(e, <YesNoModal
            heading="Nothing to update"
            text="You havent added any data. Process can not continue"
            buttonLeftHidden={true}
            buttonRightText="Ok"
            isRightButtonNormal={true}
        />);

        return onClose(final);
    };

    const parseColumnType = () => {
        switch (props.data.column.value) {
            case "Spent_IN": return "number";
            case "Spent": return "number";
            default: return "text";
        };
    };

    const getOldValue = campaign => {
        let tmp = campaign.TableData[props.data.column.value];
        
        switch (props.data.column.value) {
            case "Spent":
            case "Spent_IN":
                tmp = Number(tmp);
                if (isNaN(tmp)) tmp = 0;
                tmp = tmp.toLocaleString();
                return `${tmp} ${currencySignSelector}`;
            default: return tmp;
        };
    };

    const getOldValueTotal = () => {
        if (!campaigns) return "?";
        if (campaigns.status !== "ok") return;

        let tmp = 0;
        for (let item of campaigns.data) {
            tmp += +item.TableData[props.data.column.value];
        };

        switch (props.data.column.value) {
            case "Spent":
            case "Spent_IN":
                tmp = Number(tmp);
                if (isNaN(tmp)) tmp = 0;
                tmp = tmp.toLocaleString();
                return `${tmp} ${currencySignSelector}`;
            default: return tmp;
        };
    };
    const getNewValueTotal = () => {
        let tmp = 0;
        for (let key of Object.keys(updatedDataRef.current)) {
            tmp += +updatedDataRef.current[key];
        };

        switch (props.data.column.value) {
            case "Spent":
            case "Spent_IN":
                tmp = Number(tmp);
                if (isNaN(tmp)) tmp = 0;
                tmp = tmp.toLocaleString();
                return `${tmp} ${currencySignSelector}`;
            default: return tmp;
        };
    };

    const performClose = e => {
        let curTarget = e.target;
        if (curTarget.nodeName === "IMG") curTarget = e.target.parentNode;
        animateBox({currentTarget: curTarget}, <YesNoModal
            heading="Are you sure?"
            text="If you close the window, all data you have entered will be lost!"
            isRightButtonNormal={true}
            buttonRightCallback={async (args) => {
                await args.close();
                onClose();
            }}
        />);
    };

    const onClose = (newData) => {
        if (wrapRef.current) {
            return wrapRef.current.animate([
                {right: getComputedStyle(wrapRef.current).right},
                {right: "-100%"}
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            }).onfinish = () => {
                props.onClose(newData);
            };
        };

        props.onClose();
    };

    const displayError = (heading, text) => {
        let tmp = document.querySelector(".modals__bulkDataChange__wrap__top__left");
        if (!tmp) return;
        
        animateBox({currentTarget: tmp}, <YesNoModal
            heading={heading}
            text={text}
            buttonLeftHidden={true}
            isRightButtonNormal={true}
            buttonRightText={"Ok"}
        />);
    };

    const importFromCSV = file => {
        if (!campaigns) return;
        if (campaigns.status === "error") return;

        let campaignFields = ["Ad Set Name", "Campaign Name"];
        let spentFields = ["Amount spent (EUR)"];

        if (!file) return;

        let fr = new FileReader();
        fr.onload = e => {
            parseCSV(e.target.result).then(csv => {
                if (!csv || !Array.isArray(csv)) return displayError("CSV parse error", "There was an error while parsing the CSV file. It is probably invalid.");

                let cField = null;
                let sField = null;

                let headings = csv.shift();
                headings.forEach((h, hIdx) => {
                    if (campaignFields.includes(h)) cField = hIdx;
                    if (spentFields.includes(h)) sField = hIdx;
                });

                if (cField === null) return displayError("Campaign field not found", `Campaign field was not found in this CSV file. Searched fields: ${campaignFields.join(", ")}`);
                if (sField === null) return displayError("Spent field not found", `Spent field was not found int this CSV file. Searched fields: ${spentFields.join(", ")}`);

                let mutations = {};
                for (let item of csv) {
                    // do the reading now
                    if (!item?.[cField] || !item?.[sField]) continue;
                    let cName = item[cField];
                    if (!cName) continue;
                    let sValue = Number(item[sField]);
                    if (isNaN(sValue)) continue;

                    let potentialID = cName.split(" ").pop().trim();
                    if (potentialID.endsWith(".")) potentialID = potentialID.substring(0, potentialID.length-1);
                    potentialID = Number(potentialID);
                    if (isNaN(potentialID) || !potentialID) continue;

                    for (let campaign of campaigns.data) {
                        if (campaign.RollingID == potentialID) {
                            if (!mutations[campaign.ID]) mutations[campaign.ID] = 0;
                            mutations[campaign.ID] += sValue;

                            break;
                        };
                    };
                };

                if (Object.keys(mutations).length === 0) return displayError("Done", `Found 0 campaigns.`);

                for (let key of Object.keys(mutations)) {
                    updatedDataRef.current[key] = mutations[key];
                };
                setInputUpdate(i => i+=1);
                console.log(mutations)
                return displayError("Done", `Found ${Object.keys(mutations).length} campaigns.`);
            }).catch(() => {
                displayError("CSV Read error", "There was an error while reading the file as a CSV!");
            });
        };
        fr.readAsText(file);
    };

    React.useEffect(() => {
        let filters = [
            {name: "IntegrationID", op: "eq", value: props.data.integrationID}
        ].filter(t => t);
        if (props.data.selected) filters.push({name: "ID", op: "in", value: props.data.selected});

        let curDate = moment(props.data.date, "DD.MM.YYYY");
        let trackFilters = [
            {name: "createdAt", op: "pdgeq", value: curDate.startOf("day").toDate().getTime()},
            {name: "createdAt", op: "pdleq", value: curDate.endOf("day").toDate().getTime()}
        ];

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
            data: {
                IntegrationID: props.data.integrationID,
                limit: null,
                offset: 0,
                filters,
                TableHeaders: [props.data.column.value],
                orders: [{name: "CampaignName", order: "asc"}],
                trackFilters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setCampaigns(res.data);
        }).catch(() => {
            setCampaigns(backendModule.genericError);
        });
    }, []);

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            {right: getComputedStyle(wrapRef.current).right},
            {right: 0}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    return <div className="modals__bulkDataChange" onClick={(e) => performClose(e)}>
        <div className="modals__bulkDataChange__wrap" style={{width: "calc(100% - 84px)"}} ref={wrapRef} onClick={e => e?.stopPropagation()}>
            <div className="modals__bulkDataChange__wrap__top">
                <div className="modals__bulkDataChange__wrap__top__left">Bulk data change for {(moment(props.data.date, "DD.MM.YYYY")).toDate().toLocaleDateString()} ({currencySignSelector})</div>

                <StyledButton style={{marginRight: "10px", marginLeft: "auto", height: "30px"}} isSecondary={true} onClick={(e) => {
                    e.target.parentNode.parentNode.querySelector("input[type=file]").click();
                }}>Import from CSV</StyledButton>
                <StyledButton style={{marginRight: "20px", marginLeft: "10px", height: "30px"}} onClick={finalizeData}>Continue</StyledButton>

                <div className="modals__bulkDataChange__wrap__top__right">
                    <img src="/images/icon_close.svg" onClick={(e) => performClose(e)} />
                </div>

                <input type="file" accept="text/csv" style={{display: "none"}} onChange={e => importFromCSV(e.target.files[0] ?? null)} />
            </div>

            <div className="modals__bulkDataChange__wrap__content">
                {campaigns ? <>
                    {(campaigns.status === "ok" && campaigns.data.length > 0) ? <>
                        <FilterBySearch onChange={e => setSearch(e)} style={{marginBottom: "20px"}} />
                        <FilteredCustomTable
                            theme={props.theme}
                            headers={["Campaign name", `Current ${props.data.column?.name}`, props.data.column?.name ?? "-"]}
                            customColumns={["max-content", "max-content", "300px"]}
                            style={{width: "100px", columnGap: "20px"}}
                            data={[...campaigns.data.map(c => {
                                if (search) {
                                    let keywords = search.split(" ");
                                    for (let item of keywords) {
                                        if (!c.CampaignName.toLowerCase().includes(item.toLowerCase())) return null;
                                    };
                                };
                                return [
                                    {keyID: c.ID, type: "text", text: <p><span style={{cursor: "pointer", color: "#5A49CE"}} onClick={() => navigator.clipboard.writeText(c.RollingID)}>[{c.RollingID}]</span>&nbsp;{c.CampaignName}</p>},
                                    {keyID: c.ID, type: "text", text: getOldValue(c)},
                                    {keyID: c.ID, type: "custom", data: <StyledInput key={`${c.ID}-${inputUpdate}`} defaultValue={updatedDataRef.current[c.ID]} type={parseColumnType()} placeholder={`New ${props.data.column?.name} (blank for no change)`} onChange={e => {
                                        if (!e.target.value) {
                                            delete updatedDataRef.current[c.ID];
                                        } else {
                                            updatedDataRef.current[c.ID] = e.target.value;
                                        };
                                        tsDefer(() => setTimestamp(Date.now()), 2000);
                                    }} />}
                                ]
                            }).filter(t => t), [
                                {keyID: "total-1", type: "text", text: "Total", isFooter: true},
                                {keyID: "total-1", type: "text", text: getOldValueTotal(), isFooter: true},
                                {keyID: "total-1", type: "text", text: getNewValueTotal(), isFooter: true}

                            ]]}
                        />
                    </> : <p>There was an error while fetching data!</p>}
                </> : <>
                    <p>Fetching data, please wait...</p><br/>
                    <Spinner style={{width: "32px", height: "32px"}} color="white" />
                </>}
            </div>
        </div>
    </div>
};

const BulkDataChangeRequestModal__3 = props => {
    const [curState, setCurState] = React.useState(1);
    const [timer, setTimer] = React.useState(5);
    const [file, setFile] = React.useState();

    const wrapRef = React.useRef();
    const contentRef = React.useRef();

    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const changeState = s => {
        if (curState === s) return;

        if (!contentRef.current) return setCurState(s);

        contentRef.current.animate([
            {opacity: getComputedStyle(contentRef.current)},
            {opacity: 0}
        ], {duration: 150, iterations: 1, fill: "both", easing: "linear"}).onfinish = () => {
            setCurState(s);
        };
    };

    const hasSums = () => {
        switch (props.data.column.value) {
            case "Spent":
            case "Spent_IN":
                let tspent = Object.keys(props.data.updates).reduce((acc, val) => isNaN(props.data.updates[val]) ? acc : acc + +props.data.updates[val], 0);
                return `${Number(tspent.toFixed(2)).toLocaleString()} ${currencySignSelector}`
            default: return [];
        };
    };

    const performClose = e => {
        let curTarget = e.target;
        if (curTarget.nodeName === "IMG") curTarget = e.target.parentNode;
        animateBox({currentTarget: curTarget}, <YesNoModal
            heading="Are you sure?"
            text="If you close the window, all data you have entered will be lost!"
            isRightButtonNormal={true}
            buttonRightCallback={async (args) => {
                await args.close();
                onClose();
            }}
        />);
    };

    const onClose = (newData) => {
        if (wrapRef.current) {
            return wrapRef.current.animate([
                {right: getComputedStyle(wrapRef.current).right},
                {right: "-100%"}
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            }).onfinish = () => {
                if (props.onChange) props.onChange();
                props.onClose(newData);
            };
        };

        if (props.onChange) props.onChange();
        props.onClose();
    };

    const updateSpentVar = async () => {
        let tmpDate = props.data.date;

        let curVar = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/vars/getVar`,
            data: {
                Name: `user_spent_change_${props.integrationID}`
            },
            ...backendModule.axiosConfig
        }).then(res => res.data).catch(() => backendModule.genericError);

        await axios({
            method: "POST",
            url: `${backendModule.backendURL}/approvedSpendsPerAccount/resetSpentStatus`,data: {
                IntegrationID: props.integrationID,
                Date: moment(tmpDate, "DD.MM.YYYY").toDate().getTime()
            },
            ...backendModule.axiosConfig
        }).catch(() => null);

        
        if (
            moment(tmpDate, "DD.MM.YYYY").format("DD.MM.YYYY") === moment().format("DD.MM.YYYY") ||
            moment(tmpDate, "DD.MM.YYYY").format("DD.MM.YYYY") === moment().add(-1, "days").format("DD.MM.YYYY")
        ) {
            tmpDate = moment().format("DD.MM.YYYY HH:mm");
        } else {
            tmpDate += " 23:50"
        };

        if (curVar.status === "ok") {
            if (moment(curVar.data, "DD.MM.YYYY HH:mm").isAfter(moment(tmpDate, "DD.MM.YYYY HH:mm"))) return;
        };

        await axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/vars/addVar`,
            data: {
                Name: `user_spent_change_${props.integrationID}`,
                Value: moment(tmpDate, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY HH:mm")
            },
            ...backendModule.axiosConfig
        }).catch(() => null);
        return;
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            {right: getComputedStyle(wrapRef.current).right},
            {right: 0}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        if (!file) return;
        if (timer > 0) setTimeout(() => setTimer(t => t-1), 1000);
    }, [timer, file]);

    React.useEffect(() => {
        (async ()=>{
            if (contentRef.current) {
                contentRef.current.animate([
                    {opacity: getComputedStyle(contentRef.current)},
                    {opacity: 1}
                ], {duration: 150, iterations: 1, fill: "both", easing: "linear"});
            };
            if (curState !== 2) return;
    
            let finalData = {
                IntegrationID: props.data.integrationID,
                Data: [],
                utcOffset: (new Date()).getTimezoneOffset()
            };
            let ForDate = moment(props.data.date, "DD.MM.YYYY").toDate().getTime();
            for (let key of Object.keys(props.data.updates)) {
                finalData.Data.push({
                    CampaignID: key,
                    EntityID: "all",
                    Column: props.data.column.value,
                    NewData: props.data.updates[key],
                    ForDate
                });
            };
            let imgBuff = await getFileBase64(file);
            finalData["ImageBuffer"] = imgBuff;
            finalData["ImageName"] = file.name;
    
            console.log(finalData)
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/dataUpdateRequest/addBulkRequest`,
                data: finalData,
                ...backendModule.axiosConfig
            }).then(async res => {
                await updateSpentVar();
                if (res.data.status === "ok") return changeState(3);
                return changeState(4);
            }).catch(() => {
                return changeState(4);
            });
        })()
    }, [curState]);

    return <div className="modals__bulkDataChange" onClick={(e) => performClose(e)}>
        <div className="modals__bulkDataChange__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>
            <div className="modals__bulkDataChange__wrap__top">
                <div className="modals__bulkDataChange__wrap__top__left">Bulk data change</div>
                <div className="modals__bulkDataChange__wrap__top__right"><img src="/images/icon_close.svg" onClick={(e) => performClose(e)} /></div>
            </div>

            <div className="modals__bulkDataChange__wrap__content" ref={contentRef}>
                {curState === 1 && <>
                    <div className="modals__bulkDataChange__wrap__content__infoPanel">
                        <p>You are almost done, review your info below just to be safe, before you send the update request.</p>
                    </div>

                    <div className="modals__bulkDataChange__wrap__content__inputFinal">
                        <p>Date: <span>{moment(props.data.date, "DD.MM.YYYY").toDate().toLocaleDateString()}</span></p>
                        <p>Account ID: <span>{props.data.integrationID}</span></p>
                        <p>Column: <span>{props.data.column.name}</span></p>
                        <p>Campaigns to update: <span>{Object.keys(props.data.updates).length}</span></p>
                        {hasSums() ? <p>Total sum: <span>{hasSums()}</span></p> : null}
                    </div>

                    <div className="modals__bulkDataChange__wrap__content__input" style={{marginTop: "20px"}}>
                        <StyledButton style={{width: "100%"}} onClick={e => e.target.parentNode.querySelector("input[type=file]").click()}>Select proof of spend{file ? ` (${file?.name})` : ""}</StyledButton>
                        <input type="file" accept="image/*" style={{display: "none"}} onChange={e => setFile(e.target.files[0] ?? null)} />
                    </div>
                    <div className="modals__bulkDataChange__wrap__content__input" style={{marginTop: "20px"}}>
                        <StyledButton style={{width: "100%"}} isDisabled={timer > 0 || !file} onClick={e => changeState(2)}>Finish {file ? (timer > 0 ? `(${timer})` : "") : "(missing proof)"}</StyledButton>
                    </div>
                </>}

                {curState === 2 && <>
                    <p>Finishing up, this might take a moment...</p>
                    <Spinner style={{width: "32px", height: "32px"}} color="white" />
                </>}
                {curState === 3 && <>
                    <p>Done, You can now close this window.</p>
                    <br />
                    <StyledButton style={{width: "100%"}} onClick={() => onClose()}>Close</StyledButton>
                </>}
                {curState === 4 && <>
                    <p>There were errors while sending the request, please try again later!</p>
                    <br />
                    <StyledButton style={{width: "100%"}} onClick={() => onClose()}>Close</StyledButton>
                </>}
            </div>
        </div>
    </div>
};

export default BulkDataChangeRequestModal;