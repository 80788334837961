import React from "react";
import "./index.scss";

import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


import moment from "moment";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import * as siteFunctionsActions from "../../actions/siteFunctionsActions";
import animateModule from "../../modules/animateModule";
import { animateBox } from "../../modules/componentAnimation"
import useDefer from "../../modules/hooks/useDefer";
import { selectTrackingProfile, selectTrackingTeam } from "../../actions/trackingProfilesActions";
import { updateTimestamp } from "../../actions/timestampActions";

import { ReactComponent as ModalMyProfile_img } from "./images/header_usermodal_myProfile.svg";
import { ReactComponent as ModalChangePassword_img } from "./images/header_usermodal_changePassword.svg";
import { ReactComponent as ModalLogout_img } from "./images/header_usermodal_logout.svg";
import { ReactComponent as Calculator_img } from "./images/header_usermodal_calculator.svg";

import { ReactComponent as ThemeLight_img } from "./images/header_theme_light.svg";
import { ReactComponent as ThemeDark_img } from "./images/header_theme_dark.svg";

import ChangePassword from "./ChangePassword";
import { BonusCalculator } from "../../routes/user/reports/Marketing";
import AdvancedDropdown from "../customComponents/AdvancedDropdown";
import Spinner from "../customComponents/Spinner";
import YesNoModal from "../modals/YesNoModal";
import StyledButton from "../styledComponents/Button";
import Checkbox from "../customComponents/Checkbox";

const Header = (props) => {
    const [userModalVisible, setUserModalVisible] = React.useState(false);
    const [dropdownVisible, setdropdownVisible] = React.useState(false);
    const [trackingProfiles, setTrackingProfiles] = React.useState();
    const [submenu, setSubmenu] = React.useState(false);
    const [selectedProfiles, setSelectedProfiles] = React.useState();
    const [selectedGroup, setSelectedGroup] = React.useState();
    const [searchGroup, setSearchGroup] = React.useState();
    const [notifications, setNotifications] = React.useState([]);
    const [unreadNotifications, setUnreadNotifications] = React.useState([]);
    const [notificationsMenu, setNotificationsMenu] = React.useState(false);

    const dropdownRef = React.useRef();
    const accountBtn = React.useRef();
    const accountArrow = React.useRef();
    let maxHeightValue = React.useRef();
    const searchRef = React.useRef();

    const curLocation = useLocation();

    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});
    const currentTrackingProfiles = useSelector(state => state?.trackingProfiles ?? {});
    const refreshClickHandlers = useSelector(state => state?.siteFunctions?.headerRefreshHandlers ?? []);
    const siteFunctionsStickyHeaderSelector = useSelector(state => state?.siteFunctions?.stickyHeader ?? true);
    const siteFunctionsHeaderComponentsSelector = useSelector(state => state?.siteFunctions?.headerComponents ?? []);
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const mainNavigate = useNavigate();

    const animateNavigate = to => {
        animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));
    };



    React.useEffect(() => {
        setTrackingProfiles();
        if (!currentTrackingProfiles) return;
        let profilestmp = {};
        currentTrackingProfiles.profiles.map(item => {
            if (!profilestmp[item.ID.slice(0, 2)]) {
                profilestmp[item.ID.slice(0, 2)] = {
                    type: item.type,
                    profiles: [
                        {
                            ID: item.ID,
                            name: item.name,
                            banned: !!item.banned
                        }
                    ]
                }
            } else {
                profilestmp[item.ID.slice(0, 2)].profiles.push({
                    ID: item.ID,
                    name: item.name,
                    banned: !!item.banned
                })
            }
            setTrackingProfiles(profilestmp)
        })


    }, [currentTrackingProfiles])

    React.useEffect(() => {
        if (!props.notifications) return;
        let unread = [];
        let read = [];
        for (let not of props.notifications.data) {
            if (!not.Read) {
                unread.push(not);
            } else {
                read.push(not);
            }
        }
        setUnreadNotifications(unread);
        setNotifications(read);
    }, [props.notifications])

    const mainDispatch = useDispatch();

    const getIntegration = integrationID => {
        let curProfile = currentTrackingProfiles?.profiles?.find(p => p.ID === integrationID);
        
        return curProfile ?? null;
    };
    const formatIntegrationName = name => {
        if (name) {
            if (name.startsWith("(Personal)")) {
                name = name.split("(Personal)").pop();
                return <>
                    <span style={{color: "rgb(69, 168, 255)"}} title="Personal account">(P)</span>
                    {name}
                </>
            };
        };
        return name;
    };

    const getIntegrationImage = (integrationID) => {
        let curIntegration = getIntegration(integrationID);

        switch (curIntegration?.type) {
            case "scale-track": return "/images/logo.svg";
            case "facebook": return "/images/integrations/integration_header_facebook.svg";
            case "mgid": return "/images/integrations/integration_header_mgid.svg";
            case "midas": return "/images/integrations/integration_midas.svg";
            case "scale-track-sms": return "/images/integrations/integration_smsMarketing.svg";
            case "scale-track-social": return "/images/integrations/integration_socialCampaigns.svg";
            default: return "#";
        };
    };

    const getHeaderName = () => {
        if (curLocation.pathname === "/") return userDataSelector?.UserInfo?.Flags?.isSocialMediaManager ? "Campaigns" : "Dashboard";
        if (curLocation.pathname.startsWith("/campaigns")) return "Campaigns";
        if (curLocation.pathname.startsWith("/optimize")) return "Optimize";
        if (curLocation.pathname.startsWith("/reports")) return "Reports";
        if (curLocation.pathname.startsWith("/creatives")) return "Creatives";
        if (curLocation.pathname.startsWith("/sites")) return "Sites";
        if (curLocation.pathname.startsWith("/heatmaps")) return "Heatmaps";
        if (curLocation.pathname.startsWith("/accountBalances")) return "Account balances";
        if (curLocation.pathname.startsWith("/integrations")) return "Integrations";
        if (curLocation.pathname.startsWith("/smsMarketing")) return "SMS Marketing";
        if (curLocation.pathname.startsWith("/offers")) return "Offers";

        // Team leader
        if (curLocation.pathname.startsWith("/team-usergroups")) return "[Team] User groups";
        if (curLocation.pathname.startsWith("/team-users")) return "[Team] Users";

        // Admin
        if (curLocation.pathname.startsWith("/admin-users")) return "[Admin] Users";
        if (curLocation.pathname.startsWith("/admin-apiTokens")) return "[Admin] API tokens";
        if (curLocation.pathname.startsWith("/admin-globalIntegrations")) return "[Admin] Global integrations";
        if (curLocation.pathname.startsWith("/admin-dataChange")) return "[Admin] Data change requests";
        if (curLocation.pathname.startsWith("/admin-trackEvents")) return "[Admin] Track events";
    };

    React.useEffect(() => {
        if (!dropdownRef?.current) return;

        maxHeightValue.current = currentTrackingProfiles.profiles.length * 60;
        if (currentTrackingProfiles.selectedProfile !== -1) {
            maxHeightValue.current += 61;
            if (submenu) setSubmenu(false);
        };
    }, [dropdownRef.current, currentTrackingProfiles]);

    React.useEffect(() => {
        const closeDropdown = () => {
            if (submenu) return;
            if (dropdownVisible) setdropdownVisible(false);
            if (notificationsMenu) setNotificationsMenu(false);
        };

        window.addEventListener("click", closeDropdown);
        return () => {
            try {
                window.removeEventListener("click", closeDropdown);
            } catch { };
        };
    }, [dropdownVisible, notificationsMenu]);

    React.useEffect(() => {
        setSearchGroup(selectedProfiles);
    }, [selectedProfiles]);

    React.useEffect(() => {
        if (!notificationsMenu) return;
        if (unreadNotifications.length === 0) return;
        for (let not of unreadNotifications) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/notifications/updateNotification`,
                data: {
                    ID: not.ID
                },
                ...backendModule.axiosConfig
            }).then(() => {
                if (notificationsMenu === false) {
                    setUnreadNotifications([]);
                    setNotifications(props.notifications.data)
                }

            }).catch(() => {
                return null;
            })
        }

    }, [notificationsMenu]);

    const searchProfiles = () => {
        let tmp = [];
        for (let profile of selectedProfiles.profiles) {
            if (profile.ID.toLowerCase().includes(searchRef.current.value.toLowerCase()) || profile.name.toLowerCase().includes(searchRef.current.value.toLowerCase())) {
                tmp.push(profile)
            }
        }
        setSearchGroup({ type: selectedProfiles.type, profiles: tmp });
    }

    const getButtonText = (elem, e) => {

        switch (elem.Action) {
            case 'open-sites':
                return <button onClick={(e) => {
                    animateNavigate('/sites');
                    setNotificationsMenu(false);
                }}>Go to sites page</button>
            case 'open-creatives':
                return <button onClick={(e) => {
                    animateNavigate('/creatives');
                    setNotificationsMenu(false);
                }}>Go to creatives page</button>
            case 'change-password':
                return <button onClick={(e) => {
                    animateBox(e, <ChangePassword />);
                    setNotificationsMenu(false);
                }}>Change password</button>
            case 'open-integrations':
                return <button onClick={(e) => {
                    animateNavigate('/integrations');
                    setNotificationsMenu(false);
                }}>Open integrations</button>
            case "download-report":
                return <button onClick={e => {
                    window.open(`${backendModule.backendURL}/staticContent/reports/${elem.AdditionalData?.ReportFile}`, "_blank");
                }}>Download report</button>
        }

    }

    const checkIfGenericProfile = id => {
        switch (id) {
            case "-1":
            case "-2":
            case "-3":
            case "mg":
                return true;
            default:
                return false;
        };
    };

    const checkIfHasBalance = () => {
        let tmp = currentTrackingProfiles?.selectedProfile;
        if (!tmp) return false;

        if (tmp.startsWith("fb-")) return true;
        if (tmp.startsWith("mg-")) return true;

        return false;
    }

    return <div className="component__header" style={{
        position: siteFunctionsStickyHeaderSelector ? null : "initial"
    }}>
        <div className="component__header__left">
            <div className="component__header__left__hamburger">
                <div className={`component__header__left__hamburger__box`} onClick={props.toggleSidebar()}>
                    <div className={`component__header__left__hamburger__box__line component__header__left__hamburger__box__line--first`}></div>
                    <div className={`component__header__left__hamburger__box__line component__header__left__hamburger__box__line--middle`}></div>
                    <div className={`component__header__left__hamburger__box__line component__header__left__hamburger__box__line--last`}></div>
                </div>
            </div>
            {(Array.isArray(siteFunctionsHeaderComponentsSelector) && siteFunctionsHeaderComponentsSelector.length > 0) ? <div className="component__header__left__customComponents">
                {siteFunctionsHeaderComponentsSelector}
            </div> : getHeaderName()}
        </div>

        <div className="component__header__right">
            <div className="component__header__right__account" ref={accountBtn} onClick={e => {
                e.stopPropagation();
                setdropdownVisible(pastState => !pastState)
            }} style={{
                display: userDataSelector?.UserInfo?.Flags?.isSocialMediaManager ? "none" : null
            }}>
                <img className="component__header__right__account__img" src="/images/header_account.svg" />
                <div className="component__header__right__account__user">
                    <p className="component__header__right__account__user__bottom">
                        {currentTrackingProfiles?.selectedProfile === undefined ? <Spinner style={{ width: "16px", height: "16px" }} color="white" /> : <>
                            {currentTrackingProfiles.selectedProfile === null ? <p>Error!</p> : <>
                                <img src={getIntegrationImage(currentTrackingProfiles.selectedProfile)} />
                                <p>{formatIntegrationName(getIntegration(currentTrackingProfiles?.selectedProfile)?.name ?? "?")}</p>
                            </>}
                        </>}
                    </p>
                </div>
                <div className="component__header__right__account__arrow" ref={accountArrow} style={{
                    backgroundImage: 'url("/images/downArrow.svg")',
                    transform: dropdownVisible ? "rotate(180deg)" : null
                }}></div>
                <div className={`component__header__right__account__dropdown ${dropdownVisible ? "component__header__right__account__dropdown--active" : ""} ${submenu === true ? "component__header__right__account__dropdown--custom" : ""}`} ref={dropdownRef} style={{
                    maxHeight: dropdownVisible ? `${maxHeightValue.current > 600 ? 600 : maxHeightValue.current}px` : 0
                }}>
                    <div className="component__header__right__account__dropdown__left" style={{
                        width: submenu ? '50%' : '100%',
                        transition: "all 0.3s ease"
                    }} >
                        {
                            trackingProfiles && Object.keys(trackingProfiles).map(profile => {
                                return <p
                                    className={selectedGroup === profile ? "component__header__right__account__dropdown__active" : null}
                                    onClick={(e) => {
                                        if (checkIfGenericProfile(profile)) {
                                            mainDispatch(selectTrackingProfile(trackingProfiles[profile].profiles[0].ID));
                                            setSelectedProfiles(null);
                                            setSubmenu(false);
                                        } else {
                                            e.stopPropagation();
                                            setSelectedProfiles(trackingProfiles[profile]);
                                            setSubmenu(true)
                                        }
                                        setSelectedGroup(profile);
                                    }}

                                >
                                    <img src={getIntegrationImage(trackingProfiles[profile].profiles[0].ID)} />
                                    {checkIfGenericProfile(profile) ? trackingProfiles[profile].profiles[0].name : trackingProfiles[profile].type}
                                </p>
                            })
                        }
                    </div>
                    <div className="component__header__right__account__dropdown__right">
                        <input onClick={e => {
                            e.stopPropagation();
                        }} placeholder="Search" ref={searchRef} onChange={() => { searchProfiles() }} className="component__header__right__account__dropdown__right__search" />
                        {searchGroup && searchGroup.profiles.map(profile => {
                            let profileID = profile.ID.split('-')[1];
                            checkIfGenericProfile(profile) ? profileID = '' : profileID = profileID;
                            return <p
                                className={currentTrackingProfiles.selectedProfile === profile.ID ? "component__header__right__account__dropdown__active" : null}
                                onClick={() => mainDispatch(selectTrackingProfile(profile.ID))}
                                style={{
                                    color: profile.banned ? "rgb(238, 102, 102)" : null
                                }}
                            >
                                <span style={{ left: "10px" }} >{profileID}</span>
                                {profile.name}
                            </p>
                        })}
                    </div>
                </div>
            </div>

            {checkIfHasBalance() && <ProfileBalance integrationID={currentTrackingProfiles?.selectedProfile} />}
            <CheckProofUploaded integrationID={currentTrackingProfiles?.selectedProfile} />

            <div
                className="component__header__right__icon"
                style={{ backgroundImage: 'url("/images/header_refresh.svg")' }}
                onClick={() => refreshClickHandlers.forEach(i => i())}
            />

            <div
                className="component__header__right__icon"
                style={{ backgroundImage: 'url("/images/header_notifications.svg")' }} onClick={(e) => { setNotificationsMenu(!notificationsMenu); e?.stopPropagation(); }}
            >
                <spam style={{ display: unreadNotifications.length > 0 ? 'flex' : 'none' }}>{unreadNotifications.length}</spam>

                <div className={notificationsMenu ? `component__header__right__icon__notMenu component__header__right__icon__notMenu-active ` : 'component__header__right__icon__notMenu'} onClick={e => e?.stopPropagation()}>
                    <p className="component__header__right__icon__notMenu__head">Recent notifications</p>
                    <div className="component__header__right__icon__notMenu__single">
                        {unreadNotifications && <p className="component__header__right__icon__notMenu__single__head">
                            Unread ({unreadNotifications.length})</p>}
                        {unreadNotifications && unreadNotifications.map(item => {
                            return <div className={`component__header__right__icon__notMenu__single__notification ${item.Type === "error" ? "component__header__right__icon__notMenu__single__notification--error" : ""}`}>
                                <p className="component__header__right__icon__notMenu__single__notification__name">
                                    {item.Type}
                                </p>
                                <p className="component__header__right__icon__notMenu__single__notification__content">
                                    {item.Content}
                                </p>
                                <p className="component__header__right__icon__notMenu__single__notification__time">
                                    {moment(new Date()).diff(moment(item.createdAt), 'minutes') < 60 ? moment(new Date()).diff(moment(item.createdAt), 'minutes') + 'min' :
                                        moment(new Date()).diff(moment(item.createdAt), 'hours') < 24 ? moment(new Date()).diff(moment(item.createdAt), 'hours') + 'h' :
                                            moment(new Date()).diff(moment(item.createdAt), 'months') < 12 ? moment(new Date()).diff(moment(item.createdAt), 'months') + 'months' :
                                                moment(new Date()).diff(moment(item.createdAt), 'years') + "y"
                                    }
                                </p>
                                {getButtonText(item)}
                            </div>
                        })}

                    </div>
                    <div className="component__header__right__icon__notMenu__single">
                        {notifications && <p className="component__header__right__icon__notMenu__single__head">
                            Older ({notifications.length})</p>}
                        {notifications && notifications.map(item => {
                            return <div className={`component__header__right__icon__notMenu__single__notification ${item.Type === "error" ? "component__header__right__icon__notMenu__single__notification--error" : ""}`}>
                                <p className="component__header__right__icon__notMenu__single__notification__name">
                                    {item.Type}
                                </p>
                                <p className="component__header__right__icon__notMenu__single__notification__content">
                                    {item.Content}
                                </p>
                                <p className="component__header__right__icon__notMenu__single__notification__time">
                                    {moment(new Date()).diff(moment(item.createdAt), 'minutes') < 60 ? moment(new Date()).diff(moment(item.createdAt), 'minutes') + 'min' :
                                        moment(new Date()).diff(moment(item.createdAt), 'hours') < 24 ? moment(new Date()).diff(moment(item.createdAt), 'hours') + 'h' :
                                            moment(new Date()).diff(moment(item.createdAt), 'months') < 12 ? moment(new Date()).diff(moment(item.createdAt), 'months') + 'months' :
                                                moment(new Date()).diff(moment(item.createdAt), 'years') + "y"
                                    }
                                </p>
                                {getButtonText(item)}
                            </div>
                        })}

                    </div>

                </div>
            </div>

            <div
                className="component__header__right__icon"
                style={{ backgroundImage: 'url("/images/header_user.svg")' }}
                onClick={(e) => {
                    e.stopPropagation();
                    setUserModalVisible(m => !m);
                }}
            />

        </div>

        <UserModal
            visible={userModalVisible}
            setVisible={setUserModalVisible}

            userDataSelector={userDataSelector}
            currentTrackingProfiles={currentTrackingProfiles}
            setSelectedGroup={setSelectedGroup}
            setSearchGroup={setSearchGroup}
            setSelectedProfiles={setSelectedProfiles}
            selectTrackingTeam={selectTrackingTeam}
        />
    </div>
};

const UserModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [overflow, setOverflow] = React.useState();

    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const curDispatch = useDispatch();
    const timerRef = React.useRef();

    const changeTheme = async () => {
        await axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/vars/addVar`,
            data: {
                Name: "user_theme",
                Value: themeSelector === "dark" ? "light" : "dark"
            },
            ...backendModule.axiosConfig
        }).then((r) => {
            if (r.data.status === "ok") {
                // 1. create a style
                let s = document.createElement("style");
                s.innerHTML = `* {transition: all 0.3s ease !important;}`;
                document.head.appendChild(s);

                // 2. update theme
                curDispatch(siteFunctionsActions.changeSiteTheme(themeSelector === "dark" ? "light" : "dark"));

                // 5. after 1s remove style
                setTimeout(() => s.remove(), 1000);

                props.setVisible(false);
            };
        }).catch(() => null);
    };

    const performLogout = () => {
        setSpinner(true);

        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            ...backendModule.axiosConfig
        }).then(() => null).catch(() => null).finally(() => {
            curDispatch(siteFunctionsActions.changeSiteTheme("dark"));
            setSpinner(false);
            curDispatch(updateTimestamp());
        });
    };

    const performChangePassword = (e) => {
        setSpinner(true);
        animateBox(e, <ChangePassword onChange={() => setSpinner(false)} />)
    };

    const openCalculator = e => {
        animateBox(e, <BonusCalculator />);
    };

    React.useEffect(() => {
        const handler = () => {
            if (props.visible) props.setVisible(false);
        };

        document.body.addEventListener("click", handler);

        let sidebar = document.querySelector(".component__sidebar");
        if (sidebar) {
            sidebar.addEventListener("mouseleave", handler);
        };

        return () => {
            document.removeEventListener("click", handler);

            try {
                sidebar.removeEventListener("mouseleave", handler);
            } catch {};
        };
    }, [props.visible]);

    React.useEffect(() => {
        if (props.visible) {
            if (!timerRef.current) timerRef.current = setTimeout(() => {
                setOverflow(true);
            }, 500);
        } else {
            clearTimeout(timerRef.current);
            timerRef.current = null;
            setOverflow(false);
        };
    }, [props.visible]);

    return <div className={`component__header__userModal ${props.visible ? "component__header__userModal--active" : ""} ${props.className ?? ""}`} onClick={e => {
        e.stopPropagation();
    }} style={{overflow: overflow ? "visible" : "hidden", ...(props.style ?? {})}}>
        <div className={`component__header__userModal__spinner ${spinner ? "component__header__userModal__spinner--active" : ""}`}>
            <Spinner style={{ width: "64px", height: "64px" }} color={themeSelector === "dark" ? "white" : "black"} />
        </div>

        <div className="component__header__userModal__first">
            <img src="/images/header_usermodal_img.svg" />
            <p>{userDataSelector?.UserInfo?.Username}</p>
        </div>

        <AdvancedDropdown
            style={{
                display: props.userDataSelector?.UserInfo?.Flags?.isSocialMediaManager ? "none" : null
            }}
            headline="Selected team"
            data={(props.currentTrackingProfiles?.teams || []).map(team => {
                return { name: team.GroupName, value: team.ID };
            })}
            showSearch={true}
            selected={(() => {
                if (!props.currentTrackingProfiles.selectedTeam) return null;
                return props.currentTrackingProfiles.teams?.indexOf?.(props.currentTrackingProfiles.teams?.find?.(t => t.ID === props.currentTrackingProfiles.selectedTeam))
            })()}
            onChange={e => {
                    props.setSelectedGroup(null);
                    props.setSearchGroup(null);
                    props.setSelectedProfiles(null);
                    curDispatch(props.selectTrackingTeam(e?.value));
            }}
        />

        <div className="component__header__userModal__button" onClick={changeTheme}>
            {themeSelector === "light" ? <ThemeDark_img /> : <ThemeLight_img />}
            <p>{themeSelector === "dark" ? "Light": "Dark"} theme</p>
        </div>
        <div className="component__header__userModal__button" onClick={performChangePassword}>
            <ModalChangePassword_img />
            <p>Change password</p>
        </div>
        {/* <div className="component__header__userModal__button" onClick={openCalculator}>
            <Calculator_img />
            <p>Calculator</p>
        </div> */}
        <div className="component__header__userModal__button" onClick={performLogout}>
            <ModalLogout_img />
            <p>Logout</p>
        </div>
    </div>
};

const ProfileBalance = () => {
    const [data, setData] = React.useState();

    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");
    const selectedTrackingProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? "-1");
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");

    const greenColor = themeSelector === "dark" ? "#79c986" : "green";
    const redColor = themeSelector === "dark" ? "#c97979" : "red";

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/balances/getBalance`,
            data: {
                IntegrationID: selectedTrackingProfileSelector
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => {
            setData(backendModule.genericError);
        });
    }, [selectedTrackingProfileSelector]);

    return <div className="component__header__right__balance" style={{color: data?.data > 0 ? greenColor : redColor}}>
        {data ? <>
            {data.status === "ok" ? `${data.data} ${currencySignSelector}` : "?"}
        </> : <Spinner style={{width: "17px", height: "17px"}} color="white" />}
    </div>
};

let notifiedAccounts = [];
const CheckProofUploaded = (props) => {
    const [hasProof, setHasProof] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const trackingProfilesSelector = useSelector(state => state?.trackingProfiles?.profiles ?? []);
    const curDefer = useDefer();

    const checkSpent = () => {
        if (!props.integrationID) return;
        if (props.integrationID && !props.integrationID.startsWith("mg-") && !props.integrationID.startsWith("fb-")) return setHasProof(true);
        setHasProof();

        curDefer(() => {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/balances/checkSpentProof`,
                data: {
                    IntegrationID: props.integrationID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setHasProof(true);
                } else {
                    setHasProof(false);
                    notifyAccount();
                };
            }).catch(() => {
                setHasProof(true);
            });
        }, 1000);
    };

    const notifyAccount = () => {
        if (notifiedAccounts.includes(props.integrationID)) return;
        let tmp = document.querySelector(".component__header__left");
        if (!tmp) return;

        animateBox({currentTarget: tmp}, <YesNoModal
            heading="Account spent proof"
            text={[
                "You haven't still added a proof of spent for the last month on this account. Upload the image as soon as possible!",
                <br />,
                <img src="/images/header_balanceProofAdd.svg" style={{position: "relative", top: "8px", marginRight: "10px", width: "24px", height: "24px"}} />,
                <span>icon in the header</span>
            ]}
            buttonLeftHidden={true}
            isRightButtonNormal={true}
            buttonRightText="Ok"
        />);
        notifiedAccounts.push(props.integrationID);
    };

    React.useEffect(() => {
        checkSpent();
    }, [props.integrationID]);

    const UploadProofModal = (props2) => {
        const [infoP, setInfoP] = React.useState({
            text: "",
            inputs: [],
            hadError: false
        });
        const [spinner, setSpinner] = React.useState(false);
        const [selectedFile, setSelectedFile] = React.useState();

        const [additionalSpent, setAdditionalSpent] = React.useState();
        const [selectedFileAdditional, setSelectedFileAdditional] = React.useState();

        const spentRef = React.useRef();
        const spentAdditionalRef = React.useRef();

        const uploadProof = () => {
            if (spinner) return;

            setInfoP(ip => {return {...ip, hadError: false, inputs: []}});
            if (!selectedFile) return setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Image is not selected."}});
            if (!spentRef.current.value) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["spent"], text: "Spent is missing"}});

            if (additionalSpent) {
                if (!selectedFileAdditional) return setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Additional mage is not selected."}});
                if (!spentAdditionalRef.current.value) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["spent2"], text: "Additional spent is missing"}});
            };
    
            let curDate = moment().add(-1, "month").endOf("month").startOf("day");
    
            let fd = new FormData();
            fd.append("Image", selectedFile);
            fd.append("Amount", spentRef.current.value);
            fd.append("ForDate", curDate.toDate().getTime());
            fd.append("IntegrationID", props.integrationID);
            if (additionalSpent) {
                fd.append("ImageAdditional", selectedFileAdditional);
                fd.append("AmountAdditional", spentAdditionalRef.current.value);
            };
    
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/users/balances/uploadSpentProof`,
                data: fd,
                ...backendModule.axiosConfig,
                headers: {
                    ...backendModule.axiosConfig.headers,
                    "Content-Type": "multipart/form-data"
                }
            }).then(res => {
                if (res.data.status === "ok") {
                    props2.onChange();
                    props2.onClose();
                } else {
                    setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Error while uploading spent proof"}});
                };
            }).catch(() => {
                setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Server timed out while uploading spent proof"}});
            }).finally(() => {
                setSpinner(false);
            });
        };

        return <div className="genericModal">
            <div className="genericModal__wrap">
                <div className="genericModal__wrap__head">
                    <div className="genericModal__wrap__head__left">Upload spent proof</div>
                    <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props2.onClose}></div>
                </div>

                <p>Enter the amount spent for the last month on this account and select an image as a proof.</p>
                <p>{trackingProfilesSelector.find(t => t?.ID === props.integrationID)?.name ?? "-"}</p>
                <br />
                <br />

                <div className={`genericModal__wrap__input ${infoP.inputs.includes("spent") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Amount spent</p>
                    <input ref={spentRef} type="number" placeholder="Amount spent" />
                </div>

                <div className="genericModal__wrap__input">
                    <StyledButton onClick={e => e.target.parentNode.querySelector("input[type=file]").click()}>{selectedFile ? `Change image (${selectedFile?.name})` : "Select image"}</StyledButton>
                    <input type="file" accept="image/*" onChange={e => setSelectedFile(e?.target?.files[0])} style={{display: "none"}} />
                </div>


                <div className="genericModal__wrap__input" style={{display: "flex", alignItems: "center", gap: "5px", flexDirection: "row"}}>
                    <Checkbox onChange={e => setAdditionalSpent(!!e)} />
                    <span>Additional spent?</span>
                </div>
                {additionalSpent && <>
                    <div className={`genericModal__wrap__input ${infoP.inputs.includes("spent2") ? "genericModal__wrap__input--error" : ""}`}>
                        <p>Additional amount spent</p>
                        <input ref={spentAdditionalRef} type="number" placeholder="Additional amount spent" />
                    </div>

                    <div className="genericModal__wrap__input">
                        <StyledButton onClick={e => e.target.parentNode.querySelector("input[type=file]").click()}>{selectedFileAdditional ? `Change additional image (${selectedFileAdditional?.name})` : "Select additional image"}</StyledButton>
                        <input type="file" accept="image/*" onChange={e => setSelectedFileAdditional(e?.target?.files[0])} style={{display: "none"}} />
                    </div>
                </>}


                <div className="genericModal__wrap__buttons">
                    <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props2.onClose}>Close</div>
                    <div className="genericModal__wrap__buttons__btn" onClick={() => !spinner && uploadProof()}>
                        {spinner ? <Spinner style={{width: "19px", height: "19px"}} color="white" /> : "Upload"}
                    </div>
                </div>

                <p className="genericModal__wrap__infoP" style={{opacity: infoP.hadError ? 1 : 0}}>
                    {infoP?.text && infoP.text}
                </p>
            </div>
        </div>
    };

    if (spinner || hasProof === undefined) return <Spinner style={{width: "24px", height: "24px"}} color="white" />
    if (!hasProof) return <>
        <div
            className="component__header__right__icon"
            style={{ backgroundImage: 'url("/images/header_balanceProofAdd.svg")' }}
            onClick={(e) => animateBox({currentTarget: e?.target?.parentNode}, <UploadProofModal onChange={checkSpent} />)}
        />
    </>;
    return null;
};

export default Header;
export { UserModal }